export const ICD_VERSION = {
  functionalArea: "Reference",
  elementName: "R_ICD_VER_CD",
  lobCode: null,
  listNumber: 1140,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const GENDER = {
  functionalArea: "Reference",
  elementName: "R_SEX_CD",
  lobCode: null,
  listNumber: 1035,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const FAMILY_PLANNING = {
  functionalArea: "Reference",
  elementName: "familyPlanning",
  lobCode: null,
  listNumber: 1013,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const PEND_CONTROL = {
  functionalArea: "Reference",
  elementName: "R_CNTL_CD",
  lobCode: null,
  listNumber: 1006,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const COST_AVOIDANCE = {
  functionalArea: "Reference",
  elementName: "costAvoidance",
  lobCode: null,
  listNumber: 1038,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
//COS dropdowns
export const LOB = {
  functionalArea: "Reference",
  elementName: "R_LOB_CD",
  lobCode: null,
  listNumber: 1019,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const CATEGORY_OF_SERVICE = {
  functionalArea: "Claims",
  elementName: "C_COS_CD",
  lobCode: null,
  listNumber: 1020,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const PROF_DIAG_QLFY_TYPE = {
  functionalArea: "Claims",
  elementName: "",
  lobCode: null,
  listNumber: 1168,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null,
};
export const ASSIGN_TO = {
  functionalArea: "Claims2",
  elementName: "C_ASGN_CD",
  lobCode: null,
  listNumber: 1000,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const CLAIM_FORM = {
  functionalArea: "Claims",
  elementName: "C_ID_CD",
  lobCode: null,
  listNumber: 1027,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const CLAIM_TYPE = {
  functionalArea: "Claims",
  elementName: "C_TY_CD",
  lobCode: null,
  listNumber: 1038,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};


export const ROUTING_SHOW = {
  "functionalArea": "Claims",
  "dataElementName": "G_WORK_UNIT_TY_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
  };


 export const ASSIGN_THIS_RECORD_TO= {
  functionalArea: "Routing",
  elementName: "G_CRSPD_ASGN_TO_WORK_UNIT_SK",
  lobCode: null,
  listNumber: null,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};


export const DATA_ELEMENT_CRITERIA_COS = {
  functionalArea: "Claims",
  elementName: "C2",
  lobCode: null,
  listNumber: 5366,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const PROVIDER_ID_TYPE = {
  functionalArea: "Provider",
  elementName: "P_ALT_ID_TY_CD",
  lobCode: null,
  listNumber: 1017,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const BILLING_PROVIDER_ID_TYPE = {
  functionalArea: "Provider",
  elementName: "P_TY_CD",
  lobCode: null,
  listNumber: 1448,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

// export const LOB = { "functionalArea":"Reference", "elementName":"R_LOB_CD", "lobCode":null, "listNumber":1019, "listType":null, "listDate":null, "paramNumber":null, "key":null, "fDOS":null };
// export const CLAIM_TYPE = { "functionalArea":"Reference", "elementName":"C_TY_CD", "lobCode":null, "listNumber":1038, "listType":null, "listDate":null, "paramNumber":null, "key":null, "fDOS":null };
// export const CLAIM_FORM = { "functionalArea":"Reference", "elementName":"C_ID_CD", "lobCode":null, "listNumber":1027, "listType":null, "listDate":null, "paramNumber":null, "key":null, "fDOS":null };
export const BATCH_TYPE = {
  functionalArea: "Claims",
  elementName: "C_BATCH_TY_CD",
  lobCode: null,
  listNumber: 1015,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const MEDIA_SOURCE = {
  functionalArea: "Claims",
  elementName: "C_BATCH_MEDIA_SRC_CD",
  lobCode: null,
  listNumber: 1012,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const DATA_ELEMENT_CRITERIA = {
  functionalArea: "Claims",
  elementName: "C2",
  lobCode: null,
  listNumber: 5365,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const MODIFIER_N = {
  functionalArea: "Reference",
  elementName: "R_PROC_TB",
  lobCode: null,
  listNumber: null,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const UR_LIMIT_PARAMETER_PERIOD = {
  functionalArea: "Reference",
  elementName: "R_LMT_TM_PRD_TY_CD",
  lobCode: null,
  listNumber: 1052,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const UR_LIMIT_AMOUNT = {
  functionalArea: "Reference",
  elementName: "R_URC_LMT_UNIT_CD",
  lobCode: null,
  listNumber: 1037,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const UR_SAME_DIFF = {
  functionalArea: "Reference",
  elementName: "DM_S_DF_CD",
  lobCode: null,
  listNumber: 1134,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const DUPLICATE_CHECK_REVENUE = {
  functionalArea: "Reference",
  elementName: "DM_S_DF_CD",
  lobCode: null,
  listNumber: 1145,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

// provider inquiry validvalue dropdowns
export const ID_TYPE = {
  functionalArea: "P1",
  dataElementName: "P_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_TYPE = {
  functionalArea: "P1",
  dataElementName: "P_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_SPECALITY = {
  functionalArea: "P1",
  dataElementName: "P_SPECL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_TAXONOMY = {
  functionalArea: "P1",
  dataElementName: "P_TXNMY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_NETWORK = {
  functionalArea: "P1",
  dataElementName: "R_NW_ID",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_DISTRICT_OFFICE = {
  functionalArea: "G1",
  dataElementName: "B_CASE_DSTCT_OFC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_SPOKEN_LANGUAGE = {
  functionalArea: "Provider",
  dataElementName: "P_LANG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const Provider_COUNTRY = {
  functionalArea: "General2",
  dataElementName: "G_CNTY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const ADDRESS_TYPE = {
  functionalArea: "G1",
  dataElementName: "G_ADR_USG_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const POSITION_TYPE = {
  functionalArea: "G1",
  dataElementName: "G_REP_PROV_PSTN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROTOCOL_TYPE = {
  functionalArea: "G1",
  dataElementName: "W_SFTWR_PRTCL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const SIGNIFICANCE = {
  functionalArea: "P1",
  dataElementName: "G_CMN_ENTY_REP_SIG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CHANGE_REASON = {
  functionalArea: "P1",
  dataElementName: "G_ADR_CHG_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const STATE = {
  functionalArea: "P1",
  dataElementName: "G_US_STATE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const R_COST_AVOID_CD = {
  functionalArea: "Claims",
  dataElementName: "R_COST_AVOID_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_SVC_AREA_CD = {
  functionalArea: "Claims",
  dataElementName: "R_SVC_AREA_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_PROC_LAB_CD = {
  functionalArea: "Claims",
  dataElementName: "R_PROC_LAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_RTN_HIST_CD = {
  functionalArea: "Claims",
  dataElementName: "R_RTN_HIST_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_FROM_THRU_CD = {
  functionalArea: "Claims",
  dataElementName: "R_FROM_THRU_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_FAM_PLNG_CD = {
  functionalArea: "Claims",
  dataElementName: "R_FAM_PLNG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_SEX_CD = {
  functionalArea: "Claims",
  dataElementName: "R_SEX_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_PROC_STERIL_CD = {
  functionalArea: "Claims",
  dataElementName: "R_PROC_STERIL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_CNTL_CD = {
  functionalArea: "Claims",
  dataElementName: "R_CNTL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_PROC_UNIT_OF_MSR_CD = {
  functionalArea: "Claims",
  dataElementName: "R_PROC_UNIT_OF_MSR_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_PROC_MOD_CD = {
  functionalArea: "Claims",
  dataElementName: "R_PROC_MOD_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const P_CLIA_LAB_CD = {
  functionalArea: "Claims",
  dataElementName: "P_CLIA_LAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const P_CLIA_CERT_TY_CD = {
  functionalArea: "Claims",
  dataElementName: "P_CLIA_CERT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const R_ASC_GRP_CD = {
  functionalArea: "Claims",
  dataElementName: "R_ASC_GRP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const R_ASC_GRP_CD_1 = {
  functionalArea: "R1",
  dataElementName: "R_ASC_GRP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const R_RATE_ASC_RGN_CD = {
  functionalArea: "R1",
  dataElementName: "R_RATE_ASC_RGN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
//Revenue Type
export const REVENUE_TYPE = {
  functionalArea: "Claims",
  dataElementName: "R_REV_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const REV_PEND_CONTROL = {
  functionalArea: "Claims",
  dataElementName: "R_CNTL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const SERVICE_AREA = {
  functionalArea: "Claims",
  dataElementName: "R_SVC_AREA_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const REV_GENDER = {
  functionalArea: "Claims",
  dataElementName: "R_SEX_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PLACE_OF_SERVICE = {
  functionalArea: "Claims",
  dataElementName: "R_PLACE_OF_SVC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TOOTH_NUMBER = {
  functionalArea: "Claims",
  dataElementName: "R_PROC_TOOTH_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_TYPE_VALIDVALUE = {
  functionalArea: "Claims",
  dataElementName: "C_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const TRANS_TYPE = {
  functionalArea: "Claims",
  dataElementName: "C_TXN_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const ADJ_TYPE = {
  functionalArea: "Claims",
  dataElementName: "C_ADJ_CLM_SEL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CATEGORY_OF_SERVICE_VALIDVALUE = {
  functionalArea: "Claims",
  dataElementName: "C_COS_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const COVERAGE_CODE = {
  functionalArea: "Claims",
  dataElementName: "T_CVRG_PLCY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const CUSTOMER_TYPE = {
  functionalArea: "Customer",
  dataElementName: "R_CUST_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};


export const INVOICE_SUMMARY = {
  functionalArea: "Customer",
  dataElementName: "R_ADMIN_SUM_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const DRUG_CARD_FORMAT = {
  functionalArea: "Customer",
  dataElementName: "R_DRUG_CARD_FMT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const BRAND_METHOD = {
  functionalArea: "Customer",
  dataElementName: "R_BRAND_MTHD_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const CUSTOMER_FEE_TYPE = {
  functionalArea: "Customer",
  dataElementName: "R_CUST_FEE_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const SELECTION_TYPE = {
  functionalArea: "Customer",
  dataElementName: "R_FEE_SEL_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const RESOURCE_TYPE = {
  functionalArea: "Claims",
  dataElementName: "T_RESRC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

//Benefit Plan validValue Dropdowns
export const BENEFIT_PLAN_TYPE = {
  functionalArea: "Claims",
  dataElementName: "R_BP_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const BENEFIT_PLAN_OPTION = {
  functionalArea: "R1",
  dataElementName: "R_BP_OPTNS_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const BENEFIT_PLAN_STATUS = {
  functionalArea: "R1",
  dataElementName: "R_BP_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MAP_ID = {
  functionalArea: "R1",
  dataElementName: "R_MPST_ID",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const NW_STATUS = {
  functionalArea: "R1",
  dataElementName: "R_BP_NW_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SA_RULE = {
  functionalArea: "R1",
  dataElementName: "R_BP_SA_RULE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const INS_LMT_TYPE_CODE = {
  functionalArea: "R1",
  dataElementName: "R_BP_COINS_LMT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const DEDUCTIBLE_CODE = {
  functionalArea: "R1",
  dataElementName: "R_BP_DED_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MAP_SET_ID = {
  functionalArea: "Reference",
  dataElementName: "P_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_TYPE_PROIVDER = {
  functionalArea: "Reference",
  dataElementName: "P_SVC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const LIMIT_TY_CODE = {
  functionalArea: "Reference",
  dataElementName: "R_BP_LMT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const BYPASS_IF_CATEGORY_FUNCTIONAL_AREA = {
  functionalArea: "Reference",
  dataElementName: "R_FUNC_AREA_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const COPAY_TYPE_CODE = {
  functionalArea: "Reference",
  dataElementName: "R_BP_COPAY_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const RATE_TYPE_CODE = {
  functionalArea: "R1",
  dataElementName: "R_BP_COHRT_RATE_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SYSTEM_PARAMETER_NUMBER_2 = {
  listNumber: "24",
  functionalArea: "R1"
};

export const SYSTEM_PARAMETER_NUMBER = {
  listNumber: "23",
  functionalArea: "R1"
};

export const FUNCTIONAL_AREA_CODE = {
  listNumber: "22",
  functionalArea: "R1"
};
export const FUNCTIONAL_AREA_CODE_2 = {
  listNumber: "22",
  functionalArea: "R1"
};

export const RETRO_PROC_CODE_21 = {
  listNumber: "21",
  functionalArea: "R1"
};
export const RETRO_PROC_CODE_31 = {
  listNumber: "31",
  functionalArea: "R1"
};
export const RETRO_PROC_CODE_41 = {
  listNumber: "41",
  functionalArea: "R1"
};

export const CURRENT_PROC_CODE_20 = {
  listNumber: "20",
  functionalArea: "R1"
};

export const CURRENT_PROC_CODE_30 = {
  listNumber: "30",
  functionalArea: "R1"
};

export const CURRENT_PROC_CODE_40 = {
  listNumber: "40",
  functionalArea: "R1"
};

export const FUTURE_PROC_CODE = {
  listNumber: "31",
  functionalArea: "R1"
};

// ICD Surgical Procedure Code Dropdowns
export const RETAIN_HISTORY = {
  functionalArea: "Claims",
  dataElementName: "R_RTN_HIST_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const ICD_VERSION_SURGICAL = {
  functionalArea: "Claims",
  dataElementName: "R_ICD_VER_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PEND_CONTROL_SURGICAL = {
  functionalArea: "Claims",
  dataElementName: "R_CNTL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const FAMILY_PLANNING_SURGICAL = {
  functionalArea: "Claims",
  dataElementName: "R_FAM_PLNG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const GENDER_SURGICAL = {
  functionalArea: "Claims",
  dataElementName: "R_SEX_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_HISTORY_CLAIM_TYPE = {
  functionalArea: "Claims",
  dataElementName: "C_VOID_REPLCMT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_TYPE = {
  functionalArea: "Claims",
  dataElementName: "G_CMN_ENTY_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROV_ROLE = {
  functionalArea: "Claims",
  dataElementName: "C_PROV_ROLE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROV_ID_TYPE = {
  functionalArea: "Claims",
  dataElementName: "P_ALT_ID_SRC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

// Suspense Release Dropdowns
export const PROVIDER_ROLE = {
  functionalArea: "Reference",
  elementName: "C_PROV_ROLE_CD",
  lobCode: null,
  listNumber: 1035,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const PROVIDER_ROLE_ID_TYPE = {
  functionalArea: "Reference",
  elementName: "P_ALT_ID_TY_CD",
  lobCode: null,
  listNumber: 1002,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

// Member Details Dropdowns
export const MEMBER_ID_TYPE = {
  functionalArea: "Claims",
  dataElementName: "B_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const COE = {
  functionalArea: "Member",
  dataElementName: "B_COE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SS_TYPE_CODE = {
  functionalArea: "Member",
  dataElementName: "B_SSI_SSDI_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const DISABILITY_TYPE_CODE = {
  functionalArea: "Member",
  dataElementName: "B_DISA_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
//Revenue Code Pricing Dropdowns
export const REV_LOB = {
  functionalArea: "Claims",
  dataElementName: "R_LOB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const REV_FACTOR_CODE = {
  functionalArea: "Claims",
  dataElementName: "R_FCTR_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const REV_RATE_SOURCE = {
  functionalArea: "Claims",
  dataElementName: "R_RATE_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const REV_REASON = {
  functionalArea: "Claims",
  dataElementName: "R_RATE_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIMS_INQUIRY_MEDIA = {
  functionalArea: "Claims",
  dataElementName: "C_BATCH_MEDIA_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIMS_INQUIRY_PROVIDER_TYPE = {
  functionalArea: "P1",
  dataElementName: "P_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

//DRG Code Dropdowns
export const PEER_GROUP = {
  functionalArea: "Claims",
  dataElementName: "P_PEER_GRP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MDC = {
  functionalArea: "Claims",
  dataElementName: "R_DRG_MDC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const BILL_TYPE_LIST = {
  functionalArea: "R1",
  dataElementName: "R_REV_TY_BILL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const RATE_SRC_CD = {
  functionalArea: "R1",
  dataElementName: "R_RATE_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_TYPE_LIST = {
  functionalArea: "R1",
  dataElementName: "P_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const UR_HISTORY = {
  functionalArea: "R1",
  dataElementName: "R_BEFORE_AFTER_HIST_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_SPECIALTY_LIST = {
  functionalArea: "R1",
  dataElementName: "P_SPECL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_HISTORY_TAB = {
  functionalArea: "Claims",
  dataElementName: "C_VOID_REPLCMT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_DATE_TYPE = {
  functionalArea: "A1",
  dataElementName: "DM_A_SA_DT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_TYPE_CODE = {
  functionalArea: "A1",
  dataElementName: "A_PROV_ID_MMIS_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_CODE_TYPE = {
  functionalArea: "A1",
  dataElementName: "A_LI_SVC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_AUTH_TYPE = {
  functionalArea: "A1",
  dataElementName: "A_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const SA_CATEGORY_CODE = {
  functionalArea: "A1",
  dataElementName: "A_LI_CAT_SVC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_AUTH_TYPE1 = {
  functionalArea: "A1",
  dataElementName: "A-TY-CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SA_BENEFIT_PLAN_ID = {
  functionalArea: "A1",
  dataElementName: "R_BP_ID",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_AUTHORIZATIONSTATUS = {
  functionalArea: "A1",
  dataElementName: "A_HDR_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_USER_TYPE = {
  functionalArea: "A1",
  dataElementName: "DM_A_SA_USER_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_USER_ID = {
  functionalArea: "A1",
  dataElementName: "DM_A_SA_USER_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CO_INSURANCE_TYPE_CODE = {
  functionalArea: "R1",
  dataElementName: "R_BP_COINS_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_OVERRIDE_PAY = {
  functionalArea: "Claims",
  dataElementName: "C_EXC_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const VV_DOC_TYPE = {
  functionalArea: "Claims",
  dataElementName: "C_BATCH_DOC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const VV_BATCH_TYPE = {
  functionalArea: "Claims",
  dataElementName: "C_BATCH_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const VV_BATCH_STATUS = {
  functionalArea: "Claims",
  dataElementName: "C_BATCH_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_TYPE_STATUS = {
  functionalArea: "Claims",
  dataElementName: "C_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const DISPOSITION_TYPE = {
  functionalArea: "Claims",
  dataElementName: "R_CLM_EXC_DISP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const FORCE_PAY = {
  functionalArea: "Claims",
  dataElementName: "R_EXC_FORCE_APP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const FORCE_DENY = {
  functionalArea: "Claims",
  dataElementName: "R_FORCE_DENY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_CLASSIFICATION = {
  functionalArea: "P1",
  dataElementName: "P_TY_CLASS_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
//export const EVENT_DESC = { "functionalArea":"P1", "elementName":"P_EVENT_CD", "lobCode":null, "listNumber":1020, "listType":null, "listDate":null, "paramNumber":null, "key":null, "fDOS":null };
export const EVENT_REASON = {
  functionalArea: "Claims",
  dataElementName: "P_EVENT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
//   Provider Inquiry  Monitoring tracking dropdowns
export const PROVIDER_RESP_ACTION = {
  functionalArea: "P1",
  dataElementName: "P_CREDNTL_RESP_ACTN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_FCBC_RSLT = {
  functionalArea: "P1",
  dataElementName: "P_FCBC_RSLT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_FCBC_RSLT_RSN = {
  functionalArea: "P1",
  dataElementName: "P_FCBC_RSLT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const PROVIDER_RESP_TYPE = {
  functionalArea: "P1",
  dataElementName: "P_CREDNTL_RESP_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const VV_PROVIDER_ROLE = {
  functionalArea: "C1",
  dataElementName: "C_PROV_ROLE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const VV_PROVIDER_ROLE_ID_TYPE = {
  functionalArea: "C1",
  dataElementName: "P_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_GENDER = {
  functionalArea: "G1",
  dataElementName: "DM_GENDER_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_TOWN = {
  functionalArea: "G1",
  dataElementName: "G_TOWN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_DIST_OFFICE = {
  functionalArea: "G1",
  dataElementName: "B_CASE_DSTCT_OFC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_RACE = {
  functionalArea: "Member",
  dataElementName: "B_RACE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_DOD_VRFY = {
  functionalArea: "Member",
  dataElementName: "B_DOD_VRFY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_LANG = {
  functionalArea: "Member",
  dataElementName: "B_LANG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_MARITAL_STATUS = {
  functionalArea: "Member",
  dataElementName: "B_MRTL_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_LIVING_ARRANGEMENT = {
  functionalArea: "Member",
  dataElementName: "B_LV_ARNGMT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_ETHNIC_CODE = {
  functionalArea: "Member",
  dataElementName: "B_ETH_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_HIC_SOURCE = {
  functionalArea: "Member",
  dataElementName: "B_ALT_ID_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MEMBER_DISTRICT_OFFICE = {
  functionalArea: "Member",
  dataElementName: "B_CASE_DSTCT_OFC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_CITIZENSHIP = {
  functionalArea: "Member",
  dataElementName: "B_CITIZEN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_UPDATE_VERIF = {
  functionalArea: "Member",
  dataElementName: "B_UPD_VRFY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_PROV_ID_TYPE = {
  functionalArea: "Member",
  dataElementName: "P_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_PROV_TYPE_CODE = {
  functionalArea: "Member",
  dataElementName: "P_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MEMBER_BP_LMT_TYPE_CODE = {
  functionalArea: "Member",
  dataElementName: "R_BP_LMT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_EXC_C_BATCH_DOC_TY_CD = {
  functionalArea: "ClaimException",
  dataElementName: "C_BATCH_DOC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_EXC_C_A_MEDIA_TY_CD = {
  functionalArea: "ClaimException",
  dataElementName: "A_MEDIA_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const DISPOSITION_CLAIMS = {
  functionalArea: "ClaimException",
  dataElementName: "R_AUTH_EXC_DISP_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const FORCE_APPROVE = {
  functionalArea: "ClaimException",
  dataElementName: "R_AUTH_FORCE_PAY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const FORCE_DENY_CLAIMS = {
  functionalArea: "ClaimException",
  dataElementName: "R_AUTH_FORCE_DENY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const CLAIM_EXC_C_BATCH_MEDIA_SRC_CD = {
  functionalArea: "ClaimException",
  dataElementName: "C_BATCH_MEDIA_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_EXC_C_TY_CD = {
  functionalArea: "ClaimException",
  dataElementName: "C_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_EXC_R_TXT_LOCN_CD = {
  functionalArea: "ClaimException",
  dataElementName: "R_TXT_LOCN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_EXC_R_ADJ_RSN_CD = {
  functionalArea: "ClaimException",
  dataElementName: "R_ADJ_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const CLAIM_EXC_R_RMK_CD = {
  functionalArea: "ClaimException",
  dataElementName: "R_RMK_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_EXC_R_CLM_EOB_CD = {
  functionalArea: "ClaimException",
  dataElementName: "",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStR_CLM_EOB_CDartsOrContains: null,
  code: null
};

export const PROC_POS = {
  functionalArea: "Claims",
  dataElementName: "R_PLACE_OF_SVC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const MODIFIERS = {
  functionalArea: "Reference",
  elementName: "R_PROC_MOD_CD",
  lobCode: null,
  listNumber: 1029,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const RATE_SOURCE = {
  functionalArea: "Reference",
  elementName: "R_RATE_SRC_CD",
  lobCode: null,
  listNumber: 1056,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const PROVIDER_ALT_ID = {
  functionalArea: "Provider",
  elementName: "P_ALT_ID_TY_CD",
  lobCode: null,
  listNumber: 13,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const Region = {
  functionalArea: "Reference",
  elementName: "R_RGN_CD",
  lobCode: null,
  listNumber: 1089,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

// Service Authorization starts

export const A_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_TY_CD",
  lobCode: null,
  listNumber: 1012,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const G_US_STATE_CD = {
  functionalArea: "General",
  elementName: "G_US_STATE_CD",
  lobCode: null,
  listNumber: 1019,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const DM_SFX_NAM = {
  functionalArea: "General",
  elementName: "DM_SFX_NAM",
  lobCode: null,
  listNumber: 1053,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const G_NAM_PREFX_CD = {
  functionalArea: "General",
  elementName: "DM_SFX_NAM",
  lobCode: null,
  listNumber: 1011,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_MEDIA_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_MEDIA_TY_CD",
  lobCode: null,
  listNumber: "0021",
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const LA_SRC_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_SRC_CD",
  lobCode: null,
  listNumber: 1011,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_SRC_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_SRC_CD",
  lobCode: null,
  listNumber: 55,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
// new Again
export const A_HDR_STAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_HDR_STAT_CD",
  lobCode: null,
  listNumber: 1004,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_LTR_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LTR_TY_CD",
  lobCode: null,
  listNumber: 59,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_RSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_RSN_CD",
  lobCode: null,
  listNumber: 1268,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const APPROVED_STATUS_RSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_RSN_CD",
  lobCode: null,
  listNumber: 32,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const DENIED_STATUS_RSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_RSN_CD",
  lobCode: null,
  listNumber: 33,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const VOIDED_STATUS_RSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_RSN_CD",
  lobCode: null,
  listNumber: 34,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const PENDED_STATUS_RSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_RSN_CD",
  lobCode: null,
  listNumber: 35,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_DIAG_QLFR_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_DIAG_QLFR_CD",
  lobCode: null,
  listNumber: 111,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_DIAG_QLFR_CD_Vesrion_10 = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_DIAG_QLFR_CD",
  lobCode: null,
  listNumber: 159,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_LTR_CMNT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_DIAG_QLFR_CD",
  lobCode: null,
  listNumber: 1029,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 155,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD_RQ = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 5001,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD_EV = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 5002,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD_SV = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 5003,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD_1 = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 152,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_PROV_ID_MMIS_TY_CD_2 = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_PROV_ID_MMIS_TY_CD",
  lobCode: null,
  listNumber: 153,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_ENTY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_ENTY_CD",
  lobCode: null,
  listNumber: 136,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_ENTY_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_ENTY_TY_CD",
  lobCode: null,
  listNumber: 1221,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_ENTY_CD_req = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_ENTY_CD",
  lobCode: null,
  listNumber: 135,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_ENTY_CD_SRV = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_ENTY_CD",
  lobCode: null,
  listNumber: 137,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const G_ALERT_TY_CD = {
  functionalArea: "General",
  elementName: "G_ALERT_TY_CD",
  lobCode: null,
  listNumber: 137,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const G_ALERT_STAT_CD = {
  functionalArea: "General",
  elementName: "G_ALERT_STAT_CD",
  lobCode: null,
  listNumber: 88,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_FACI_QLFR_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_FACI_QLFR_CD",
  lobCode: null,
  listNumber: 1074,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_UM_REVW_REQ_CAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_UM_REVW_REQ_CAT_CD",
  lobCode: null,
  listNumber: 1469,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_CERT_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_CERT_TY_CD",
  lobCode: null,
  listNumber: 1071,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_UM_SVC_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_UM_SVC_TY_CD",
  lobCode: null,
  listNumber: 1079,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_UM_LVL_OF_SVC_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_UM_LVL_OF_SVC_CD",
  lobCode: null,
  listNumber: 1075,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_LVL_OF_CARE_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_LVL_OF_CARE_CD",
  lobCode: null,
  listNumber: 1452,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_ATTACH_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_ATTACH_CD",
  lobCode: null,
  listNumber: 1448,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const X12_ATTACH_TRNSMSN_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_ATTACH_TRNSMSN_CD",
  lobCode: null,
  listNumber: 1246,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const UNIT_BASIS_MSR_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "UNIT_BASIS_MSR_CD",
  lobCode: null,
  listNumber: 1453,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_LI_CAT_SVC_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_CAT_SVC_CD",
  lobCode: null,
  listNumber: 1249,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const MEDICAL_CAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_CAT_SVC_CD",
  lobCode: null,
  listNumber: 41,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const DENTAL_CAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_CAT_SVC_CD",
  lobCode: null,
  listNumber: 42,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const DME_CAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_CAT_SVC_CD",
  lobCode: null,
  listNumber: 43,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const HCBC_CAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_CAT_SVC_CD",
  lobCode: null,
  listNumber: 44,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const A_LI_SVC_TY_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_SVC_TY_CD",
  lobCode: null,
  listNumber: 1007,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const IP_SVC_TYPE_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_SVC_TY_CD",
  lobCode: null,
  listNumber: 58,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};
export const A_LI_STAT_CD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_STAT_CD",
  lobCode: null,
  listNumber: 1006,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const G_CNTRY_CD = {
  functionalArea: "General",
  elementName: "G_CNTRY_CD",
  lobCode: null,
  listNumber: 1006,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const PROSTHESIS_CODE = {
  functionalArea: "Claims",
  elementName: "X12_LI_PLCMT_STAT_CD",
  listNumber: 1251,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const OralCavityCode = {
  functionalArea: "Claims",
  elementName: "X12_ORAL_CAV_CD",
  listNumber: 1077,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const X12_TOOTH_NUM = {
  functionalArea: "Reference",
  elementName: "X12_TOOTH_NUM",
  listNumber: 1031,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TOOTH_SURFACE = {
  functionalArea: "Claims",
  elementName: "X12_TOOTH_SURF_CD",
  listNumber: 1147,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

/* export const R_PROC_CD = {
    functionalArea: "A9",
    elementName: "R_PROC_CD",
    lobCode: null,
    listNumber: 1007,
    listType: null,
    listDate: null,
    paramNumber: null,
    key: null,
    fDOS: null,
  };
  export const R_PROC_CD = {
    functionalArea: "A9",
    elementName: "R_PROC_CD",
    lobCode: null,
    listNumber: 1007,
    listType: null,
    listDate: null,
    paramNumber: null,
    key: null,
    fDOS: null,
  }; */
// SA Ends

export const PROVIDER_ID_TYPE_CODE = {
  functionalArea: "FINANCIAL_PYE",
  dataElementName: "P_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const RATES_BENEFIT_PLAN_ID_DROPDOWN = {
  functionalArea: "FINANCIAL_PYE",
  dataElementName: "R_BP_ID",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const RATES_NETWORK_ID_DROPDOWN = {
  functionalArea: "FINANCIAL_PYE",
  dataElementName: "R_NW_ID",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const RATES_MOD_CODE = {
  functionalArea: "Reference",
  elementName: "G_CE_OR_SE_TY_CD",
  lobCode: null,
  listNumber: 1,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const DATE_CODE =    {
  functionalArea:"Claims4",
  elementName:"C_ADJ_CLM_SEL_CD",
  lobCode:null,
  listNumber:5037,
  listType:null,
  listDate:null,
  paramNumber:null,
  key:null,
  fDOS:null

}

export const COS_CODE =     {
  "functionalArea": "Claims",
  "elementName": "C_COS_CD",
  "lobCode": null,
  "listNumber": 1020,
  "listType": null,
  "listDate": null,
  "paramNumber": null,
  "key": null,
  "fDOS": null
}

export const OTHER_CODE =   {

  "functionalArea":"Claims4",
  "elementName":"C_ADJ_CLM_SEL_CD",
  "lobCode":null,
  "listNumber":5038,
  "listType":null,
  "listDate":null,
  "paramNumber":null,
  "key":null,
  "fDOS":null
}

export const CODES_CODE = {
  "functionalArea":"Claims4",
  "elementName":"C_ADJ_CLM_SEL_CD",
  "lobCode":null,
  "listNumber":5039,
  "listType":null,
  "listDate":null,
  "paramNumber":null,
  "key":null,
  "fDOS":null
};

export const RELATED_CAUSE = {
  functionalArea: "Reference",
  dataElementName: "X12_RLTD_CAUSE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const SA_EXC_TY = {
  busNameStartsOrContains: null,
  businessName: null,
  code: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  dataElementName: "R_SA_EXC_TY_CD",
  functionalArea: "R1"
}

export const SA_APPROVAL_PERIOD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "A_LI_APP_PRD_CDD",
  lobCode: null,
  listNumber: 1042,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const SERVICE_QUANTITY_TYPE = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_UNIT_QTY_QLFR_CD",
  lobCode: null,
  listNumber: 1081,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const FREQUENCY_PERIOD = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_SVCDLVRY_UNIT_BASIS_MSR_CD",
  lobCode: null,
  listNumber: 1085,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const DURATION_OF_SERVICES = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_TM_PRD_QLFR_CD",
  lobCode: null,
  listNumber: 1084,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const CALENDAR_PATTERN = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_PTRN_CD",
  lobCode: null,
  listNumber: 1082,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const TIME_OF_DAY = {
  functionalArea: "ServiceAuthorisation",
  elementName: "X12_PTRN_TM_CD",
  lobCode: null,
  listNumber: 1083,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null
};

export const SA_MEDIA_TY = {
  busNameStartsOrContains: null,
  businessName: null,
  code: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  dataElementName: "A_MEDIA_TY_CD",
  functionalArea: "R1",
};

export const CARRIER_BILL = {
  functionalArea: "T11",
  dataElementName: "T_CARR_BILL_MEDIA_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const CARRIER_MEDIA = {
  functionalArea: "T1",
  dataElementName: "T_CARR_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const INFO_SOURCE = {
  functionalArea: "T1",
  dataElementName: "T_INFO_SRC_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const WORKER_ID = {
  functionalArea: "T1",
  dataElementName: "T_CARR_CASEWRK_TAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const CHARGE_MODE = {
  functionalArea: "R1",
  dataElementName: "R_INST_CHRG_MODE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const LEVEL_OF_CARE = {
  functionalArea: "R1",
  dataElementName: "B_LVL_OF_CARE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const PRESCRIPTION_QUALIFIER = {
  functionalArea: "C1",
  dataElementName: "X12_RX_QLFR_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const PROCEDURE_TYPE = {
    "functionalArea": "Claims",
    "dataElementName": "X12_SURG_PROC_QLFR_CD",
    "businessName": null,
    "valueShortDescription": null,
    "crossReferenceColumnName": null,
    "crossReferenceTableName": null,
    "dataEleNameStartsOrContains": null,
    "busNameStartsOrContains": null,
    "code": null
};

export const MEMBER_SEARCH_TYPE = {
  functionalArea: "Claims",
  dataElementName: "T_MEM_TAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const HIPP_CASE_STATUS = {
  functionalArea: "Claims",
  dataElementName: "T_HIPP_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const RECOVERY_TYPE = {
  functionalArea: "Claims",
  dataElementName: "T_RCVRY_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const RECOVERY_CASE_STATUS = {
  functionalArea: "Claims",
  dataElementName: "T_CASE_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}








export const CASE_NUMBER = {
  functionalArea: "T1",
  dataElementName: "T_HIPP_CASE_TAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const CARRIER = {
  functionalArea: "T1",
  dataElementName: "T_CARR_TAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const WORKER = {
  functionalArea: "T1",
  dataElementName: "T_HIPP_CARR_WKR_TAB_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}






export const TPL_MEDIA = {
  functionalArea: "TPL",
  elementName: null,
  lobCode: null,
  listNumber: 1052,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null,
};

export const TPL_HOLDER_SEARCH = {
  functionalArea: "TPL",
  dataElementName: "T_PLYHLDR_TAB_CD",
  lobCode: null,
  listNumber: 1052,
  listType: null,
  listDate: null,
  paramNumber: null,
  key: null,
  fDOS: null,
};

export const MASS_CHANGE_TYPE = {
  functionalArea: "TPL",
  dataElementName: "T_MASS_CHG_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const CONTACT_STATUS = {
  functionalArea: "G1",
  dataElementName: "G_CMN_ENTY_REP_XREF_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}
export const EPSDT_SCREENING_TYPE = {
  functionalArea: "E1-1001",
  dataElementName: "E_SCRNG_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const CONTACT_TYPE = {
  functionalArea: "G1",
  dataElementName: "G_REP_XREF_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const PREFIX = {
  functionalArea: "G1",
  dataElementName: "G_NAM_PREFX_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const SUFFIX = {
  functionalArea: "G1",
  dataElementName: "DM_SFX_NAM",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const EPSDT_YEARS_MONTHS = {
  functionalArea: "E1-2013",
  dataElementName: "DM_MO",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TP_CLASSIFICATION = {
  functionalArea: "Edi&Trading",
  dataElementName: "W_TP_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const NETWORK_TYPE = {
  functionalArea: "R1",
  dataElementName: "R_NW_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const LOB_TYPE = {
  functionalArea: "R1",
  dataElementName: "R_LOB_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}
export const PAYMENT_CYCLE = {
  functionalArea: "R1",
  dataElementName: "R_PMT_CYCLE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}
export const ADDRESS_SIGNIFICANCE = {
  functionalArea: "R1",
  dataElementName: "G_PHONE_TY_SIG_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const CONFIRMATION_CODE = {
  functionalArea: "TPL",
  dataElementName: "T_MASS_CHG_CNFRM_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
}

export const WORK_UNIT_LEVEL = {
  functionalArea: "G1",
  dataElementName: "DM_EDMS_WORK_UNIT_LEVEL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const DOCUMENT_TYPE = {
  functionalArea: "G1",
  dataElementName: "G_EDMS_DOC_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}


export const PROPRIETARY_INTERFACE_FILES={
  functionalArea: "P1",
  dataElementName: "W_TXN_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}
export const BATCH_PAYMENT_TYPE ={
  functionalArea: "P1",
  dataElementName: "C_BATCH_PMT_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const BATCH_STATUS = {
  functionalArea: "Claims",
  dataElementName: "C_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};


export const MASS_ADJ_RQS = {
  
  functionalArea: "G1",
  dataElementName: "C_ADJ_REQ_RTN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
}

export const REASON_TYPE = {
  functionalArea: "C1 – Claims Ent",
  dataElementName: "C_VOID_REPLCMT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const COMPARISON_TYPE = {
  "functionalArea": "R1",
  "dataElementName": "R_CMPRSN_TY_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null,
  "listNumber": ""
}

export const DATE_OF_SERVICE = {
  "functionalArea": "R1",
  "dataElementName": "R_DOS_CMPRSN_TY_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null,
  "listNumber": ""
}

export const MEMBER_ID_COVERAGE = {
  functionalArea: "Claims",
  dataElementName: "B_MCARE_PART_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MBI_END_RSN_CD = {
  functionalArea: "Claims",
  dataElementName: "B_MCARE_MBI_END_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MBI_BEG_RSN_CD = {
  functionalArea: "Claims",
  dataElementName: "B_MCARE_MBI_BEG_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const MBI_STAT_CD = {
  functionalArea: "Claims",
  dataElementName: "B_MCARE_MBI_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const SERVICE_NAME = {
  functionalArea: "C4",
  dataElementName: "C_SVC_NAM",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const FAULT_SERVICE_NAME = {
  functionalArea: "C4",
  dataElementName: "C_FAULT_SVC_NAM",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const STATUS_TYPE = {
  functionalArea: "T1",
  dataElementName: "T_CVRG_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};


export const RELATIONSHIP = {
  functionalArea: "G1",
  dataElementName: "G_REL_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};


export const TPL_MEMBER_ID_TYPE = {
  functionalArea: "B1",
  dataElementName: "B_ALT_ID_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const TPL_COVERAGE_CODE = {
  functionalArea: "T1",
  dataElementName: "T_CVRG_PLCY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TPL_COUNTRY = {
  functionalArea: "G1",
  dataElementName: "G_CNTY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TPL_STATE = {
  functionalArea: "G1",
  dataElementName: "G_US_STATE_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const EVENT_TYPE = {
  functionalArea: "R_EVENT_LOG_MSG_TB",
  dataElementName: "G_EVENT_LOG_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const COUNTRY = {
  "functionalArea": "G1#1006",
  "dataElementName": "G_CNTRY_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const UPDATESOURCE =
{"functionalArea": "B1-1045",
"dataElementName": "B_MCARE_UPD_SRC_CD",
"businessName": null,
"valueShortDescription": null,
"crossReferenceColumnName": null,
"crossReferenceTableName": null,
"dataEleNameStartsOrContains": null,
"busNameStartsOrContains": null,
"code": null}
export const INVOICESTATUS = {
  "functionalArea": "R1",
  "dataElementName": "T_INVC_STAT_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const CLOSUREREASON = {
  "functionalArea": "T1",
  "dataElementName": "T_BLNG_CLOSE_RSN_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const RECORYREASON = {
  "functionalArea": "T1",
  "dataElementName": "T_BLNG_RESP_RSN_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const RECORYMETHOD = {
  "functionalArea": "T1",
  "dataElementName": "T_RCVRY_METHOD_TB_CD",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const LETTER_RESPONSES_ALERT_BASED = {
  listNumber: "12",
  functionalArea: "G1"
};
export const LETTER_RESPONSES_SEND_ALERT = {
  listNumber: "9",
  functionalArea: "G1"
};
export const LETTER_RESPONSES_ALERT_BASED_VV = {
  "functionalArea": "G1",
  "dataElementName": "G_ALERT_BASED_ON_COL_NAM",
  "businessName": null,
  "valueShortDescription": null,
  "crossReferenceColumnName": null,
  "crossReferenceTableName": null,
  "dataEleNameStartsOrContains": null,
  "busNameStartsOrContains": null,
  "code": null
};
export const ALERT_BASED_SYSTEM_LIST =  {
  "functionalArea": "General",
  "elementName": "'G_ALERT_BASED_ON_COL_NAM'",
  "lobCode": null,
  "listNumber": 12,
  "listType": null,
  "listDate": null,
  "paramNumber": null,
  "key": null,
  "fDOS": null
}