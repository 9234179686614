/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React from "react";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const RolesWorkUnit = (props) => { 
  
const rolesData = props.userData.userRoles;
const workData = props.userData.workUnits;
const customList = (title, items) => (
    <Card>
      <CardHeader
        className='makeStyles-cardHeader-28'
        title='Assigned User Role'
      />
      <List className="list" dense component="div" role="list">
        {items?.map(value => {
          const labelId = `transfer-list-all-item-${value.name}-label`;
          return (
            <ListItem key={value.name} role="listitem" button >
              <ListItemText id={labelId} primary={value.name}/>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
const customerWorkItemList = (title, items) => (
    <Card>
      <CardHeader
        className='makeStyles-cardHeader-28'
        title='Assigned Work Unit'
      />
      <List className="list" dense component="div" role="list">
        {items?.map(value => {
          const labelId = `transfer-list-all-item-${value.displayName}-label`;
          return (
            <ListItem key={value.displayName} role="listitem" button >              
              <ListItemText id={labelId} primary={value.displayName}/>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
    return (
      
     <Grid container spacing={2} justify="left" alignItems="center" className="mar-Auto">
      <Grid item className="py-0 mr-3">{customList('Selected', rolesData)}</Grid>
      <Grid item className="py-0 pr-0">{customerWorkItemList('Selected', workData)}</Grid>
    </Grid>

    );
}
export default RolesWorkUnit;
