import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Bootstrap, { Button, Form } from 'react-bootstrap';
import InputLabel from "@material-ui/core/InputLabel";
import * as ErrorConstants from '../Messages/ErrorMsgConstants';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function NoteAddForm(props) {
    const {
        noteSetListInput,
        handleChangeValue,
        usageTypeCodeData,
        wordCount,
        usageTypeError,
        usageTypeErrorText,
        totalCharacters,
        textError,
        textErrorText,
        checkWordCount} = props;
    return (
        <form autoComplete="off">
            <div className="tab-body-bordered mt-2">
                <div className="form-wrapper">

                <div className="mui-custom-form with-select input-md custom-input-select">
                    <InputLabel htmlFor="note-usage-type-code" title="Usage Type Code"><span className="required-label">*</span>Usage Type Code</InputLabel>

                    <NativeSelect
                        id="note-usage-type-code"
                        value={noteSetListInput.usageTypeCode}
                        inputProps={{  maxLength: 10}}
                        onChange={handleChangeValue('usageTypeCode')}
                        placeholder="Please Select One"
                        InputLabelProps={{
                            shrink: true,
                            required: true,
                            name: 'name',
                            id: 'note-usage-type-code' 
                        }}
                    >
                        <option value="Please Select One">Please Select One</option>
                        {usageTypeCodeData
                        ? usageTypeCodeData.map(option => (
                          <option key={option.code} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                    </NativeSelect>
                    <FormHelperText className="required-label">{usageTypeError ? usageTypeErrorText:null}</FormHelperText>
                </div>
                {/* <div className="mui-custom-form with-select input-md">
                <TextField
                      id="usageTypeCode"
                      fullWidth
                      required
                      select
                      value={noteSetListInput.usageTypeCode}
                      onChange={handleChangeValue('usageTypeCode')}
                      label="Usage Type Code"
                    error={usageTypeError ? usageTypeErrorText : null}
                    helperText={usageTypeError ? usageTypeErrorText : null}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <MenuItem
                      selected
                      key="Please Select One"
                      value="Please Select One"
                    >
                      Please Select One
                      </MenuItem>
                      {usageTypeCodeData
                        ? usageTypeCodeData.map(option => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.description}
                          </MenuItem>
                        ))
                        : null}
                    </TextField>
                </div> */}
                <div className="mui-custom-form input-md field-xl">
                    <Form.Group>
                      <Form.Label className='MuiFormLabel-root  MuiInputLabel-root MuiInputLabel-shrink'>
                      <span className="Mui-required" id="text-label-01">
                        *
                      </span>
                      Note Description</Form.Label>
                      <Form.Control 
                      autoFocus
                      as="textarea" 
                      rows="4" 
                      name='text' 
                      aria-label='Note Description'
                      value={noteSetListInput.noteText} 
                      onChange={handleChangeValue('noteText')} 
                      onKeyUp={checkWordCount} 
                      maxLength={totalCharacters} />
                       {textError > 0 ? (
                      <p style={{fontSize: '0.75rem'}}
                        className="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                      >
                        {textErrorText}
                      </p>
                    ) : null}
                    </Form.Group>
                    <div className="mt-1"><Chip avatar={<Avatar>{wordCount}</Avatar>} label="Characters remaining" /></div>
                  </div>
                </div>
            </div>
        </form>
    )
}