export const GET_APP_DROPDOWNS = 'GET_APP_DROPDOWNS_ACTION_TYPE';
export const GET_NETWORKID_DROPDOWN = 'GET_NETWORKID_DROPDOWN_ACTION_TYPE';
export const GET_MAP_ID_DROPDOWN ="GET_MAP_ID_DROPDOWNS";
export const GET_SYSTEMLIST_DROPDOWNS = 'GET_SYSTEMLIST_DROPDOWNS';
export const SET_PRINT_LAYOUT= 'SET_PRINT_LAYOUT';
export const GET_SYSTEM_LIST_NONVV_DROPDOWNS = 'GET_SYSTEM_LIST_NONVV_DROPDOWNS';
export const SET_NAVIGATION_PRIVILEGES = 'SET_NAVIGATION_PRIVILEGES';
export const GET_NETWORK_DATA_DROPDOWN = 'GET_NETWORK_DATA_DROPDOWN';
export const GET_SA_RSN_DATA_DROPDOWN = 'GET_SA_RSN_DATA_DROPDOWN';
export const BENEFIT_PLAN_LIST ='BENEFIT_PLAN_LIST';
