/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/

import React from 'react';
import "../../App.scss"
import "../../theme.scss"
import * as TextConst from "./ManageAccountConstants";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import NativeDropDown from "../../SharedModules/Dropdowns/NativeDropDown";

function UserDetails(props){
    const {userData} = props;
    return(
        <>
                <form autoComplete="off">
                    <div>
                        <div className="form-wrapper">
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-user-id-disabled"
                                            label={TextConst.USER_ID}
                                            className="inline-lable-ttl"
                                            value={userData._id}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-user-status"
                                            label={TextConst.USER_STATUS}
                                            className="inline-lable-ttl"
                                            value={userData.accountStatus}
                                            disabled
                                            inputProps={{className: 'txt-caps'}}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-organization"
                                            label={TextConst.ORGANIZATION}
                                            className="inline-lable-ttl"
                                            value={userData.organization}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    
                                    <div className="mui-custom-form input-md ">
                                            <TextField
                                                id="user-password-expires"
                                                label={TextConst.PASSWORD_EXPIRES}
                                                className="inline-lable-ttl"
                                                value={userData.passwordExpiry}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                            
                        </div>
                        <div className="form-wrapper">
                            <NativeDropDown
                                id="user-prefix"
                                        label={TextConst.PREFIX}
                                        value={userData.prefix}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                        dropdownList={
                                            props.dropdowns &&
                                                Object.keys(props.dropdowns).length > 0 &&
                                                props.dropdowns["General#1011"] &&
                                                props.dropdowns["General#1011"].length > 0 &&
                                                props.dropdowns["General#1011"].map((each) => (
                                                    <option selected key={each.code} value={each.code}>
                                                    {each.description}
                                                    </option>
                                                ))}
                            />
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-first-name"
                                        label={TextConst.FIRST_NAME}
                                        className="inline-lable-ttl"
                                        disabled
                                        value={userData.givenName}
                                        inputProps={{ maxLength: 25 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-last-name"
                                        label={TextConst.LAST_NAME}
                                        className="inline-lable-ttl"
                                        disabled
                                        value={userData.sn}
                                        inputProps={{ maxLength: 35 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-middle-name"
                                        label={TextConst.MIDDLE_NAME}
                                        className="inline-lable-ttl"
                                        value={userData.middleName}
                                        inputProps={{ maxLength: 1 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled
                                    />
                                </div>
                            <NativeDropDown
                                        id="user-suffix"
                                        select
                                        label={TextConst.SUFFIX}
                                        value={userData.suffix}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                        dropdownList={
                                            props.dropdowns &&
                                                Object.keys(props.dropdowns).length > 0 &&
                                                props.dropdowns["General#1053"] &&
                                                props.dropdowns["General#1053"].length > 0 &&
                                                props.dropdowns["General#1053"].map((each) => (
                                                    <option selected key={each.code} value={each.code}>
                                                    {each.description}
                                                    </option>
                                                ))}
                            />
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-phone"
                                        label={TextConst.PHONE}
                                        className="inline-lable-ttl"
                                        disabled
                                        value={userData.telephoneNumber}
                                        inputProps={{ maxLength: 10 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                <TextField
                                    id="user-email"
                                    label={TextConst.EMAIL}
                                    className="inline-lable-ttl"
                                    disabled
                                    value={userData.mail}
                                    inputProps={{ maxLength: 99 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            <div className="mui-custom-form input-md ">
                                            <TextField
                                                id="user-last-login"
                                                label={TextConst.LAST_LOGIN}
                                                className="inline-lable-ttl"
                                               value={userData.lastLogin === "----------"?"":userData.lastLogin}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                            </div>
                    </div>
                </form>
        </>
    )
}

export default UserDetails;