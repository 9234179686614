/**
 * © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
 * Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
 * States and/or other countries.
 */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import { Button } from 'react-bootstrap';
import * as ErrorConst from '../../../../SharedModules/Messages/ErrorMsgConstants_copy';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import NavigationPrompt from 'react-router-navigation-prompt';
import * as ManageAccTextConst from '../../ManageAccountConstants';
import RolesWorkUnit from './RolesWorkUnit';
import UserDetails from './UserDetails';
import {
  UserRolesDropdownActions,
  WorkUnitsDropdownActions,
  AppConfigDropdownActions,
} from '../../../../SharedModules/DropdownService/Actions/AppActions';
import { validateUserAction, saveUserDetailsAction } from '../../action';
import * as Dropdowns from '../../../../SharedModules/Dropdowns/dropdowns';
import { GET_SYSTEMLIST_DROPDOWN } from '../../../../SharedModules/Dropdowns/actions';
import { useHistory } from 'react-router-dom';
import { USER_SEARCH } from '../../../../SharedModules/Navigation/RouteConstants';


const AddManageAccount = props => {
  //test
  const dispatch = useDispatch();
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    userId: '',
    prefix: '-1',
    organization: '',
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '-1',
    phone: '',
    email: '',
    userRole: [],
    workUnit: [],
  });
  const [defaultValues, SetDefaultValues] = React.useState(values);
  const [
    {
      userIdValid,
      firstNameReq,
      lastNameReq,
      isFirstNameValid,
      isMiddleNameValid,
      isLastNameValid,
      phoneNumberReq,
      organizationReq,
      emailReq,
      userRolesReq,
      workUnitsReq,
      emailInvErr,
      phoneInvErr,
    },
    setErrors,
  ] = React.useState(false);
  const [saveEnable, setSaveEnable] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);
  const dropDownUserRoles = () => dispatch(UserRolesDropdownActions());
  const dropDownWorkUnits = () => dispatch(WorkUnitsDropdownActions());
  const onDropdowns = values => dispatch(GET_SYSTEMLIST_DROPDOWN(values));
  const dropDownDispatch = configKeyList =>
    dispatch(AppConfigDropdownActions(configKeyList));
  const validateUserDispatch = userId => dispatch(validateUserAction(userId));
  const saveUserDispatch = data => dispatch(saveUserDetailsAction(data));
  const [userRolesDropdownData, setuserRolesDropdownData] = React.useState();
  const [workUnitsDropdownData, setworkUnitsDropdownData] = React.useState();
  const userRolesDropdown = useSelector(
    state => state.sharedState.userRolesDropdown
  );
  const workUnitsDropdown = useSelector(
    state => state.sharedState.workUnitsDropdown
  );
  const history = useHistory()
  const dropdownData = useSelector(
    state => state.sharedState.appConfigDropdown
  );
  const sysDropdowns = useSelector(state => state.appDropDowns.sysdropdowns);
  const validateUserResponse = useSelector(
    state => state.userManagement.validateUserResponse
  );
  const validateUserTime = useSelector(
    state => state.userManagement.validateUserTime
  );
  const saveUserDetailsResponse = useSelector(
    state => state.userManagement.saveUserDetailsResponse
  );

  useEffect(() => {
    !!userRolesDropdown && setuserRolesDropdownData(userRolesDropdown);
    !!workUnitsDropdown && setworkUnitsDropdownData(workUnitsDropdown);
  }, [userRolesDropdown, workUnitsDropdown]);

  const handelPromptSet = set => {
    if (set) setPrompt(true);
  };
  useEffect(() => {
    onDropdowns({
      inputList: [Dropdowns.DM_SFX_NAM, Dropdowns.G_NAM_PREFX_CD],
    });
    dropDownUserRoles();
    dropDownWorkUnits();
  }, []);
  useEffect(() => {
    if (validateUserResponse) {
      setspinnerLoader(false);
      if (validateUserResponse.statusCode == '01') {
        if (
          validateUserResponse.responseObject &&
          validateUserResponse.responseObject.valid
        ) {
          setSuccessMessages([`${values.userId} successfully validated.`]);
          setSaveEnable(true);
        } else {         
          seterrorMessages([ErrorConst.USER_ID_EXIST]);
          setErrors({
            userIdValid: ErrorConst.USER_ID_EXIST,
          });
        }
      } else {
        const errorText = `System fails to validate the user ${values.userId} credentials.`;
        seterrorMessages([errorText]);
        setErrors({
          userIdValid: errorText,
        });
      }
    }
  }, [validateUserTime]);
  useEffect(() => {
    if (saveUserDetailsResponse) {
      setspinnerLoader(false);
      if (saveUserDetailsResponse.statusCode == '01') {
        setSuccessMessages(['The user is created successfully']);
      } else if (
        saveUserDetailsResponse.statusDescription &&
        saveUserDetailsResponse.statusDescription.search('already exists') > 0
      ) {       
        seterrorMessages([ErrorConst.USER_ID_EXIST]);
        setErrors({
          userIdValid: ErrorConst.USER_ID_EXIST,
        });
      } else {
        const errorText = `The system failed to store the new user ${values.userId}. Please try again later or contact the system administrator.`;
        seterrorMessages([errorText]);
        setErrors({
          userIdValid: errorText,
        });
      }
    }
  }, [saveUserDetailsResponse]);
  const handleValuesChanges = name => event => {
    if (name == 'userId') {
      setSaveEnable(true);
    }
    if (
      name === 'organization' ||
      name === 'firstName' ||
      name === 'lastName' ||
      name === 'middleName' ||
      name === 'email' ||
      name === 'userId'
    ) {
      setValues({ ...values, [name]: event.target.value });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };
  const [isReset,setIsReset] = useState(false)
  const handelReset = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    setValues({...defaultValues});
    setErrors(false);
    setIsReset(true)
    setSaveEnable(false)
  };
  const handelCancel = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    if (JSON.stringify(values) == JSON.stringify(defaultValues)) {
      setConfirm(true);
    }
    //setTimeout(() => {

      props.history.push({
        pathname: USER_SEARCH,
      });

    //}, 200);
  };
  const containsSpecialCharacters = str => {
    let regex = /^(?!.*[\s])(?=.*[A-Za-z])(?=.*[0-9])[-_.A-Za-z0-9]{6,16}$/;
    return !regex.test(str); 
  };
  const handleValidateUser = () => {
    let errorMessagesArray = [];
    setSuccessMessages([]);
    seterrorMessages([]);
    setspinnerLoader(true);
    setErrors(false);
    if (
      !values.userId ||
      containsSpecialCharacters(values.userId)
    ) {
      errorMessagesArray.push(ErrorConst.USERID_INVALID);
      setErrors({ userIdValid: true });
    }
    if (errorMessagesArray.length > 0) {
      seterrorMessages(errorMessagesArray);
      setspinnerLoader(false);
      return false;
    }
    validateUserDispatch(values.userId);
  };
  const handelSave = () => {
    let errorMessagesArray = [];
    setSuccessMessages([]);
    seterrorMessages([]);
    setspinnerLoader(true);
    const EMAIL_ADDRESS_REGEX =
      /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]){2,4}$/;
    const nameRegex = /^[a-zA-Z\s]+$/;
    const isUserNameValid = name => nameRegex.test(name);

    setErrors({
      userIdValid:
        !values.userId ||
        containsSpecialCharacters(values.userId)
          ? (() => {
              errorMessagesArray.push(ErrorConst.USERID_INVALID);
              return true;
            })()
          : false,
      firstNameReq: !values.firstName
        ? (() => {
            errorMessagesArray.push(ErrorConst.FIRST_NAME_REQ);
            return true;
          })()
        : false,
      lastNameReq: !values.lastName
        ? (() => {
            errorMessagesArray.push(ErrorConst.LAST_NAME_REQ_ERR);
            return true;
          })()
        : false,
      isFirstNameValid:
        values.firstName && !isUserNameValid(values.firstName)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INVALID_USER_FIRST_NAME);
              return true;
            })()
          : false,
      isMiddleNameValid:
        values.middleName && !isUserNameValid(values.middleName)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INVALID_USER_MIDDLE_NAME);
              return true;
            })()
          : false,
      isLastNameValid:
        values.lastName && !isUserNameValid(values.lastName)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INVALID_USER_LAST_NAME);
              return true;
            })()
          : false,
      phoneNumberReq: !values.phone
        ? (() => {
            errorMessagesArray.push(ErrorConst.PHONE_REQ);
            return true;
          })()
        : false,
      organizationReq: !values.organization
        ? (() => {
            errorMessagesArray.push(ErrorConst.ORGANIZATION_REQ);
            return true;
          })()
        : false,
      emailReq: !values.email
        ? (() => {
            errorMessagesArray.push(ErrorConst.EMAIL_REQ);
            return true;
          })()
        : false,
      userRolesReq:
        values.userRole.length == 0
          ? (() => {
              errorMessagesArray.push(ErrorConst.USER_ROLES_REQ);
              return true;
            })()
          : false,
      workUnitsReq:
        values.workUnit.length == 0
          ? (() => {
              errorMessagesArray.push(ErrorConst.WORK_UNITS_REQ);
              return true;
            })()
          : false,
      emailInvErr:
        values.email && !EMAIL_ADDRESS_REGEX.test(values.email)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INV_CONTENT);
              return true;
            })()
          : false,
      phoneInvErr:
        values.phone && !/^[0-9-+]+$/.test(values.phone)
          ? (() => {
              errorMessagesArray.push(ErrorConst.INV_CONTENT);
              return true;
            })()
          : false,
    });
    if (errorMessagesArray.length > 0) {
      seterrorMessages(errorMessagesArray);
      setspinnerLoader(false);
      return false;
    } else {
      let data = {
        workUnits: workUnitsDropdownData.filter(e =>
          values.workUnit.includes(e._id)
        ),
        userRoles: userRolesDropdownData.responseObject.filter(e =>
          values.userRole.includes(e._id)
        ),
        userName: values.userId.toUpperCase(),
        organization: values.organization.toUpperCase(),
        prefix: values.prefix != '-1' ? values.prefix : '',
        givenName: values.firstName.toUpperCase(),
        middleName: values.middleName.toUpperCase(),
        sn: values.lastName.toUpperCase(),
        suffix: values.suffix != '-1' ? values.suffix : '',
        mail: values.email.toUpperCase(),
        telephoneNumber: values.phone,
      };
      saveUserDispatch(data);
    }
  };

  return (
    <div className="pos-relative">
      {spinnerLoader ? <Spinner setspinnerLoader={setspinnerLoader} /> : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </div>
      ) : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </div>
      ) : null}
      <div className="tabs-container">
        <div className="tab-header">
          <h1 className="page-heading float-left">
            {ManageAccTextConst.ADD_PAGE_HEADING}
          </h1>
          <div className="float-right th-btnGroup">
            <Button
              title="Save"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-save"
              disabled={!saveEnable}
              onClick={() => handelSave()}
            >
              {ManageAccTextConst.SAVE_BUTTON}
            </Button>
            <Button
              title="Reset"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-reset"
              onClick={handelReset}
            >
              {ManageAccTextConst.RESET_BUTTON_LABEL}
            </Button>
            <Button
              title="Cancel"
              variant="outlined"
              color="primary"
              className="btn btn-cancel"
              onClick={handelCancel}
            >
              {ManageAccTextConst.CANCEL_BUTTON}
            </Button>
          </div>
        </div>
        <div className="rquired-header">* Required Field</div>
        <div className="custom-hr my-1 pb-1" />
        <div className="tab-body mt-2">
          <div className="tab-body m-3 py-2">
            <UserDetails
              type="Add"
              dropdowns={sysDropdowns}
              values={values}
              handleValuesChanges={handleValuesChanges}
              handleValidateUser={handleValidateUser}
              errors={{
                userIdValid,
                firstNameReq,
                lastNameReq,
                isFirstNameValid,
                isMiddleNameValid,
                isLastNameValid,
                phoneNumberReq,
                organizationReq,
                emailReq,
                emailInvErr,
                phoneInvErr,
              }}
            />
            <RolesWorkUnit
              userRoleList={userRolesDropdownData}
              workUnitList={workUnitsDropdownData}
              values={values}
              setValues={setValues}
              defaultValues={defaultValues}
              isReset={isReset}
              setIsReset={setIsReset}
              errors={{ userRolesReq, workUnitsReq }}
            />
          </div>
        </div>
        
      </div>
      <div className=''>
      <NavigationPrompt
        when={(crntLocation, nextLocation) => {
          if (confirm) {
            return false;
          } else {
            handelPromptSet(nextLocation);
            return true;
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <Dialog
            open={prompt}
            onClose={() => {
              setPrompt(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box-inject"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                  {ManageAccTextConst.POPUP_UNSAVED_MSG1} <br />
                  {ManageAccTextConst.POPUP_UNSAVED_MSG2}
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setPrompt(false);
                  onCancel();
                }}
                color="primary"
                className="btn btn-transparent"
              >
                {ManageAccTextConst.POPUP_STAY_ON}
              </Button>
              <Button
                onClick={onConfirm}
                color="primary"
                className="btn btn-success"
                autoFocus
              >
                {ManageAccTextConst.POPUP_STAY_CONTINUE}{' '}
                <i className="fa fa-arrow-right ml-1"></i>
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </NavigationPrompt>
      </div>

    </div>
  );
};
export default withRouter(AddManageAccount);
