export const getLoginUserDetails = ()=>{
   let username = localStorage.getItem("loginState")? JSON.parse(localStorage.getItem("loginState")):"";
   return (username && username.loginUserName?
    {loginUserName:username.loginUserName,loginUserID:username.logInUserId}:'');
}
export const scrollToElement = (ref, time = 10) => {
    if (ref) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, time);
    }
  };
  
export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  };

export const safeLocalStorage = (actType, storeName, data) => {
    let res;
    try {
        if (actType === "set") {
            res = localStorage.setItem(storeName, data);
        } else if (actType === "get") {
            res = localStorage.getItem(storeName);
        } else if (actType === "remove") {
            res = localStorage.removeItem(storeName);
        }
    } catch {
        console.error("No access to local storage.");
    }
    return res;
};
  