import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as serviceEndPoint from '../../SharedModules/services/service';
import { CLAIMS_APP_CODE } from '../AppConstants';

export const dispatchDropdowns = (response,dynamicType) => ({
    type: dynamicType?dynamicType:actionTypes.GET_APP_DROPDOWNS,
    dropdowns: response
});
export const dispatchSystemDropdowns = (response) => ({
    type: actionTypes.GET_SYSTEMLIST_DROPDOWNS,
    sysdropdowns: response
});

export const dispatchSystemNonVVDropdowns = (response) => ({
    type: actionTypes.GET_SYSTEM_LIST_NONVV_DROPDOWNS,
    nonvvdropdowns: response
});

export const dispatchMatchDropdowns = (response) =>({
    type:actionTypes.GET_MAP_ID_DROPDOWN,
    mapIDDropdown:response
})

export const dispatchNetworkidDropdown = (response) => ({
    type: actionTypes.GET_NETWORKID_DROPDOWN,
    networkIdDropdown: response
})

export const dispatchNetworkDataDropdown = (response) => ({
    type: actionTypes.GET_NETWORK_DATA_DROPDOWN,
    networkDataDropdown: response
})

export const dispatchReasonDataDropdown = (response) => ({
    type: actionTypes.GET_SA_RSN_DATA_DROPDOWN,
    saReasonDataDropdown: response
})

export const setPrintLayout = (data) => {
    return {
      type: actionTypes.SET_PRINT_LAYOUT,
      printLayout: data
    };
  };
  export const setNavigationPrivileges = (data) => {
    return {
      type: actionTypes.SET_NAVIGATION_PRIVILEGES,
      privileges: data
    };
  };

  export const dispatchBenefitCode = (response) => ({
    type: actionTypes.BENEFIT_PLAN_LIST,
    urcBenefitCodeList: response
});

  export const GET_APP_DROPDOWNS = (values,dynamicType) => dispatch => {    
    return axios.post(`${serviceEndPoint.GET_APP_DROPDOWN_ENDPOINT}`, values)
        .then(response => {            
            if (Object.keys(response.data.listObj).length > 0) {
                dispatch(dispatchDropdowns(response.data.listObj,dynamicType));
            }
        })
}

export const GET_MAP_SET_ID = values => dispatch =>{
    return axios.post(`${serviceEndPoint.MAP_ID_DROPDOWN}?lobCode=MED`)
    .then(response =>{
        console.log(response);
        dispatch(dispatchMatchDropdowns(response.data.searchResultsValues))
    })
}

export const GET_NETWORKID_DROPDOWN = () => dispatch => {    
    return axios.get(`${serviceEndPoint.GET_NETWORKID_DROPDOWN_ENDPOINT}`)
        .then(response => {            
            dispatch(dispatchNetworkidDropdown(response.data));
        })
}
export const GET_SYSTEMLIST_DROPDOWN = values => dispatch => {   
    let config = {
        headers: {
          'X-CMDS-APP-CODE' :CLAIMS_APP_CODE,
          Authorization: localStorage.getItem("accessToken"),
          Username: localStorage.getItem("username"),
        },
      };
    
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_GETREFERENCEDATA_ENDPOINT}`, values,config)
        .then(response => {            
            if (Object.keys(response.data.listObj).length > 0) {
                dispatch(dispatchSystemDropdowns(response.data.listObj));
            }
        })
}

export const GET_SYSTEM_LIST_NONVV_DROPDOWNS = values => dispatch => {  
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_NONVV_ENDPOINT}`, values)
        .then(response => {    
            if (Object.keys(response.data.listObj).length > 0) {
                 dispatch(dispatchSystemNonVVDropdowns(response.data.listObj));  
            }
        })
}

export const GET_NETWORK_DATA_DROPDOWN = () => dispatch => {    
    return axios.get(`${serviceEndPoint.NETWORK_DATA_DROPDOWN}`)
        .then(response => {            
            dispatch(dispatchNetworkDataDropdown(response.data));
        })
}

