import React from 'react';
import moment from 'moment';
import dateFnsFormat from 'date-fns/format';

export const getDateInMMDDYYYYFormat = (enteredDate) => {

  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
    const tempDate = new Date(enteredDate);
    return (tempDate.getMonth() + 1) + '/' + tempDate.getDate() + '/' + tempDate.getFullYear();
  }
  return null;
};

export const getDateInMMDDYYYYFormatWithApendZero = (enteredDate) => {

  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
    const tempDate = new Date(enteredDate);
    let date = null;
    let month = null;
    if ((tempDate.getMonth() + 1) < 9) {
      month = '0' + (tempDate.getMonth() + 1).toString();
    } else {
      month = (tempDate.getMonth() + 1).toString();
    }
    if (tempDate.getDate() < 9) {
      date = '0' + (tempDate.getDate()).toString();
    } else {
      date = tempDate.getDate().toString();
    }
    return (month + '/' + date + '/' + tempDate.getFullYear().toString());
  }
  return null;
};

export const validateDateMinimumValue = date => {

  const _beginDate = new Date(date);
  const _minBeginDate = new Date(1964, 0, 1, 0, 0, 0, 0); // 1964-1-1
  if (_beginDate >= _minBeginDate) {
    return false;
  } else {
    return true;
  }
};

/* This function is used to convert datetime to timestamp format */
export const setSelectedDate = date => {

  var selectedDate = new Date(null);

  if (((date.getMonth() + 1) < 10 && (date.getMonth() + 1) > 0) && ((date.getDate()) < 10 && (date.getDate()) > 0)) {
    selectedDate = '0' + (date.getMonth() + 1) + '/0' + date.getDate() + '/' + date.getFullYear();
  } else if ((date.getMonth() + 1) < 10 && (date.getMonth() + 1) > 0) {
    selectedDate = '0' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  } else if ((date.getDate()) < 10 && (date.getDate()) > 0) {
    selectedDate = (date.getMonth() + 1) + '/0' + date.getDate() + '/' + date.getFullYear();
  } else {
    selectedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  return selectedDate;
};

export const generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export const getDifferenceInDays = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds Y-M-D
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);

  return (Math.abs((secondDate - firstDate) / oneDay));
};

// this function takes an array of date ranges in this format:
// [{ start: Date, end: Date}]
// the array is first sorted, and then checked for any overlap

export const overlapCheckWithDateRange = (dateRanges) => {
  var sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    var previousTime = previous.start.getTime();
    var currentTime = current.start.getTime();

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // if the previous time is later than the current time
    return 1;
  });

  var result = sortedRanges.reduce((result, current, idx, arr) => {
    // get the previous range
    if (idx === 0) { return result; }
    var previous = arr[idx - 1];

    // check for any overlap
    var previousEnd = previous.end.getTime();
    var currentStart = current.start.getTime();
    var overlap = (previousEnd >= currentStart);

    // store the result
    if (overlap) {
      // yes, there is overlap
      result.overlap = true;
      // store the specific ranges that overlap
      result.ranges.push({
        previous: previous,
        current: current
      });
    }

    return result;

    // seed the reduce
  }, { overlap: false, ranges: [] });
  // return the final results
  return result;
};
export const convertDateTimeToTimestamp = (dateTimeValue) => {
  var dateTimestamp = Date.parse(dateTimeValue);
  return dateTimestamp/1000;
};

/* This function is used to compare two dates 
 * Returns false if date1 is greater than date2
 * Return true if date1 is less than or equals to date2
*/
export const compareTwoDates = (date1, date2) => {
  var dateone = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  var datetwo = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
  if (dateone > datetwo) {
    return false;
  } else {
    return true;
  }
};

export const compareTwoDatesGreaterThanOrEqual = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  var dateone = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  var datetwo = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
  if (dateone >= datetwo) {
    return false;
  } else {
    return true;
  }
};

/* This function is used to validate date string
 * Returns false if month, day or year is not a valid one
*/
export const checkForValidDateString = (dateString) => {
  // First checking for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) { return false; }

  // Parsing the date parts to integers
  var parts = dateString.split('/');
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Checking the ranges of month and year
  if (year > 9999 || month === 0 || month > 12) {
    return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Checking the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

/* This function returns UTC timestamp for auditTimeStamp and addedAuditTimeStamp
*/
export const getUTCTimeStamp = () => {
  return moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
};

export const getDateInYYYYMMDDFormatWithApendZero = (enteredDate) => {
  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
    const tempDate = new Date(enteredDate);
    let date = null;
    let month = null;
    if ((tempDate.getMonth() + 1) <= 9) {
      month = '0' + (tempDate.getMonth() + 1).toString();
    } else {
      month = (tempDate.getMonth() + 1).toString();
    }
    if (tempDate.getDate() <= 9) {
      date = '0' + (tempDate.getDate()).toString();
    } else {
      date = tempDate.getDate().toString();
    }
    return (tempDate.getFullYear().toString() + '-' + month + '-' + date);
    // return moment(enteredDate).format('MM/DD/YYYY');
  }
  return null;
};

/* Problem: Javascript parses first centruy dates to latest century dates.
 * Solution: This function is used to parse first century date string to Date object without changing date to latest century.
 * Input: Date string in MM/dd/yyyy format.
 * Output: Date Object or same input value.
*/
export const parseFirstCenturyDateString = (dt) => {
  if (!dt || !(typeof dt == "string")) {
    return dt;
  }
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dt)) {
    return dt;
  }
  if (new Date(dt).toString() == "Invalid Date") {
    return dt;
  }
  let split = dt.split('/');
  if (split[2] < 100) {
    return new Date(split[2] + '-' + split[0] + '-' + split[1]);
  }
  return dt;
};


/*
 * This function overrides or mimics Javascript new Date
 */
// export const newDate = (dt) => {    
//     if(typeof dt == "undefined"){
//       return new Date();
//     }
//     if(typeof dt == "string"){
//       return new Date(parseFirstCenturyDateString(dt));
//     }
//     if(typeof dt == "object"){
//       return new Date(dt);
//     }
//     return new Date(dt);
// };

export const formatDate = (dt) => {
  if (!dt) {
      return "";
  }
  dt = new Date(dt);
  if (dt.toString() == "Invalid Date") {
      return dt;
  } else {
      return dateFnsFormat(dt, "MM/dd/yyyy");
  }
};


/* Problem: Javascript parses first centruy dates to latest century dates.
 * Solution: This function is used to parse first century date string to Date object without changing date to latest century.
 * Input: Date string in MM/dd/yyyy format.
 * Output: Date Object or same input value.
*/

export const parseDateToMMDDYYYY = dt => {
  if (!dt) {
    return dt;
  }
  if (new Date(dt).toString() == 'Invalid Date') {
    return dt;
  }
  if (typeof dt == 'string') {
    return convertToMMDDYYYYFormat(dt);
  } else {
    return dateFnsFormat(dt, 'MM/dd/yyyy');
  }
};
export const parseDate = (dt) => {
  if(!dt){
    return dt;
  }
  if (new Date(dt).toString() == "Invalid Date") {
    return dt;
  }
  if (!dt || !(typeof dt == "string") && typeof dt == "object") {
    return dateFnsFormat(dt, "MM/dd/yyyy");
  }    
  let cdt = convertToMMDDYYYYFormat(dt),split = cdt.split('/');  
  if (split[2] < 100) {
    return new Date(new Date(cdt).setFullYear(split[2]));
  }
  return cdt;
};
export const handelDateRngeArrayOverlap = (initalStartDate, initialEndDate, inputArray, index) => {
  if (inputArray.length > 0) {
    const result = inputArray.map((each, i) => {
      if (i == index) {
        return false;
      }
      return newDate(initalStartDate) <= newDate(each.endDate) && newDate(initialEndDate) >= newDate(each.beginDate);
    });
    if (result.filter(e => e === true).length > 0) {
      return true
    } else {
      return false;
    }
  } else {
    return false
  }
};

/*
 * This function overrides or mimics Javascript new Date
 */
export const newDate = (dt) => {    
    if(typeof dt == "undefined"){
      return new Date();
    }
    if(typeof dt == "string"){
      return new Date(parseDate(dt));
    }
    if(typeof dt == "object"){
      return new Date(dt);
    }
    return new Date(dt);
};

/*
 * Converts YYYY-MM-DD date string to MM/DD/YYYY
 */
export const convertToMMDDYYYYFormat = (r) => {
  try{
    let m = [...r.matchAll(/^(\d\d\d\d)[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/igm)];
    m.forEach((v,i)=>{
    r = r.replace(v[0],`${v[2]}/${v[3]}/${v[1]}`);
    });
    m = [...r.matchAll(/^(\d\d\d\d)[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])(T\d\d:\d\d:\d\d.\d\d\dZ)$/igm)];
    m.forEach((v,i)=>{      
      r = r.replace(v[0], `${v[2]}/${v[3]}/${v[1]}`);
    });
    m = [...r.matchAll(/^(\d\d\d\d)[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])(T\d\d:\d\d:\d\d.\d\d\d\+\d\d\d\d)$/igm)];
    m.forEach((v,i)=>{      
      r = r.replace(v[0], `${v[2]}/${v[3]}/${v[1]}`);
    }); 
    m = [...r.matchAll(/^(\d\d\d\d)[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])(T\d\d:\d\d:\d\d.\d\d\d\-\d\d\d\d)$/igm)];
    m.forEach((v,i)=>{      
      r = r.replace(v[0], `${v[2]}/${v[3]}/${v[1]}`);
    });   
    return r;
  }catch(e){
    return r;
  }  
};