/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React, { useEffect,useState } from "react";
import * as TextConst from "../../ManageAccountConstants";
import * as ErrorConst from "../../../../SharedModules/Messages/ErrorMsgConstants_copy";
import FormHelperText from '@material-ui/core/FormHelperText';
import TransferList from "../../../../SharedModules/TransferList/TransferListUpdate";

const RolesWorkUnit = (props) => { 
const { userRoleList, workUnitList, isReset } = props
const [transferListUserRoleValues, setTransferUserRoleListValues] = useState([]) //shifted Values
const [transferListworkUnitValues, setTransferworkUnitListValues] = useState([]) //shifted Values

let userRoleListState =
  (userRoleList &&
    userRoleList.responseObject &&
    !userRoleList.responseObject.message &&
    userRoleList.responseObject) ||
  []
let workUnitListState = workUnitList || []
useEffect(() => {
  if (isReset) {
    if (props?.defaultValues?.workUnit?.length === 0) {
      setTransferworkUnitListValues([])
      props.setIsReset(false)
    }

    if (props?.defaultValues?.userRole?.length === 0) {
      setTransferUserRoleListValues([])
      props.setIsReset(false)
    }
  }
}, [isReset, props?.defaultValues?.workUnit, props?.defaultValues?.userRole])
useEffect(() => {
  if (props?.defaultValues?.workUnit?.length > 0) {
    let selected = props?.defaultValues?.workUnit
    let _transferListworkUnitValues = []
    workUnitListState &&
      workUnitListState.map(e => {
        if (selected.includes(e?._id)) {
          _transferListworkUnitValues.push(e)
        }
      })
    setTransferworkUnitListValues(_transferListworkUnitValues)
  }
}, [workUnitListState, isReset])

useEffect(() => {
  if (props?.defaultValues?.userRole?.length > 0) {
    let selected = props?.defaultValues?.userRole
    let _transferListUserRoleValues = []
    userRoleListState &&
      userRoleListState.map(e => {
        if (selected.includes(e?._id)) {
          _transferListUserRoleValues.push(e)
        }
      })
    setTransferUserRoleListValues(_transferListUserRoleValues)
  }
}, [userRoleListState, isReset])

useEffect(() => {
  let workUnitIDs = transferListworkUnitValues.map(item => item._id)
  props.setValues(prevValues => ({ ...prevValues, workUnit: workUnitIDs }))
}, [transferListworkUnitValues, props.setValues])

useEffect(() => {
  let userRoleIDs = transferListUserRoleValues.map(item => item._id)
  props.setValues(prevValues => ({ ...prevValues, userRole: userRoleIDs }))
}, [transferListUserRoleValues, props.setValues])

    return (
        <div className="tabs-holder">
            <div className="tab-body-bordered collapsable-panel mt-3">
                <div className="custom-panel"><div className="panel-header"><span>{TextConst.ROLES_WORKUNIT_HEADING}</span></div></div>
             <div className="mt-3">
             <div className="multi-select-area flex-block justify-content-around">
                            <div className="mui-custom-form my-2" style={{margin:'0px 6px'}}>              
                              <label className="MuiFormLabel-root small-label">
                                     <span>*</span>{" "+TextConst.USER_ROLES}
                               </label>
                            <TransferList 
                                 left={userRoleListState||[]}
                                 right={transferListUserRoleValues||[]}
                                 setTransferListValues={setTransferUserRoleListValues}
                                 disabled={(props.edit && (props.initialIndicators.pendingIndicator == "false" || props.initialIndicators.voidIndicator == "true")) || 
                                 props.shouldDisable}
                            />
                            <FormHelperText className="required-label">{props.errors.userRolesReq && ErrorConst.USER_ROLES_REQ}</FormHelperText>
                            </div>
                            <div className="mui-custom-form my-2" style={{margin:'0px 6px'}}>
                            <label className="MuiFormLabel-root small-label">
                                   <span>*</span>{" "+TextConst.WORK_UNITS}
                           </label>
                            <TransferList 
                                left={workUnitListState||[]}
                                 right={transferListworkUnitValues||[]}
                                 setTransferListValues={setTransferworkUnitListValues}
                                 disabled={(props.edit && (props.initialIndicators.pendingIndicator == "false" || props.initialIndicators.voidIndicator == "true")) ||
                                 props.shouldDisable}
                            />
                            <FormHelperText className="required-label">{props.errors.workUnitsReq && ErrorConst.WORKUNIT_IS_REQUIRED}</FormHelperText>
                            </div>
                        </div></div>
            </div>
        </div>
    );
}
export default RolesWorkUnit;
