/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function DisplayErrorMessages (props) {

    useEffect(() => {
        const timer = setTimeout(function () {
            props.setErrorMessages([]);
          }.bind(this), 10000);
          return () => clearTimeout(timer);
    });

  return (<div>{props.errorMessages.length > 0 ? <div className="alert alert-danger custom-alert hide-on-print" role="alert">
    {props.errorMessages.map((message, index) => <li key ={index}>{message}</li>)
    }
  </div> : null
  }</div>);
}

DisplayErrorMessages.propTypes = {
  errorMessages: PropTypes.array.isRequired
};
