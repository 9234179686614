import * as actionTypes from './AppActionTypes';
import * as serviceEndPoint from '../../services/service';
import axios from 'axios';

export const AppConfigDropdown = (appConfigDropdown) => {
  return ({
    type: actionTypes.APP_CONFIG_DROPDOWNS,
    appConfigDropdown: appConfigDropdown
  });
};
export const AppConfigDropdownActions = (value) => {
  return dispatch => {
   return axios.post(`${serviceEndPoint.REACT_APP_DROPDOWN_ENV}`, value)
    //return axios.post('http://10.142.119.208/esb/appconfig/configKeyList', value)
      .then(response => {
        dispatch(AppConfigDropdown(response.data));
      })
      .catch(error => {
        dispatch(AppConfigDropdown({ systemFailure: true }));
      });
  };
};

export const RulesConfigDropdown = (rulesConfigDropdown) => {
  return ({
    type: actionTypes.RULES_CONFIG_DROPDOWNS,
    rulesConfigDropdown: rulesConfigDropdown
  });}



export const UserRolesDropdown = (userRolesDropdown) => {
  return ({
    type: actionTypes.USER_ROLES_DROPDOWNS,
    userRolesDropdown: userRolesDropdown
  });
};
export const UserRolesDropdownActions = (value) => {
  return dispatch => {
   return axios.get(`${serviceEndPoint.USER_ROLES_DROPDOWN}`, value)
      .then(response => {
        dispatch(UserRolesDropdown(response.data));
      })
      .catch(error => {
        dispatch(UserRolesDropdown({ systemFailure: true }));
      });
  };
};

export const WorkUnitsDropdown = (workUnitsDropdown) => {
  return ({
    type: actionTypes.WORK_UNITS_DROPDOWNS,
    workUnitsDropdown: workUnitsDropdown
  });
};
export const WorkUnitsDropdownActions = (value) => {
  return dispatch => {
   return axios.get(`${serviceEndPoint.WORK_UNITS_DROPDOWN}`, value)
      .then(response => {
        let workUnitResponse = response.data

        workUnitResponse = workUnitResponse && workUnitResponse.responseObject && !workUnitResponse.responseObject.message && workUnitResponse.responseObject.map((item)=>{
          return {...item, ['description'] : item.displayName}
       })

        dispatch(WorkUnitsDropdown(workUnitResponse));
      })
      .catch(error => {
        dispatch(WorkUnitsDropdown({ systemFailure: true }));
      });
  };
};
