/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React from "react";
import * as TextConst from "../../ManageAccountConstants";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import * as ErrorConst from "../../../../SharedModules/Messages/ErrorMsgConstants_copy";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

const UserDetails = (props) => { 
    return (
        <div className="tabs-holder">
            <div className="tab-body-bordered collapsable-panel mt-3">
                <div className="custom-panel"><div className="panel-header"><span>{TextConst.USER_DETAILS_HEADING}</span></div></div>
                <form autoComplete="off">
                    <div className="mt-3">
                        <div className="form-wrapper">
                            {props.type == "Add" ? (
                                <>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-user-id"
                                        label={TextConst.USER_ID}
                                        className="inline-lable-ttl"
                                        required
                                        value={props.values.userId}
                                        onChange={props.handleValuesChanges('userId')}
                                        inputProps={{ maxLength: 16 , style:{textTransform:'uppercase'} }}
                                        helperText={props?.errors?.userIdValid?.constructor === String ? props.errors.userIdValid : props.errors.userIdValid ? ErrorConst.USERID_INVALID : null}
                                        error={props.errors.userIdValid}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position='end'>
                                                <IconButton color="primary" aria-label='user-validate' title="Click here to Validate" onClick={props.handleValidateUser}>
                                                    <LoopOutlinedIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-organization1"
                                        label={TextConst.ORGANIZATION}
                                        required
                                        value={props.values.organization}
                                        onChange={props.handleValuesChanges('organization')}
                                        className="inline-lable-ttl"
                                        inputProps={{ maxLength: 35, style:{textTransform:'uppercase'}  }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={props.errors.organizationReq ? ErrorConst.ORGANIZATION_REQ : null}
                                        error={props.errors.organizationReq ? ErrorConst.ORGANIZATION_REQ : null}
                                    />
                                </div>
                                </>
                            ) : (
                                <>
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-user-id-disabled"
                                            label={TextConst.USER_ID}
                                            className="inline-lable-ttl"
                                            value={props.values.userId}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-user-status"
                                            label={TextConst.USER_STATUS}
                                            className="inline-lable-ttl"
                                            value={props.values.userStatus}
                                            disabled
                                            inputProps={{className: 'txt-caps'}}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    <div className="mui-custom-form input-md ">
                                        <TextField
                                            id="user-organization"
                                            label={TextConst.ORGANIZATION}
                                            className="inline-lable-ttl"
                                            value={props.values.organization}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </div>
                                    <div className="mui-custom-form input-md ">
                                            <TextField
                                                id="user-password-expires"
                                                label={TextConst.PASSWORD_EXPIRES}
                                                className="inline-lable-ttl"
                                                value={props.values.passwordExpires}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>
                                </>
                            )}
                        </div>
                        <div className="form-wrapper">
                            <NativeDropDown
                                id="user-prefix"
                                        label={TextConst.PREFIX}
                                        value={props.values.prefix}
                                        onChange={props.handleValuesChanges('prefix')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={props.shouldDisable}
                                dropdownList={
                                    props.dropdowns &&
                                        Object.keys(props.dropdowns).length > 0 &&
                                        props.dropdowns["General#1011"] &&
                                        props.dropdowns["General#1011"].length > 0 &&
                                        props.dropdowns["General#1011"].map((each) => (
                                            <option selected key={each.code} value={each.code}>
                                            {each.description}
                                            </option>
                                        ))}
                            />
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-first-name"
                                        label={TextConst.FIRST_NAME}
                                        autoFocus={props.type === "Edit"}
                                        className="inline-lable-ttl"
                                        required
                                        value={props.values.firstName}
                                        disabled={props.shouldDisable}
                                        onChange={props.handleValuesChanges('firstName')}
                                        inputProps={{ maxLength: 25 , style:{textTransform:'uppercase'} }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={props.errors.firstNameReq ? ErrorConst.FIRST_NAME_REQ : props.errors.isFirstNameValid ? ErrorConst.INVALID_USER_FIRST_NAME : null}
                                        error={props.errors.firstNameReq || props.errors.isFirstNameValid}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-last-name"
                                        label={TextConst.LAST_NAME}
                                        className="inline-lable-ttl"
                                        required
                                        value={props.values.lastName}
                                        disabled={props.shouldDisable}
                                        onChange={props.handleValuesChanges('lastName')}
                                        inputProps={{ maxLength: 35 , style:{textTransform:'uppercase'} }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={props.errors.lastNameReq ? ErrorConst.LAST_NAME_REQ_ERR : props.errors.isLastNameValid ? ErrorConst.INVALID_USER_LAST_NAME : null}
                                        error={props.errors.lastNameReq || props.errors.isLastNameValid}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-middle-name"
                                        label={TextConst.MIDDLE_NAME}
                                        className="inline-lable-ttl"
                                        value={props.values.middleName}
                                        disabled={props.shouldDisable}
                                        onChange={props.handleValuesChanges('middleName')}
                                        inputProps={{ maxLength: 1 , style:{textTransform:'uppercase'} }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={props.errors.isMiddleNameValid ? ErrorConst.INVALID_USER_MIDDLE_NAME : null}
                                        error={props.errors.isMiddleNameValid}
                                    />
                                </div>
                            <NativeDropDown
                                        id="user-suffix"
                                        select
                                        label={TextConst.SUFFIX}
                                        value={props.values.suffix}
                                        onChange={props.handleValuesChanges('suffix')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={props.shouldDisable}
                                dropdownList={
                                    props.dropdowns &&
                                        Object.keys(props.dropdowns).length > 0 &&
                                        props.dropdowns["General#1053"] &&
                                        props.dropdowns["General#1053"].length > 0 &&
                                        props.dropdowns["General#1053"].map((each) => (
                                            <option selected key={each.code} value={each.code}>
                                            {each.description}
                                            </option>
                                        ))}
                            />
                                <div className="mui-custom-form input-md ">
                                    <TextField
                                        id="user-phone"
                                        label={TextConst.PHONE}
                                        className="inline-lable-ttl"
                                        required
                                        value={props.values.phone}
                                        disabled={props.shouldDisable}
                                        onChange={props.handleValuesChanges('phone')}
                                        inputProps={{ maxLength: 10 }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={props.errors.phoneNumberReq ? ErrorConst.PHONE_REQ : props.errors.phoneInvErr ? ErrorConst.INV_CONTENT : null}
                                        error={props.errors.phoneNumberReq || props.errors.phoneInvErr}
                                    />
                                </div>
                                <div className="mui-custom-form input-md ">
                                <TextField
                                    id="user-email"
                                    label={TextConst.EMAIL}
                                    className="inline-lable-ttl"
                                    required
                                    value={props.values.email}
                                    onChange={props.handleValuesChanges('email')}
                                    inputProps={{ maxLength: 99 , style:{textTransform:'uppercase'}  }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={props.shouldDisable}
                                    helperText={props.errors.emailReq ? ErrorConst.EMAIL_REQ : props.errors.emailInvErr ? ErrorConst.INV_CONTENT : null}
                                    error={props.errors.emailReq || props.errors.emailInvErr}
                                />
                            </div>
                           {props.type != "Add"? <div className="mui-custom-form input-md ">
                                            <TextField
                                                id="user-last-login"
                                                label={TextConst.LAST_LOGIN}
                                                className="inline-lable-ttl"
                                                value={props.values.lastLogin}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </div>: <></>}
                            </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default UserDetails;
