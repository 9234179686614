import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import TableComponent from '../Table/Table';

const headCells = [
    {
        id: 'timestamp', numeric: false, disablePadding: true, label: 'Timestamp', enableHyperLink: false, width: '16%', fontSize: 12,auditRowTimestamp:true
    },
    {
        id: 'user', numeric: false, disablePadding: false, label: 'User', enableHyperLink: false, width: '20%', fontSize: 12,auditRowUser:true
    },
    {
        id: 'activity', numeric: false, disablePadding: false, label: 'Activity', enableHyperLink: false, width: '12%', fontSize: 12
    },
    {
        id: 'columnName', numeric: false, disablePadding: false, label: 'Column', enableHyperLink: false, width: '16%', fontSize: 12
    },
    {
        id: 'beforeValue', numeric: false, disablePadding: false, label: 'Before', enableHyperLink: false, width: '17%', fontSize: 12
    },
    {
        id: 'afterValue', numeric: false, disablePadding: false, label: 'After', enableHyperLink: false, width: '17%', fontSize: 12
    }
];

function AuditLogRowTableComponent(props) {

    const editRow = row => (event) => {
        
     };
     const getTableData = (data)=>{
        if (data && data.length) {
          let tData = JSON.stringify(data); 
          tData = JSON.parse(tData);     
          tData.map((each,index) => {
            let updatedUserName = each.user ? each.user.trim():"";
            let fullName = updatedUserName ? updatedUserName.toLowerCase():"";  
        let name = fullName && fullName.includes(" ")?fullName.split(" ") : fullName;
        let fName = updatedUserName.includes(" ") && name[0]?name[0]:updatedUserName;
        let lName = updatedUserName.includes(" ") && name[1]?name[1]:'';
        each.user=updatedUserName.includes(" ")?fName+":"+lName : updatedUserName;
            each.index = index;
          }); 
          return tData;           
        }else{
          return [];
        }
      }
    return (
        <TableComponent headCells={headCells} tableData={getTableData(props.auditLogData)} onTableRowClick={editRow} defaultSortColumn="timestamp" sortOrder="asc"/>
    );
}
export default AuditLogRowTableComponent;
