import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
//import { isReadOnly as isRead } from '../../SharedModules/utility/utilityFunction';
const isRead = ()=>{return 0;}
const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
export default function TransferListUpdate(props) {
  const classes = useStyles();
  const [left, setLeft] = React.useState(props.left ||[]);
  const [right, setRight] = React.useState(props.right||[]);
  const [checked, setChecked] = React.useState([]);
  const [isdisabled, setIsdisabled] = React.useState(false);
  const isReadOnly = isRead();

  React.useEffect(() => {
    if (props.reset) {
      setChecked([]);
      if (typeof props.setReset === 'function') {
        props.setReset(false);
      }
    }
  }, [props.reset]);
  React.useEffect(() => {
    let resultRight = props.right?.map(a => a._id);
    let data = props.left && !props.left?.systemFailure &&props.left?.filter(item => !resultRight.includes(item._id));
    //
    data?.length > 0 && data[0] !== '' ? setLeft(data) : setLeft([]);
    props.right?.length > 0 && props.right[0] !== ''
      ? setRight(props.right)
      : setRight([]);
  }, [props.left, props.right]);

  React.useEffect(() => {
    setIsdisabled(props.disabled);
  }, [props.disabled]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value, type) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // props.handelToggleFunction(value, type)

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  // const handleAllRight = () => {
  //   setRight(right?.concat(left));
  //   props.setFormValues({
  //     ...props.formValues,
  //     selectedList: props.formValues.selectedList.concat(left),
  //   })
  //   props.handleSelectedChanges(props.type, formValues.selectedList.filter(
  //     (value) => rightChecked.findIndex((e) => value.value == e._id) === -1
  //   ));
  //   setLeft([]);
  // };
  // const handleAllLeft = () => {
  //   setLeft(left?.concat(right));
  //   props.setFormValues({
  //     ...props.formValues,
  //     selectedList: [],
  //   })
  //   setRight([]);
  // };
  const handleCheckedRight = () => {
    let resultLeft = leftChecked.map(a => a._id);
    let data = left.filter(item => !resultLeft.includes(item._id));
    
    setRight([...new Set(right?.concat(leftChecked))]);
    setLeft(data);
    setChecked(not(checked, leftChecked));
    props.setTransferListValues(right?.concat(leftChecked));
    // if (props.handleSelectedChanges) {
    //   props.handleSelectedChanges(props.type, [...new Set(right?.concat(leftChecked))]);
    // }
    // else {
    //   props.setFormValues({
    //     ...props.formValues,
    //     selectedList: [...new Set(props?.formValues?.selectedList.concat(leftChecked))],
    //   })
    // }
    // handleListChanges(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    let resultRight = rightChecked.map(a => a._id);

    let data = right.filter(item => !resultRight.includes(item._id));
    setLeft([...new Set(left?.concat(rightChecked))]);
    setRight(data);
    setChecked(not(checked, rightChecked));
    
    props.setTransferListValues(data);
    // New _id  11th May 2021

    //     let sol = [];

    // for (var i = 0; i < rightChecked.length; i++) {
    //   sol = props.formValues.selectedList.filter((item) => {
    //     return item.description !== rightChecked[i].description;
    //   });
    // }
    //    props.setFormValues({
    //       ...props.formValues,
    //       selectedList: sol

    //     })

    // New Working _id

    // if (props.handleSelectedChanges) {
    //   props.handleSelectedChanges(props.type, [...new Set(not(right, rightChecked))]);
    // }
    // else {
    //   props.setFormValues({
    //     ...props.formValues,
    //     selectedList: [...new Set(not(props?.formValues?.selectedList, rightChecked))],
    //   })
    // }
  };

  const customList = (title, items, ValueIn, type) => (
    <Card>
      <CardHeader
        className={classes?.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items?.length && items?.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items?.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items?.length === 0 || isdisabled || isReadOnly}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} selected`}
      />
      <Divider />
      <List className={classes?.list} dense component="div" role="list">
        {items?.map((value, key) => {
          const labelId = `transfer-list-all-item-${key}-label`;

          return (
            <ListItem
              key={value.description}
              role="listitem"
              button
              onClick={handleToggle(value, type)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disabled={isdisabled || isReadOnly}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.description} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes?.root}
      style={{ 'pointer-events': isdisabled || isReadOnly ? 'none' : '' }}
    >
      <Grid item>{customList('Available', left, props.leftValue, 'left')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {/* <Button
            variant="outlined"
            size="small"
            className={classes?.button}
            onClick={handleAllRight}
            disabled={left?.length === 0 || isdisabled || isReadOnly}
            aria-label="move all right"
          >
            ≫
          </Button> */}
          <Button
            variant="outlined"
            size="small"
            className={classes?.button}
            onClick={handleCheckedRight}
            //disabled={leftChecked.length === 0 || props.includeExcludeIgnoreIndicator=='G'}
            disabled={leftChecked.length === 0 || isdisabled || isReadOnly}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes?.button}
            onClick={handleCheckedLeft}
            //disabled={rightChecked.length === 0 || props.includeExcludeIgnoreIndicator=='G'}
            disabled={rightChecked.length === 0 || isdisabled || isReadOnly}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          {/* <Button
            variant="outlined"
            size="small"
            className={classes?.button}
            onClick={handleAllLeft}
            disabled={right?.length === 0 || isdisabled || isReadOnly}
            aria-label="move all left"
          >
            ≪
          </Button> */}
        </Grid>
      </Grid>
      <Grid item>{customList('Selected', right)}</Grid>
    </Grid>
  );
}
