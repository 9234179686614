export const BREADCRUMB_PARENT = "Manage Account";
export const BREADCRUMB_CHILD1 = "Search";
export const PAGE_HEADING = "Search User";
export const TAB1_HEADING = "Name";
export const TAB2_HEADING = "User ID";
export const PRINT = "Print";
export const HELP = "Help";
export const ADD_USER_BUTTON_TITLE = "Add New User";
export const ADD = "ADD";
export const LAST_NAME = "Last Name";
export const FIRST_NAME = "First Name";
export const MI = "Middle Initial";
export const EMAIL = "Email";
export const SEARCH_RESULT = "Search Results";
export const SEARCH_BUTTON_LABEL = "Search";
export const RESET_BUTTON_LABEL = "Reset";
export const USER_STATUS_PLACEHOLDER = "Please Choose User Status";
export const SELECT_WITH = "Starts With";
export const PTP_DEFAULT_VALIDATION_MESSAGE = "*At least one search criteria must be entered";

