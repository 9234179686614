/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import './Spinner.scss';

export default function spinner({ load }) {
  return (
    <div className={"hide-on-print" + (load ? " mt-2" : "")}>
      {
        load
          ?
          (<div className="loading-spinner-redirect">
            <div className="loader"></div>
          </div>)
          :
          (<div className="loading-spinner hide-on-print ">
            <div className="loader"></div>
          </div>)
      }</div>
  );
}
