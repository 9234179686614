import React from 'react';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AuditLogTableComponent from './AuditLogTableComponent';

function AuditLog(props) {

    return (
                <div className="tabs-holder">
                    <div className="tab-body-bordered collapsable-panel  my-3">
                        <div className="custom-panel">
                            <div className="panel-header"><span> Audit Details </span></div>
                            
                        </div>
                        <div className="p-3">  <AuditLogTableComponent auditLogData={props.auditLogData?props.auditLogData:[]} /></div>
                    </div>
                </div>
    );
};

export default AuditLog;