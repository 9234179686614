import React, { useState } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import * as RouteUrls from './RouteConstants.js'
import UserSearch from '../../Modules/UserSearch/Components/index'
import ManageAccountAddEdit from '../../Modules/UserSearch/Components/ManageAccount/index'
import ManageAccount from '../../Modules/MyAccount/ManageAccount'
import { ACCESS_DENIED } from '../Messages/ErrorMsgConstants_copy'
const PUBLIC_ACCESS_URLS = [RouteUrls.MY_ACCOUNT]
function DisplayErrorMessage () {
  const [display, setDisplay] = useState(false)
  setTimeout(() => {
    setDisplay(true)
  }, 500)
  return (
    display && (
      <div className='alert alert-danger custom-alert' role='alert'>
        <li>{ACCESS_DENIED}</li>
      </div>
    )
  )
}
const checkAccess = p => {
  const applist = JSON.parse(localStorage.getItem('applist'));
  const manageUserpaths = [
    RouteUrls.USER_SEARCH,
    RouteUrls.MANAGE_ACCOUNT_ADDEDIT
  ]
  if (p) {
    return (
      PUBLIC_ACCESS_URLS.some(pa => pa === p) ||
      (manageUserpaths.includes(p) &&
        (applist.includes('MANAGEACCOUNT') || applist.includes('MANAGEACCOUNTR')))
    )
  }
  return false
}

const Checkprivileges = p => {
  return new Promise(resolve => {
    setTimeout(() => {
      if (!checkAccess(p)) {
        resolve(false)
      }
      resolve(true)
    }, 1)
  })
}

const OwnRoute = ({ path, Component, ...rest }) => {
  const [privileges, setPrivileges] = React.useState(null)
  React.useEffect(() => {
    const p = async () => {
      const c =
        ((await Checkprivileges(window.location.pathname)) && {
          add: true,
          update: true,
          search: true
        }) ||
        null
      setPrivileges(c)
    }
    p()
  }, [path])
  return (
    <Route
      path={path}
      render={props =>
        (!privileges || privileges?.length <= 0 ? null : (
          <Component {...props} {...rest} />
        )) || (
          <>
            <DisplayErrorMessage />
          </>
        )
      }
    />
  )
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <OwnRoute path={RouteUrls.USER_SEARCH} Component={UserSearch} />
        <OwnRoute
          path={RouteUrls.MANAGE_ACCOUNT_ADDEDIT}
          Component={ManageAccountAddEdit}
        />
        <OwnRoute path={RouteUrls.MY_ACCOUNT} Component={ManageAccount} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
