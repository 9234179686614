import React, { useState, useEffect } from "react";
import Iframe from "./Iframe";
import * as endPointURLs from "../services/service";

function FooterLinks(props) {
  const [source, setSource] = useState();
  const [quickLinks, setQuickLinks] = useState([
    "PrivacyPolicy",
    "TermsofUse",
    "BrowserRequirements",
    "AccessibilityCompliance",
    "SiteMap"
  ]);
  useEffect(() => {
    if (props.footerLinksName) {
      if (props.footerLinksName === quickLinks[0]) {
        setSource(endPointURLs.PrivacyPolicy);
      } else if (props.footerLinksName === quickLinks[1]) {
        setSource(endPointURLs.TermsOfUse);
      } else if (props.footerLinksName === quickLinks[2]) {
        setSource(endPointURLs.BrowserReq);
      } else if (props.footerLinksName === quickLinks[3]) {
        setSource(endPointURLs.AccessibilityCompliance);
      }
       else {
        setSource(endPointURLs.PrivacyPolicy);
      }
    }
  }, [props.footerLinksName]);

  return (
    <>
      <div className="pos-relative">
        <Iframe source={source} />
      </div>
    </>
  );
}

export default FooterLinks;
