// Global messages
export const MINOR_CANCEL_TITLE = 'Changes you made may not be saved.';
export const URC_Parameter_Number_Req_Error = 'Parameter Number is required.';
export const PTP_REQ_TWO_CHAR= "A minimum of 2 characters must be entered for a starts with search."
export const URC_Description_Req_Error = 'Description is required.';
export const URC_Description_Length_Req_Error =
  'UR Parameter Description should not be more than 30 characters.';
export const URC_LOB_Req_Error = 'Line of Business is required.';
export const URC_ExeptionCode_Req_Error = 'Exception Code is required.';
export const EXCEPTION_DEPENDENCY_CODE_REQ =
  'Exception Dependency Code is required.';
export const DUPLICATE_EXCEPTION_CODE = 'Duplicate Exception Code.';
export const EXCEPTION_CODE_INVALID = 'Exception Code is invalid.';
export const ENTER_VALID_EXCEPTION = 'Enter Valid Exception Code.';
export const EXCEPTION_DEP_CODE_INVALID =
  'Exception Dependency Code is invalid.';
export const EXCEPTION_COMBINATION_EXISTS =
  'Entered combination already exists. ';
export const EXCEPTION_AND_DEP_COMBINATION_EXISTS =
  'The Exception code or Exception dep code are exists. Duplicate codes are not allowed.';
export const EXCEPTION_CODE_INV = 'Exception Code is invalid';
export const EXCEPTION_DEP_CODE_INV = 'EXCEPTION DEP CODE IS INVALID ERROR';
export const MEMBER_AGE_ONE_ROW = 'At least one Rate Detail row must exist.';
export const URC_History_Req_Error = 'History is required.';
export const SELECT_DATA = 'Select Record to be Saved';
export const SELECT_REP_TYPE = 'Type is required.';
export const MEM_FIRST_NAME = 'The member first name cannot be blank';
export const MEM_LAST_NAME = 'The member last name cannot be blank.';
export const SELECT_DATA_SUBMIT = 'Select Record to be Submitted';
export const MEMBER_ID_TYPE = 'Select Record to be Submitted';
export const INVALID_REVENUE_CODE_FOUR =
  'Invalid Revenue Code:  Revenue must be four characters, all numeric.';
export const URC_Include_Exclude_Req_Error =
  'Procedure/Revenue Range or Procedure/Revenue List Num required when Include or Exclude selected.';
export const BUSINESS_NAME_ERROR =
  'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const DATA_ELEMENT_NAME_ERROR = 'Either code or text is required.';
export const URL_LIMIT_MET_CODE_REQ_ERR =
  'Limit Met Exception Code is required. ';
export const Identifiers_Vendor_No_Duplicate =
  'Vendor Number is a duplicate of a Vendor Number already on file.';
export const IDENTIFIER_ID_INVALID =
  'Only Numeric values are accepted for Vendor master Number.';
export const IDENTIFIER_ID_INVALID_NPI =
  'NPI Number is invalid. NPI number should be 10 digit numeric only.';
export const URL_LIMIT_OVER_CODE_REQ_ERR =
  'Limit Over Exception Code is required.';
export const URL_LIMIT_EXCP_CODE_NOT_FILE_ERR = 'Exception Code not on file.';
export const URL_TIME_PERIOD_TYPE_REQ_ERR = 'Time Period is required.';
export const URL_TIME_PERIOD_QUANT_REQ_ERR =
  'Time Period Quantity is required.';
export const URL_LIMIT_AMOUNT_QUANT_REQ_ERR =
  'Limit Amount Quantity is required.';
export const URL_LIMIT_BOTH_AMOUNT_QUANT_ERR =
  'Both Time Period Quantity and Limit Amount Quantity must be greater than zero.';
export const URL_QUANTITY_ERROR = 'Data entered is in the incorrect format.';
export const URL_TIME_PERIOD_QUANTITY_REQ_ERR =
  'Both Time Period Quantity and Limit Amount Quantity must be greater than zero.';
export const URL_LIMIT_AMT_TYPE_REQ_ERR = 'Limit Amount Type is required.';
export const URL_LIMIT_AMT_QUANTITY_REQ_ERR =
  'Both Time Period Quantity and Limit Amount Quantity must be greater than zero.';
export const URC_REVENUE_FROM_CODE_ERROR =
  'Invalid Revenue Code: Revenue must be four characters, all numeric.';
export const URC_REVENUE_THRU_CODE_ERROR =
  'Invalid Revenue Code: Revenue must be four characters, all numeric.';
export const URC_REVENUE_PROCEDURE_LIST_ERROR =
  'Procedure/Revenue Range not allowed when Procedure/Revenue List Numbers are selected.';
export const MEMBER_ID_TYPE_REQ = 'Member ID Type is required';
export const RATE_REVENUE_CODE_REQ_ERR = 'Revenue Code is required.';
export const RATE_ALPHA_INVALID_ERR = 'Please enter valid code.';
export const RATE_REVENUE_CODE_FOURDIGIT_ERR =
  'Invalid Revenue Code: Revenue must be four characters, all numeric.';
export const REGION_CODE_REQ_ERR = 'Region is required.';
export const REVENUE_TYPE_REQ_ERR = 'Revenue Type is required.';
export const PRO_CODE_NOT_EXIST = 'Provider ID not on the Provider file.';
export const PROVIDER_ID_TYPE_REQUIRED = 'Provider ID Type Code is required.';
export const REVENUE_CODE_NOT_EXIST = 'Revenue Code not on file.';
export const NO_RECORDS_FOUND = 'No records found.';
export const NO_RECORDS_WITHSEARCH_ASSOCIATED_MEM =
  'Search criteria did not return any results. Please change the search criteria and try again.';
export const NO_RECORDS_WITHSEARCH =
  'No records found for the search criteria entered.';
export const MEMBER_ID_NOT_FOUND_ERR =
  'Member ID not found in the system. Please check the Member and resubmit.';
export const NO_RECORDS_REVIEW =
  'No record is found. Please review your search criteria.';
export const NO_RECORDS_PEND_STATUS = 'Pended Policy not found.';
export const ERROR_OCCURED_DURING_TRANSACTION =
  'There was an error processing the request. Please retry the transaction.';
export const DATE_RANGE_ERROR =
  'The begin date must be less than or equal to the end date.';
export const CLIA_DATE_OVERLAP_ERROR =
  'CLIA Number entered is already in the system for selected Procedure Code for the same dates. Duplicate CLIA Number information  is not allowed.';
export const INPUT_FORMAT_ERROR = 'Please enter data in correct format.';
export const Begin_Date_Error = 'Begin Date is required.';
export const Provider_network_Error =
  'When the Benefit plan Type is selected as "MCO" then only one Provider Network association is allowed.';
export const End_Date_Error = 'End Date is required.';
export const BEGIN_DATE_ERROR = 'Begin Date is required.';
export const END_DATE_ERROR = 'End Date is required.';
export const Invalid_Begin_Date_Error =
  'Begin Date that was entered is invalid.';
export const Invalid_End_Date_Error = 'End Date that was entered is invalid.';
export const INVALID_END_DATE_ERROR = 'End Date that was entered is invalid.';
export const The_Invalid_End_Date_Error =
  'The End Date that was entered is invalid.';
export const The_Invalid_Begin_Date_Error =
  'The Begin Date that was entered is invalid.';
export const Expected_Confinement_Date_INVALID =
  'Expected Confinement Date is invalid. Date should be in mm/dd/yyyy format. ';
export const Plan_Begin_Date_Requried_Error = 'Plan Begin Date is required.';
export const Plan_End_Date_Requried_Error = 'Plan End Date is required.';
export const Plan_End_Date_Error = 'Plan End date is invalid.';
export const PLAN_END_DATE_INVALID = 'Incorrect end control date format';
export const Fail_End_Date_Error =
  'Failed to save the record. Please connect Technical Support';
export const Plan_Begin_Date_Error = 'Plan Begin date is invalid.';
export const Begin_Control_Format = 'Incorrect begin control date format';
export const NON_VOID_CI_DATES_GAPS =
  'Non-voided Codes & Indicators Dates Must Cover All Days in Header Dates with No Gaps or Overlaps.';
export const SUGG_ADDRESS_MSG =
  'Addresses are checked for proper postal format. Select one of the standardized addresses for efficient delivery.';
export const labClass_out_of_range_err =
  'Lab Classification Section dates are out of range of Header Section.';
export const YEARS_DOING_BUSINESS =
  'Years Doing Business As is not equal to Integers';
export const CLIA_out_of_range_err =
  'CLIA Code Section dates are out of range of Header Section.';
export const ASC_out_of_range_err =
  'ASC Group Section dates are out of range of Header Section.';
export const medicover_out_of_range_err =
  'Medicare Coverage Section dates are out of range of Header Section.';
export const proModif_out_of_range_err =
  'Procedure Modifier Section dates are out of range of Header Section.';
export const pricing_out_of_range_err =
  'Pricing Section dates are out of range of Header Section.';
export const NO_ASSOCIATION_ALLOWED =
  'Members cannot be associated.Please add or select a Representative.';
export const AUTHORIZATION_REP_SUCCESS_MSG =
  'Following Member(s) have been successfully associated with the Authorized Representative.';
export const AUTHORIZATION_REP_SUCCESS_MSG_DIS =
  'Following Member(s) have been successfully disassociated with the Authorized Representative.';
export const Gap_Btw_Detail_Rows_Dates =
  'There cannot be gaps between date spans across the detail rows.';
export const Short_Desc_Error = 'Short Description is required.';
export const Long_Desc_Error = 'Long Description is required.';
export const Header_Dates_ReqBfr_Error =
  'Header Dates are required before the detail lines can be save.';
export const NDC_Required = 'NDC is required.';
export const Conversion_Factor_Required = 'Conversion Factor is required.';
export const CONV_FACTOR_REQD = 'Conversion Factor is required.';
export const CONV_FACTOR_INVALID = 'Conversion Factor is invalid.';
export const RETRO_INFO_WARN = 'This changes retroactive information. Are you sure you want to save?';
export const CANCEL_CONFIRM_TITLE = 'Are you sure you want to cancel?';
export const ENTER_VALID_NDC_CODE = 'Enter valid National Drug Code';
export const Conversion_Factor_Error_Invalid =
  'Conversion Factor should be in the format #####.######';
export const ma_Error_Invalid =
  'Medicare Allowable should be in the format #############.##';
export const BGDT_GT_ENDT_ERR =
  'Begin Date must be less than or equal to the End Date.';
export const Bgndt_GT_Enddt_Err =
  'The Begin Date must be less than or equal to the End Date.';
export const NCPDP_Duplicate_Err = 'NCPDP is a duplicate.';
export const Fall_In_Header_Dates_Err =
  'Begin/End Dates at the detail level must fall within the Begin and End Dates at Header Level.';
export const Fall_Header_Dates_Err =
  'Non-voided Codes & Indicators Dates Must Cover All Days in Header Dates with No Gaps or Overlaps.';
export const Fall_In_Header_Dates_Err_provider_ntwrk =
  'For each effective-dated row in a table, the begin date must be greater than or equal to the plan begin date and less than or equal to the plan end date.';
export const Fall_In_Header_End_Date_Err =
  'For each effective-dated row in a table, the end date must be less than or equal to the plan end date and greater than or equal to the plan begin date.';
export const Date_Range_Error =
  'For each effective-dated row in a table, the begin date must be less than or equal to the end date.';
export const Former_Code_Header_Dates_Err =
  "Former code's detail dates are not within the former code's header dates. ";
export const NO_FIRST_BUT_LAST_NAM =
  'Member first name is blank or not alpha numeric.';
export const NO_LAST_BUT_FIRST_NAM =
  "Member Representative's last name cannot be blank when the first name is entered.";
export const MEMBER_REP_OVERLAP =
  'Entered Member Rep span dates for the same Member ID and Rep Type overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Member Rep span.';
export const Starts_With_Contains_Error =
  'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const OneCI_Error =
  'At least one detail row is required in Codes and Indicators.';
export const Age_Year_Error =
  'The valid range for the year portion of the age is between 0 and 999.';
export const Age_Month_Error =
  'The valid range for the month portion of the age is between 0 and 11.';
export const Overlaps_Btw_Detail_Rows_Dates =
  'There cannot be overlaps between date spans across the detail rows.';
export const OVERLAPPING_ENROLLMENT =
  'Overlapping dates not allowed for Enrollment.';
export const OVERLAP_IDENTIFIERS =
  'Vendor Number span overlaps existing Vendor Number span for this provider.';
export const Overlaps_Btw_Detail_Rows_Dates_Vendor_Number =
  'Vendor Number span overlaps existing Vendor Number span for this provider.';
export const OVERLAPS_BTW_PAYER_ID =
  'Date span overlap existing span for a Payee/Payer ID.';
export const OVERLAP_NETWORK = 'Overlapping dates not allowed for Network.';
export const Non_DRG_Overlap =
  'Non-DRG rate records, overlapping begin and end dates are not allowed for the same level of care and charge mode combination.';
export const Overlaps_Btw_CLA_Code_Dates =
  'The dates cannot overlap for the same CLIA Code for a Procedure Code.';
// CMS FORM COL
export const CMS_FORM_REQ_ERR = 'CMS Form Code is required.';
export const CMS_COL_REQ_ERR = 'CMS Column Code is required.';
export const CMS_PRI_NUM_REQ_ERR = 'Priority Number is required.';

// APRDRG code add/edit
export const Relative_Weight_Error = 'Relative Weight is required.';
export const DRG_FORMAT_ERROR = 'Data entered is in the incorrect format.';
export const DRG_FORMAT_ERROR_NUMERIC =
  'The DRG Code is not numeric or more than five digits long';
export const Drg_Code_Req_Error = 'APR DRG Code is required.';
export const Majot_Diagnostic_Categories_Error =
  'Major Diagnostic Categories is required.';
export const Drg_Description_Error = 'APR DRG Description is required.';
export const Drg_Atleast_Error =
  'At least one detail row is required.Please enter at least one detail row.';
export const APRDRG_DUPLICATE_ERR =
  'The APRDRG code exists. Duplicate APRDRG codes are not allowed.';
export const PST_DUPLICATE_ERR =
  'Provider Type Specialty combination already exists.';
export const PST_VALID_TAX = 'Enter valid Taxonomy.';
export const OVERLAP_PREVIOUS_MEDICAD =
  'Overlapping dates not allowed for Previous Medicaid.';
export const Multiple_ASC_Group_For_Same_Date_Error =
  'Multiple ASC groups are not allowed for a procedure code for the same dates.';
export const Multiple_Medicare =
  'Multiple Medicare Coverage Segments are not allowed for a procedure code with same dates.';
export const Min_Age_GT_Max_Age_Error =
  'Minimum age must be less than or equal to maximum age.';
export const BDT_LT_CDT_DELETE_ERROR =
  'In order to delete this item, the begin date must be greater than or equal to the current date.';
export const DELETE_ERROR =
  'In order to delete this item, the begin date must be greater than or equal to the current date.';

export const DELETION_NOT_ALLOWED =
  'This data exists in the former procedure code table and the data element cannot be deleted.';
export const DATA_INCORRECT_FORMAT = 'Data entered is in the incorrect format.';
export const DELETE_FAIL_MSG =
  'This item cannot be deleted because it is tied to another data element.';
export const PROV_TYPE_DATE_OVERLAP_ERR =
  'Begin and end dates within the same Field Set cannot be the same or overlap between rows';
// Waiver Services Message start
export const WAIVER_CODE = 'Waiver Code is required.';
export const WAIVER_LINE_CODE = 'Waiver Line Code is required.';
export const WAIVER_NAME = 'Waiver Name is required.';
export const WAIVER_LINE_DESCRIPTION =
  'Waiver Line Code Description is required.';
export const CMS64_TOS_Code = 'CMS64 19 TOS Code is required.';
export const PRIORITY_NUMBER = 'Priority Number is required.';
export const BEGIN_PROCEDURE_CODEWS = 'Begin Procedure Code  is required.';
export const END_PROCEDURE_CODEWS = 'End Procedure Code  is required.';
export const INVALID_BEGIN_PROCEDURE_CODEWS =
  'Enter valid Begin Procedure Code.';
export const INVALID_END_PROCEDURE_CODEWS = 'Enter valid End Procedure Code.';
export const PROCEDURE_CODE_VALID = 'Enter valid Procedure Code';
// Waiver Service Message End
// Global messages

// Provider Inquiry messages
export const PROVIDER_ID_REQUIRED = 'Provider ID is required.';
export const ID_TYPE_REQUIRED = 'ID Type is required.';
export const FIRST_SEARCH_REQUIRED = 'First Search by is required.';
export const PROVIDER_NAME_REQUIRED = 'Provider Name is required.';
export const PROVIDER_TYPE_REQUIRED = 'Provider Type is required.';
export const PROVIDER_SPECIALITY_REQUIRED = 'Provider Specialty is required.';
export const NETWORK_REQUIRED = 'Network is required.';
export const TAXONOMY_REQUIRED = 'Taxonomy is required.';
export const TAXONOMY_REQUIRED_VALID =
  'Please enter a taxonomy code that is 10 characters in length.';
export const TAXONOMY_REQUIRED_INVALID = 'Taxonomy code is invalid.';
export const TAXONOMY_DUPLICATE =
  'New taxonomy must not be in existing list of taxonomy codes.';
export const CLIA_REQUIRED = 'CLIA is required.';
export const LICENSE_CERT_NUMBER_REQUIRED = 'License/Cert Number is required.';
export const duplicate_Not_Allowed = 'Duplication is not allowed';
export const CITY_REQUIRED = 'City is required.';
export const COUNTRY_REQUIRED = 'County is required.';
export const DISTRIC_OFFICE_REQUIRED = 'District Office is required.';
export const ZIP_CODE_REQUIRED = 'Zip code is required.';
export const MEMBER_ZIP_CODE_FORMAT = 'Zip Code format is 99999.';
export const MEMBER_ZIP_CODE_EXT_FORMAT = 'Zip Ext format is 99.';
export const PHONE_VALIDATION = 'Phone Format mismatch.';
export const PHONE_EXT_MISMATCH = 'Phone Ext Format mismatch';
export const SPOKEN_LANGUAGE_REQUIRED = 'Spoken language is required.';
// Provider Inquiry messages
//licensure
export const licenseNumberDuplicateErr =
  'New certification must not be in existing list of certifications';

// Provider Maintenence messages
export const PROVIDER_MAINTENENCE_FIRSTNAME = 'First Name is required.';
export const PROVIDER_MAINTENENCE_MIDDLENAME = 'Middle Name is required.';
export const PROVIDER_MAINTENENCE_SUBCONTRACTOR =
  'Subcontractor Legal Name is required.';
export const PROVIDER_MAINTENENCE_SUBCONTRACTOR_SUBOWNER =
  'Subcontractor Name is required.';
export const PROVIDER_MAINTENENCE_ADDRESS_SUBOWNER = 'Address is required.';
export const PROVIDER_MAINTENENCE_CITY_SUBOWNER = 'City is required.';
export const PROVIDER_MAINTENENCE_STATE_SUBOWNER = 'State is required.';
export const PROVIDER_MAINTENENCE_ZIP_SUBOWNER = 'Zip Code is required.';
export const PROVIDER_MAINTENENCE_ZIPINVALID_SUBOWNER =
  'Please enter a 5 digit zip code.';
export const PROVIDER_MAINTENENCE_OWNER_LAST_NAME =
  'Owner Last Name is required.';
export const PROVIDER_MAINTENENCE_OWNER_FIRST_NAME =
  'Owner First Name is required.';
export const PROVIDER_MAINTENENCE_OWNER_ADDRESS = 'Address is required.';
export const PROVIDER_MAINTENENCE_OWNER_CITY = 'City is required.';
export const PROVIDER_MAINTENENCE_OWNER_ZIP = 'Zip Code is required.';
export const PROVIDER_MAINTENENCE_OWNER_ZIP_INVALID = 'ZipCode is invalid.';
export const PROVIDER_MAINTENENCE_OWNER_SIGNIFICANT_BUSSINESS =
  'Owner Significant Bussiness Transactions from 4b are required.';
export const PROVIDER_MAINTENENCE_GRP_LAST_NAME = 'Last Name is required.';
export const PROVIDER_MAINTENENCE_GRP_FIRST_NAME = 'First Name is required.';
export const PROVIDER_MAINTENENCE_GRP_EFF_DATE = 'Effective Date is required.';
export const PROVIDER_MAINTENENCE_GRP_END_DATE = 'End Date is required.';
export const PROVIDER_MAINTENENCE_GRP_DOB = 'Date of Birth is required.';
export const PROVIDER_MAINTENENCE_GRP_ADDRESS = 'Address is required.';
export const PROVIDER_MAINTENENCE_GRP_CITY = 'City is required.';
export const PROVIDER_MAINTENENCE_GRP_ZIP = 'Zip Code is required.';
export const PROVIDER_MAINTENENCE_GRP_SSN = 'SSN is required.';
export const PROVIDER_MAINTENENCE_GRP_SSNINVALID = 'SSN is Invalid.';
export const PROVIDER_MAINTENENCE_GRP_SSN_DUPLICATE =
  'SSN already exists on the Database.';
export const PROVIDER_MAINTENENCE_GRP_FEIN_DUPLICATE =
  'FEIN already exists on the Database.';
export const PROVIDER_MAINTENENCE_GRP_IND_RELATIONSHIP =
  'Relationship is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_BUSSINESS =
  'Business Name is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_DBA =
  'Doing Business As (DBA) Name is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_FEIN = 'FEIN is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_FEIN_INVALID = 'FEIN is invalid.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_ADDRESS = 'Address is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_CITY = 'City is required.';
export const PROVIDER_MAINTENENCE_GRP_GROUP_ZIP = 'City is required.';
export const PROVIDER_MAINTENENCE_GRP_TYPE = 'Type of Ownership is required.';
export const PROVIDER_MAINTENENCE_GRP_IND =
  'Please Select an Owner Type - Individual or Group';
export const PROVIDER_MAINTENENCE_GRP_EFF_DATE_OWNER =
  'Effective Date of Ownership is required.';
export const PROVIDER_MAINTENENCE_GRP_END_DATE_OWNER =
  'End Date of Ownership is required.';
export const NameAndAddress_Phone_length =
  'Please enter a 10 digit phone number.';
export const PROV_MAIN_NUMBER_NUMERIC =
  'Phone number must be 10 digits and all numeric.';
// Controlling Interest Msg
export const PROVIDER_MAINTENENCE_CI_LAST_NAME = 'Last Name is required.';
export const PROVIDER_MAINTENENCE_CI_FIRST_NAME = 'First Name is required.';
export const PROVIDER_MAINTENENCE_CI_EFF_DATE = 'Effective Date is required.';
export const PROVIDER_MAINTENENCE_CI_END_DATE = 'End Date is required.';
export const PROVIDER_MAINTENENCE_CI_DOB = 'Date of Birth is required.';
export const PROVIDER_MAINTENENCE_CI_ADDRESS = 'Address is required.';
export const PROVIDER_MAINTENENCE_CI_CITYNAME = 'City is required.';
export const PROVIDER_MAINTENENCE_CI_STATE = 'State is required.';
export const PROVIDER_MAINTENENCE_CI_ZIP = 'Zip & Extension is required.';
export const PROVIDER_MAINTENENCE_CI_ZIP_INVALID = 'Zip Code is invalid.';
export const PROVIDER_MAINTENENCE_CI_SSN = 'SSN is required.';
export const PROVIDER_MAINTENENCE_CI_EFF_DATE_CONT =
  'Effective Date of Controlling Interest is required.';
export const PROVIDER_MAINTENENCE_CI_END_DATE_CONT =
  'End Date of Controlling Interest is required.';
// controlling interest msg
export const INVALID_FIRSTNAME = 'Authorized Reps First Name is invalid.';
export const INVALID_LASTNAME = 'Authorized Reps Last Name is invalid.';
export const INVALID_MI = 'Authorized Reps Middle Name is invalid.';
export const PROVIDER_MAINTENENCE_LASTNAME = 'Last Name is required.';
export const PROVIDER_MAINTENENCE_DOB = 'Date of Birth is required.';
export const PROVIDER_MAINTENENCE_DOB_AGE =
  "The Provider's age should be between 18 and 100.";
export const PROVIDER_MAINTENENCE_DOB_FUTURE =
  "The Provider's date of birth must not be greater than the Application date.";
export const PROVIDER_MAINTENENCE_STATE =
  'Please identify state field is required.';
export const PROVIDER_MAINTENENCE_GENDER = 'Gender is required.';
export const PROVIDER_MAINTENENCE_AREYOU =
  'Are you currently enrolled as a Medicaid provider in another State is required.';
export const PROVIDER_MAINTENENCE_HAVEYOU =
  'Have you revalidated with another state Medicaid program within the last 5 Years is required.';
export const PROVIDER_MAINTENENCE_HAVEPAID =
  'Have you paid the application fee is required.';
export const PROVIDER_MAINTENENCE_STATE_SELECTD =
  'Please enter the other Medicaid State the Provider was previously enrolled in.';
export const PROVIDER_MAINTENENCE_LEGALNAME =
  'Legal Name as it appears on W9 is required.';
export const PROVIDER_MAINTENENCE_INKIND_INDICATOR =
  'In Kind Indicator is required.';
export const PROVIDER_MAINTENENCE_PEERGROUP =
  'Peer Group required for provider type.';

export const PROVIDER_MAINTENENCE_FOR_PROFIT = 'forProfit is required.';
export const PROVIDER_MAINTENANCE_CLIA_NUMBER = 'CLIA Number is required.';
export const PROVIDER_MAINTENANCE_CLIA_NUMBER_ALPHA_NUMARIC =
  'Please enter a CLIA number with alphanumeric digits only.';
export const PROVIDER_MAINTENANCE_CLIA_BEGINDATE = 'Begin Date is required.';
export const PROVIDER_MAINTENANCE_CLIA_ENDATE = 'End Date is required.';
export const PROVIDER_MAINTENANCE_CLIA_GREATER_END_DATE =
  'The End date must be greater than the Begin date.';
export const PROVIDER_MAINTENANCE_BEGIN_DATE_INVALID =
  'Begin Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy only.';
export const PROVIDER_MAINTENANCE_END_DATE_INVALID =
  'End date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy only.';
export const PROVIDER_MAINTENANCE_CLIA_BEGIN_END_DATE_OVERLAP =
  'Overlapping dates not allowed for CLIA.';
export const PROVIDER_MAINTENANCE_CLIA_NUMBER_NOT_EXITS =
  'The entered CLIA Number does not exist in the database.';
export const PROVIDER_MAINTENANCE_CLIA_NUMBER_VALIDATE = 'Validate the CLIA#';
export const CLIA_ID_DUPLICATE =
  'CLIA ID entered is a duplicate for this provider.';
// Provider Maintenence messages

//Procedure Code messages start
export const Proc_Code_Req_Error = 'Procedure Code is required.';
export const PROC_CODE_REQ_ERR = 'Procedure Code is required.';
export const MIN_YEAR_REQUIRED = 'Min.Year/Month is required.';
export const MAX_YEAR_REQUIRED = 'Max.Year/Month is required.';
export const Proc_Code_Invalid_Error =
  'Procedure Code was not entered in the correct format. The valid formats are: A9999, 99999, 9999A or 999.';
export const PROC_CODE_INVALID_ERROR =
  'Procedure Code was not entered in the correct format. The valid formats are: A9999, 99999, 9999A or 999.';
export const MODIFIERS_ERROR =
  'Procedure Code is required when modifier(s) is/are entered as one of the search criteria.';
export const PROCEDURE_PERCENTAGE_ERROR = 'Percent Covered is required.';
export const PRC_DL_MA_REQ_ERROR = 'Medicare Allowable is required';
export const ASC_GROUP_REQUIRED = 'ASC Group is required.';
export const LAB_CODE_REQUIRED = 'Lab Code is required.';
export const AT_LEAST_ONE_LAB_CODE =
  'At least one Lab Code row is required for a Lab Classification Date Span.';
export const DUBLICATE_LAB_CODE =
  'CLIA Lab Codes entered are already in the system for selected Procedure Code for same Begin and End dates. Duplicate CLIA Lab Code information is not allowed.';
export const DUPLICATE_CLIA_CODE =
  'CLIA Number entered is already in the system for selected Procedure Code for the same dates.  Duplicate CLIA Number information is not allowed.';
export const FACTCODREQERR = 'Factor Code is required.';
export const PLOBREQERR = 'Line of Business is required.';
export const SCREENTYPE = 'Screening Type is required.';
export const YEARS = 'Years is required.';
export const MONTHS = 'Months is required.';
export const MAXALWAMTREQERR = 'Maximum Allow Amount is required.';
export const RATESRCREQERR = 'Rate Source is required.';
export const PRSNREQERR = 'Reason is required.';
export const MAXUNTSREQERR = 'Maximum Units is required.';
export const PRMULTPLRREQERR = 'Multiplier is required.';
export const PCPTREQERR = 'Provider Type is required.';
export const DATE_RANGE_IGNORE =
  'New Date Range cannot be added with ignore option.';
  export const DATE_RANGE_IGNORE_ERR =
  'New date Range cannot be selected with ignore option.';
export const SELECT_ATLEAST_ONE =
  'Please Select at least one value from available list.';
export const Former_PC_NOT_FOUND =
  'Former Codes and/or Code modifier combination not found.';
export const Former_PC_EXIST =
  'The Former Code and/or Code modifier combination entered is already in the system. Duplicate codes are not allowed.';
export const ABORTION_GENDER_ERROR = 'For Abortion, Gender must be Female.';
export const HYSTERECTOMY_GENDER_ERROR =
  'For Hysterectomy Gender must be Female.';
export const FACTOR_CODE_OVERLAP =
  'Overlap of Factor Codes cannot exist within same LOB, Dates and Factor Code type.';
export const PROF_LOB_EXIST =
  "Procedure code's professional component cannot overlap within the same LOB and begin and end date";
export const TECH_LOB_EXIST =
  "Procedure code's technical component cannot overlap within the same LOB and begin and end date.";
export const DATA_INCORRECT_FORMAT_G8 =
  'Procedure Pricing Factor of Percentage requires an amount greater than 0.00 and less than or equal to 1.00';
export const PROC_DATALIST_DATE_OVERLAP_ERR =
  'Begin and end dates within the same Field Set cannot be the same or overlap between rows';
//Procedure Code messages end

//Diagnosis Code messages start
export const Diag_Code_Req_Error = 'Diagnosis Code is required.';
export const Icd_Invalid_Diag =
  'ICD9 Diagnosis Code was not entered in the correct format. The valid formats are: 999, 999.9, 999.99, V99, V99.9, V99.99, E999 , E999.9 ';

  export const DIAGNOSIS_CODE_FORMAT_EROOR_ICD_10 =
  "ICD10 Diagnosis Code was not entered in the correct format. The valid formats are: Axx, Axx.x, Axx.xx, Axx.xxx, Axx.xxxx.";
export const Icd_Ver_Req_Error = 'ICD Version is required.';
//Diagnosis Code messages end
//CLAIM ENTRY -> VISIT Start
export const D_And_Q_Code_Req_Error =
  'Diagnosis codes and Diagnosis Qualifier codes are required.';
export const D_Req_When_Q_Enter_Error =
  'Diagnosis codes are required when Diagnosis Qualifier Codes are entered.';
export const Q_Req_When_D_Enter_Error =
  'Diagnosis Qualifier codes are required when Diagnosis Codes are entered.';
//CLAIM ENTRY -> VISIT End
// CLAIM INQUIRY MESSAGES START
export const claim_inquiry_error =
  'TCN OR Batch Number, Julian Date and Media Source is required.';
export const TCN_Error = 'TCN is required.';
export const TCN_NUM_Error = 'TCN is invalid. Format must be 17 digit.';
export const CORRECTION_TCN_NUM_Error =
  'TCN is invalid. Format must be 17 digit.';
export const DATE_INVALID = 'Date is Invalid.';
export const BEGIN_DATE_INVALID = 'Begin Date is Invalid.';
export const END_DATE_INVALID = 'End Date is Invalid.';
export const BEGINDOSERR = 'Begin Date of Service is required.';
export const ENDDOSERR = 'End Date of Service is required.';
export const BEGINADJERR = 'Adjudication Begin Date is required.';
export const ENDADJERR = 'Adjudication End Date is required.';
export const TCN_Batch_NUM_Error =
  'Please enter a TCN or Batch Data information, but not both.';
export const Batch_No_Error = 'Batch Number is required.';
export const MEMBER_ID_INV_Error =
  'Member Id is Invalid. Format must be 10 digits.';
export const Batch_No_INV_Error =
  'Batch Number is invalid. Format must be 4 digit numeric.';
export const mass_batch_no_Error =
  'Batch Number must be between 9000 and 9999 inclusive.';
export const JULIAN_DATE_INV_Error = 'Julian Date is invalid.';
export const Julian_Date_Error = 'Julian Date is required.';
export const Media_Source_Error = 'Media Source is required.';
export const PROVIDER_ROLE_Error = 'Prov Role is Required.';
export const PROVIDER_ID_TYPE_Error = 'Prov ID Type is Required.';
export const PROVIDER_ID_Error = 'Provider ID is required.';
export const External_TCN_Error = 'External TCN is required.';
export const MEMBER_ID_Error = 'Member ID is required.';
export const PROVIDER_DATA_Error =
  'Provider Data and/or Member Data is required.';
export const CORRECTION_ADV_SRCH_ERR =
  'Provider Data, Member Data and/or Claim Type is required.';
export const DATE_ERROR_INV =
  'Date of Service Begin Date cannot be greater than Current Date.';
export const DATE_LESSTHAN_ERROR_INV =
  'Date of Service Begin Date must be less than or equal to Date of Service End Date.';
export const Value_Field_Error1 =
  'Value is required for Category of Eligibility in List.';
export const Value_Field_Error =
  'Value is required for Nursing Facility Span Type in List';
export const FUNCTIONAL_AREA_ERROR =
  ' Functional Area is required for Category of Eligibility in List.';
export const FUNCTIONAL_AREA_ERROR1 =
  ' Functional Area is required for Nursing Facility Span Type in List';
export const Network_ID_Error = 'Network ID is required.';
export const Network_Status_Error = 'Network Status is required.';
export const Rate_Type_Code_Error = 'Rate Type Code is required.';
export const Rank_Error = 'Rank is required.';
export const Rate_Error = 'Rate is required.';
export const Invalid_Rate_Error =
  'Incorrect rate (should be a dollar amount with two decimals).';
export const Invalid_Rank_Error = 'Rank that was entered is invalid.';
export const Map_Id_Error = 'Map ID is required.';
export const Map_Id_Invalid = 'Map ID is invalid.';
// CLAIM INQUIRY MESSAGES END
export const Benifit_Hie_Error =
  'For a given benefit plan hierarchy, the begin date must be less than or equal to the end date.';
export const BENEFIT_PLAN_ERROR = 'Failed To Update the record';
export const BENEFIT_PLAN_SUCCESS_ERROR =
  'System successfully saved the Information.';
export const BENEFIT_PLAN_DELETE_ERROR = 'Ranking Details Deleted Successfully';
export const BENEFIT_PLAN_ENDDATE_ERROR =
  'For a given benefit plan hierarchy, the begin date must be less than or equal to the end date.';
export const BENEFIT_PLAN_RANK_ERROR =
  'The rank field should be unique for each active (non-voided) row in an effective dated benefit plan hierarchy.';
export const BENEFIT_PLAN_BPID_ERROR =
  'BP ID within a hierarchy should be unique.';
export const BENEFIT_PLAN_SAVE_ERROR = 'Error saving in BP Hierarchy.';
export const BENEFIT_PLAN_UPDATE_ERROR = 'Error Saving BP Hierarchy details.';
export const BENEFIT_PLAN_BEGIN_DATE_ERROR = 'Incorrect begin date format.';
export const BENEFIT_PLAN_HIERARCHY_BEGIN_DATE_ERROR = 'Incorrect begin date.';
export const BENEFIT_PLAN_HIERARCHY_END_DATE_ERROR = 'Incorrect end date.';
export const BENEFIT_PLAN_END_DATE_ERROR = 'Incorrect end date format.';
export const BENEFIT_PLAN_RANKING_ZERO_ERROR = 'Rank Should be Numeric.';
export const BENEFIT_PLAN_RANKING_ZERO =
  'The rank field must be a numeric value greater than zero.';
export const BENEFIT_PLAN_DEFAULT_WARNING =
  'A change to the benefit plan hierarchy ranking has occurred that potentially impacts claims that have already been processed and a mass claims adjustment may be required.';
export const BENEFIT_PLAN_DATE_OVERLAP_ERROR =
  'Begin and End dates should not overlap for Benefit Plan Hierarchy when there is more than one active hierarchy available in the system. However the user can void a hierarchy and add another hierarchy with the same begin and end date';
export const BENEFIT_PLAN_DATE_OVERLAP_ERROR_PAST =
  'For a benefit plan hierarchy, the user can only change the end date when the current end date is greater than System Date / System Cycle date (i.e. future dated).';
//Add Benifit Plan
export const LOB_ID_Error = 'LOB is required.';
export const Benefit_Plan_Error = 'Benefit Plan is required.';
export const PAYMENT_CAT_REQ_ERR = 'Payment Category is required.';
export const Benefit_Plan_ID_Error = 'Benefit Plan ID is required.';
export const Benefit_Plan_Desc_Error = 'Benefit Plan Description is required.';
export const Benefit_Plan_Type_Error = 'Benefit Plan Type is required.';
export const Benefit_Plan_Option_Error = 'Benefit Plan Option is required.';
export const Benefit_Plan_Status_Error = 'Benefit Plan Status is required.';
export const Retro_Procedure_Error = 'Retro Procedure Code is required.';
export const Current_Procedure_Error = 'Current Procedure Code is required.';
export const CAPITATION_MAP_DATE_ERR =
  'The date spans for valid map definition data rows for this map definition do not span or fall within the plan begin and end dates.';
export const NWTACC_REQ_Error =
  'At least one detail row is required in Network Association.';
export const ONE_RECORD_EXIST_CO_PAY =
  'Atleast One Record Should Exist For Co-Pay';
export const RANK_TABLE_OVERLAP =
  'There cannot be duplicate ranked rows on any benefit plan component tables.';
export const NETWORK_TABLE_OVERLAP =
  'Network ID and Begin Date MUST NOT BE IN LIST of existing Network ID and Begin Date.';
export const CAPITATION_OVERLAP =
  'Same Combination of Map ID,Begin Date and Network Status MUST NOT BE IN LIST of existing Combination of Map ID,Begin Date and Network Status.';
export const NETWORK_MAP_OVERLAP =
  'Same Combination of Network Status,Map ID and Begin Date MUST NOT BE IN LIST of existing combination of Network Status,Map ID and Begin Date.';
export const PLAN_LIMIT_NETWORK_MAP_OVERLAP =
  'Same Combination of Network Status,Map ID,Begin Date and LimitTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and LimitTypeCode.';
export const COPAY_OVERLAP_ERROR =
  'Same Combination of Network Status, Service Type Code, Provider Type Code, Minimum Age, Begin Date and Co-pay Type Code MUST NOT BE IN LIST of existing Combination of Network Status, Service Type Code, Provider Type Code, Minimum Age, Begin Date and Co-pay Type Code.';
export const COPAY_TYPE_CODE_OVERLAP_ERROR =
  'Same Combination of Network Status,Map ID,Begin Date and Co-PayTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and Co-PayTypeCode.';
export const COINSURANCE_OVERLAP_ERROR =
  'Same Combination of Network Status,Map ID,Begin Date and Co-InsuranceTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and Co-InsuranceTypeCode.';
export const DEDUCATIBLE_OVERLAP_ERROR =
  'Same Combination of Network Status,Map ID,Begin Date and DeductibleTypeCode MUST NOT BE IN LIST of existing Combination of Network Status,Map ID,Begin Date and DeductibleTypeCode.';
export const Sa_Rule_Error = 'SA Rule Code is required.';
export const LIMIT_TYPE_CODE = 'Limit Type Code is required.';
export const TYPE_CODE = 'Co-pay Type Code is required.';
export const INDIVIDUAL_LIMIT = 'Individual Limit is required.';
export const LIMIT_MET_EXC_CODE = 'Limit Met Exc code is required.';
export const LIMIT_MET_OVER_EXC_CODE = 'Limit Over Exc Code is required.';
export const PERCENTAGE_APPLIED_TO_OUT_OF_POCKET_EMPTY =
  'Percent Applied to OOP Max is required.';
export const PERCENTAGE_APPLIED_TO_OUT_OF_POCKET =
  'Incorrect percentage applied to OOP max (e.g. one hundred dollars = 100.00).';
export const PERCENTAGE_ERROR =
  'Incorrect percentage (e.g. one hundred percent = 100.00).';
export const COPAY_AMT_ERROR =
  'Incorrect copay amount format (e.g. one hundred dollars = 100.00)';
export const MIN_AGE = 'Min Age is required.';
export const MIN_YEAR_REQ = 'Min. Year is required.';
export const MAX_YEAR_REQ = 'Max. Year is required.';
export const ShowLimitMetExcErr = 'Limit Met Exc code is required.';
export const CoInsTypeCode = 'Co-Insurance Type Code is required.';
export const AmountError = 'Either Amount or Percent is required.';
export const PercentAppliedError =
  'Percent Applied to Out of Pocket Max is Required';
export const TypeCodeInsError = 'Limit Type Code is required.';
export const Invalid_Ind_Amount =
  'Individual Limit that was entered is invalid';
export const Invalid_Ind_Pls_Amount =
  'Individual + Limit that was entered is invalid';
export const Invalid_Fam_Amount = 'Family Limit that was entered is invalid';
export const INVALID_IND_LIMIT =
  'Incorrect individual limit (e.g. one hundred dollars = 100.00)';
export const INVALID_IND_PLUS_LIMIT =
  'Incorrect individual+1 limit (e.g. one hundred dollars=100.00)';
export const INVALID_IND_FAM_LIMIT =
  'Incorrect family limit format (e.g. one hundred dollars = 100.00)';
export const IND_LIMIT_MAX =
  'Individual Limit cannot be greater than 99999999999.99';
export const IND_PLUS_LIMIT_MAX =
  'Individual +1 Limit cannot be greater than 99999999999.99';
export const IND_FAM_LIMIT_MAX =
  'Family Limit cannot be greater than 99999999999.99';
export const INVALID_LMT_OVR_EXC_CODE = 'Limit Over Exception Code is invalid.';
export const INVALID_EXC_CODE = 'Exception Code is invalid.';
export const DEDUCTABLE_INVALID_EXC_CODE =
  'Deductible Met Exception Code is invalid.';
export const BENIFIT_INVALID_LMT_OVR_EXC_CODE =
  'Limit Over Exception Code must not contain special characters.';
export const INVALID_SPL_EXC_CODE =
  'Exception Code must not contain special characters.';
export const INVALID_LMT_MET_EXC_CODE = 'Limit Met Exception Code is invalid.';
export const INVALID_LMT_MET_EXC_CODE_SPE =
  'Limit Met Exception Code must not contain special characters.';
export const INVALID_EXCEPTION_CODE =
  'The Benefit Plan Exception Code does NOT match with a LOB Exception Code.';
export const EXC_CODE_NOT_MATCH_LOB =
  'The Benefit Plan Exception Code does NOT match with a LOB Exception Code.';
export const BENEFIT_PLAN_ID_SPCL_CHAR_ERR =
  'Benefit Plan ID must not contain special characters';
export const BENEFIT_BUSS_UNIT_SPCL_CHAR_ERR =
  'Business Unit must not contain special characters';
export const BENEFIT_PLAN_DESC_SPCL_CHAR_ERR =
  'Benefit Plan Description must not contain special characters';
export const INVALID_AGE =
  'Minimum Age should be a positive whole number between 0-999. Decimal values are not allowed.';
//success message//
export const SUCCESSFULLY_SAVED_INFORMATION =
  'System successfully saved the information.';
export const SUCCESSFULLY_SAVED_INFORMATION_VOID =
  'A Claims Adjustment Request has been created for the span that was just voided.';
export const SUCCESSFULLY_ADDED_INFORMATION =
  'System successfully added the information.';
export const SUCCESSFULLY_UPDATED_INFORMATION =
  'System successfully updated the information.';
export const MSG_CRT_OLD =
  'In order to delete this item, the begin date must be greater than or equal  to the current date.';

export const BENIFIT_PLAN_DELETE_SUCCESS =
  'System successfully deleted the information.';
export const DELETE_SUCCESS = 'System successfully deleted the information.';
export const DELETE_CONFIRM = 'Are you sure that you want to delete?';
export const INVALID_AGE_NAN = 'Min age must be a positive whole number.';
export const ENTER_ONE_SEARCH = 'Please enter at least one search parameter.';
export const CONFIRMATION_ID_ERROR =
  'Please enter a Confirmation ID or other search criteria, but not both.';
  export const SERVICE_TYPE_CODE =
  'Service Type Code is required.';

export const MEMBER_ID_AND_TYPE_ERROR =
  'Member ID and Member ID Type must both be entered for valid search.';
export const PROVIDER_ID_AND_TYPE_ERROR =
  'Provider ID and Provider ID Type must both be entered for valid search.';
export const GUARANTEE_DATE_INVALID = 'Date must be a valid date format.';
export const GUARANTEE_DATE_GREATER =
  'Guarantee from date, if entered, must be less than or equal to today.';
export const GUARANTEE_DATE_ERROR =
  'Please enter a Member ID/ID Type or Provider ID/ID Type in addition to the guarantee From Date.';
export const PI_REQ_Error = 'Policyholder ID is required.';
export const MR_NO_ADDR =
  'For a non-voided Member Representative, there must be at least one non-voided Address span.';
//Service Authorization Error
export const SA_ID_Error = 'The SA ID is required.';
export const SA_PROVIDER_ID_Error = 'Provider ID is required.';
export const SA_INVALID =
  'SA Number is invalid. Format must be a 5 digit numeric.';
export const SA_MEMBER_ID_Error = 'Member ID is required.';
export const SA_AUTH_TYPE = 'Auth Type is required.';

//SA ADD STARTS
export const SA_MEDIA_TYPE_ERROR = 'SA Media Type is required.';
export const SA_RECEIVED_DATE_ERROR = 'Received Date is required.';
export const SA_AUTH_TYPE_ERROR = 'Auth Type is required.';
export const EXTERNAL_SA_ID_ERROR = 'External SA ID is a required field.';
export const HEADER_STATUS_REASON_ERROR =
  'When changing the Header Status on a Service Authorization to a value other than Approved, the Reason for Status Change  must be populated by the user.';
export const SA_REQUESTED_BEGIN_DATE_ERROR =
  'Requested Begin Date is required.';
export const ID_TYPE_INVALID_ERR = 'NCPDP ID Number is invalid';
export const SA_REQUESTED_END_DATE_ERROR = 'Requested End Date is required.';
export const SA_INPUT_SOURCE_ERROR = 'SA Input Source is required.';
export const MEMBER_ID_ERROR = 'Member ID is required.';
export const TYPE_OF_ATTACHMENT_REQ = 'Type Of Attachment is required.';
export const DELIVERY_METHOD_REQ = 'Delivery Method is required.';
export const MEMBER_ID_NOT_FOUND_Error =
  'Member ID not found for the Member ID Type selected on the database.';
export const SA_HEADER_STATUS = 'Header Status is required.';
export const SA_PROVIDER_ERROR = 'Provider is required.';
export const SA_EXT_ERROR =
  'Contact Phone Ext cannot be entered without a Phone Number';
export const EXT_NOPHN_ERROR =
  'A phone extension number has been entered without a corresponding phone number.';
export const ZIP_LEN_ERR = 'Residential Zip code is less than five digits.';
export const ZIP_PLUS_LEN_ERR =
  'Residential Zip Plus code is less than four digits.';
export const INVALID_ZIP1 = 'Invalid Zip1.';
export const INVALID_ZIP2 = 'Invalid Zip2.';
export const PHONE_OVERLAP_ERR =
  'Entered Phone span dates for the same Phone Type and Phone Significance overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Phone span.';
export const SA_MAIL_FAX_ERROR =
  'When Provider Phone Ext is present, include only the Fax or Email but not both';
export const TAXONOMY_NOT_ALLOWED_SV =
  'A Servicing Provider Taxonomy Code is Not Allowed with the Entity Code Selected';
export const TAXONOMY_NOT_ALLOWED_EV =
  'An Event Provider Taxonomy Code is Not Allowed with the Entity Code Selected';
export const SERVICE_CODE_ERR = 'Service Code is a required field.';
export const SERVICE_CODE_FROM_ERR = 'Service Code From is a required field.';
export const REQUNITS_REQAMOUNT_ERR =
  'Please enter either requested unit or requested amount, but not both.';
export const SERVICE_CODE_TO_ERR = 'Service Code To is a required field.';
export const LIST_ERR = 'List is a required field.';
//SA SADD ENDS
export const SA_CODE_REQUIRED =
  'If either the Service Type Code or the Service Code are entered, then both are required.';
export const SA_BEGIN_DATE = 'Begin Date is required.';
export const SA_END_DATE = 'End Date is required.';
export const SA_SERVICE_AUTH_TYPE_Error = 'Auth Type is required.';
export const SA_USER_ID_ERROR = 'User ID is required.';
export const SA_USER_TYPE_ERROR = 'User Type is required.';
export const SA_DATE_TYPE = 'Date Type is required.';
export const SA_LOCATION_ERROR = 'Location is required.';
export const SA_PROVIDER_TYPE = 'Provider Type is required.';
export const SA_NO_RECORD_FOUND =
  'No records were found for the search criteria that was entered.';
export const SA_GREATER_DATE = 'End Date should be greater than Begin Date.';
export const SA_PROIDER_ERROR =
  'Provider Type, Provider Id Type Code and Provider Id are all required if any are entered.';
export const SA_Invalid_Begin_Date_Error =
  'Begin Date is invalid.It should be in the format MM/DD/CCYY.';
export const SA_Invalid_End_Date_Error =
  'End Date is invalid.It should be in the format MM/DD/CCYY.';
export const SA_Invalid_Begin_Error_Min =
  'Please enter the data in correct format.';
export const SA_Invalid_End_Date_Error_Min =
  'Please enter the data in correct format.';

export const BP_BD_ERR = 'Please enter the data in correct format.';
export const BP_ED_ERR = 'Please enter the data in correct format.';

export const Benefit_Plan_Amount_Error = 'Benefit plan amount is required.';

//Batch Control Error
export const BATCH_DATE_ERROR = 'Batch Date is required.';
export const BATCH_NUMBER_ERROR =
  'Batch Number is invalid. Format must be 4 digit numeric.';
export const BATCH_NUMBER_REQ = 'Batch Number is a required.';
export const TCN_NUMBER_ERROR = 'TCN is invalid.Format must be 17 digit.';
export const BATCH_DATE_FORMAT_ERROR = 'Batch Date is invalid.';

export const BATCH_DATE_REQ = 'Batch Date is required.';
export const DATCH_DATE_INVALID = 'Batch Date is invalid.';
export const JULIAN_DATE_INVALID =
  'The Julian Date is invalid. The Julian Date cannot be greater than todays date (YYDDD).';
export const JULIAN_DATE_INVALID_ABOVE_14 =
  'Batch Date cannot be more than 14 days prior to the current date.';
export const SEQUENCE_NUMBER =
  'The sequence number of the end document (End Document Number) must be greater than or equal to sequence number of the begin document (Begin Document number).';
export const BATCH_NUM_BWT_8_9 =
  'The Batch Number is invalid. The system reserved number sequence for a new batch is 8000 through 9999.';
export const BATCH_NUM_BWT_9_0 =
  'The Batch Number is invalid. The system reserved number sequence for a new batch is 9000 through 9999.';

export const BATCH_NUM_BET_0_7 =
  'The Batch Number is invalid. The system reserved number sequence for a new batch is 0001 through 7999.';
export const MEDIA_SRC_PAPER =
  'The Media Source Code must be "8-Paper" for a new batch.';
export const TOTAL_DOC_REQ = 'Total Documents is required.';
export const BEGIN_DOC_REQ = 'Begin Document is required.';
export const TOTAL_DOC_INVALID = 'Total Documents is invalid.';
export const BEGIN_DOC_INVALID = 'Begin Document is invalid.';
export const BEGIN_DOC_GRT_ZERO =
  'The Begin Document Number must be greater than zero.';
export const END_DOC_GRT_ZERO =
  'The End Document Number must be greater than zero.';
export const TOTAL_DOC_GRT_ZERO =
  'The Total Documents Number must be greater than zero.';
export const BEGIN_DOC_LESS_MAX =
  'The Begin Document Number must be less than 999,999.';
export const END_DOC_REQ = 'End Document is required.';
export const END_DOC_INVALID = 'End Document is invalid.';
export const TOTAL_DOC_GRT =
  'Total Documents should equal the End Document number minus the Begin Document number.';
export const TOTAL_DOC_LES =
  'The number of claims entered exceeds Total Documents.';
export const DOC_TYPE_REQ = 'Document Type is required.';
export const BATCH_TYPE_REQ = 'Batch Type is required.';
export const BATCH_STATUS_REQ = 'Batch Status is required.';
export const PAYMENT_TYPE_REQ = 'Payment Type is required.';
export const BATCH_TYPE_RELEVENT_ERR =
  'The Batch Type must be Replac Req or Credit for Adjustment batches.';
export const DOC_TYPE_RELEVENT_ERR =
  'The Document Type must be Adjustment or Encounter Adjustment for Replac Req or Credit batches.';
export const BATCH_CONTROL_DUPLICATE =
  'The Batch Date, Media Source and/or Batch Number combination must not exist to add a new batch control record.';
export const BATCH_CONTROL_DELETE_EXCEPTION =
  'This batch control record has claims associated and cannot be deleted.';
export const DOC_TYPE_ENC_MAT =
  'Encounter Document Type must be combined with History Only Payment Type.';
export const MAX_OOP_REQ = 'Percent Applied to OOP Max is Required';
export const MAX_OPP_INVALID = 'Percent Applied to OOP Max Invalid';
export const DEDUCT_TYPE_CODE_ERR = 'Deductible Type Code is required.';
export const BATCH_DATE_REQUIRED_ERROR = 'Batch Date is required.';
export const BATCH_NUMBER_REQUIRED_ERROR = 'Batch Number is required.';
//Claims Entry Messages Start
export const BATCH_DATE_INVALID = 'Batch Date is invalid.';

export const MASS_BATCH_DATE_INVALID =
  'Please enter the date in the correct format.';
export const BATCH_DATE_REQUIRED = 'Batch Date is required.';
export const BATCH_DATE_GT_CDT = "Batch Date must be less than Today's date.";
export const BATCH_DATE_GT_CURDT =
  'Batch Date must be less than or equal to Current Date.';
export const BATCH_DATE_OLD =
  'Batch Date can only go back 10 days in the past.';
export const PROV_ROLE_INVALID = 'Prov Role is Invalid.';
export const PROV_ID_INVALID = 'Prov ID Type is Invalid.';
export const PROV_ROLE_REQ = 'Prov Role is Required.';
export const PROV_ID_TYPE_REQ = 'Prov ID Type is Required.';
export const OVR_EXC_REQ = 'Override Exception is required.';
export const MAN_EOB_REQ = 'EOB Code is required.';
export const OVR_PAY_TYPE_REQ = 'Pay is required.';
export const CE_DOC_NUM_INVALID = 'Document Number is invalid.';
export const CC_OVR_LOC_REQ = 'Override Loc is required.';
export const CC_OVR_USR_REQ = 'User ID is required.';
//Claims Entry Messages End
// CLAIM CORRECTION MESSAGES START
export const LOCATION_CODE_ERROR = 'Location Code is required.';
// CLAIM CORRECTION MESSAGES END

export const ATTACHMENT_REQ = 'Attachment is required.';
export const REMARK_REQ = 'Remark is required.';
export const ADJ_REASON_REQ = 'Adj Reason is required.';
// Claims Advanced Tab Messages Start
export const MAX_ALLOW_AMT_ERR =
  'Amount cannot be greater than 99,999,999,999.99.';
export const Multiplier_err_frmt = 'Data entered is in the incorrect format.';
export const Amt_Float_Error = 'Amount entered must be a float.';
export const Amt_Incorrect_Format_Error =
  'Amount entered is in the incorrect format.';
export const Multp_Incorrect_Format_Error =
  'Data entered is in the incorrect format.';
export const Mult_Invalid_Entry =
  'Received non-numeric data in a numeric field.';
// Claims Advanced Tab Messages End

export const INVALID_BEGAIN_DATE =
  'Received non-numeric data in a numeric field.';

// CLAIM CORRECTION OTHER PAYER START
export const LINE_ITEM_ERROR = 'LI # is required.';
export const BILLED_ERROR = 'Billed Amt is required.';
export const ALLOWED_ERROR = 'Allowed Amt is required.';
export const DEDUCATIBLE_ERROR = 'Deductible Amt is required.';
export const COINSURANCE_ERROR = 'Coinsurance Amt is required.';
export const PAID_ERROR = 'Paid Amt is required.';
export const CO_PAY_ERROR = 'CoPay Amt is required.';
// CLAIM CORRECTION OTHER PAYER END

export const LINE_ITEM_SVC_DATE_INVALID = 'Line Item Service Date is invalid.';
export const LINE_ITEM_SVC_UNITS_INVALID = 'Line Item Service Unit is invalid.';
export const LINE_ITEM_TOTAL_CHARGE_INVALID =
  'Line Item Total Charge is invalid.';
export const LINE_ITEM_BASE_RATE_INVALID = 'Line Item Base Rate is invalid.';
export const LI_IS_REQ = 'Li# is required.';
export const LINE_REQ = 'Line # is required.';
export const LI_IS_INV = 'Li# is invalid.';
export const LINE_INV = 'Line# is invalid.';

//Claim Inquiry
//Claim Inquiry
export const DOS_Begin_Date_Error = 'DOS Begin is required.';
export const DOS_End_Date_Error = 'DOS End is required.';
export const DOS_Invalid_Begin_Date_Error = 'DOS Begin is invalid.';
export const DOS_Invalid_End_Date_Error = 'DOS End is invalid.';
export const PAID_Invalid_End_Date_Error = 'Paid Date End is invalid.';
export const PAID_Invalid_Begin_Date_Error = 'Paid Date Begin is invalid.';
export const ADJ_Invalid_Begin_Date_Error =
  'Adjudication Date Begin is invalid.';
export const ADJ_Invalid_End_Date_Error = 'Adjudication Date End is invalid.';
export const CLAIM_ENTRY_VALIDATE = 'Claim Validated Successfully.';
export const CLAIM_ENTRY_NO_DATA =
  'Data must be entered on claim form to successfully save. Please enter claim data.';
export const SERVICE_DATE_FROM = 'Date of Service From is required. ';
export const SERVICE_DATE_TO = 'Date of Service To is required. ';

// EVV
export const BGN_ERROR = 'Procedure Effective Begin Date is invalid.';
export const END_ERROR = 'Procedure Effective End Date is invalid.';
export const EVVB_ERROR =
  'Effective Date for EVV Claim Denial for No Matching Visit is invalid.';
export const EVVE_ERROR =
  'End Date for EVV Claim Denial for No Matching Visit is invalid.';
export const BGND1_ERROR = 'Begin date cannot be greater than the end date';
export const BGND2_ERROR = 'Begin date cannot be greater than the end date.';
export const PRC_CD_ERROR = 'Enter valid Procedure Code.';
export const RVN_CD_ERROR = 'Enter valid Revenue Code.';
export const PRC_INV_ERROR = 'Procedure Code is Required.';
export const RVN_INV_ERROR = 'Revenue Code is Required.';
//MEMBER SEARCH
export const SSN_Error = 'SSN is required.';
export const SSN_INV_Error =
  'Please enter SSN in the correct format. Example 123-45-6789 Example 123456789';
export const SSN_ERROR_INVALID =
  'Please enter SSN in the correct format. Example 123-45-6789 Example 123456789';
export const BILLED_DATE_ERR = 'Payment & Provider Sign / Date is Invalid.';
export const MEMBER_DATE_ERR = 'Member Date of Birth is invalid.';
export const LAST_NAME_Error = 'Last Name is required.';
export const LAST_NAME_ENV_Error = 'Last name must be 2 or more characters';
export const FIRST_NAME_ENV_Error = 'First name must be 2 or more characters';
export const FIRST_NAME_Invalid_char_Error =
  'First name must be 2 or more characters';
export const ID_NUMBER_Error = 'ID Number is required.';
export const ENTER_SEARCH_CRITERIA =
  'Please enter search criteria before clicking the Search button.';
export const MEMBER_MI_ERROR = 'Member middle initial invalid.';
export const MEMBER_NAME_INVALID =
  'Warning-Member last name contains invalid characters. System will continue processing the name as entered.';
export const MEMBER_ZIP_ERROR = 'Zip Code should contain only numbers.';
export const ZIPCODE_ERROR = 'Invalid Zip Code.';
export const ID_TYPE_Error = 'ID Type is required.';
export const VISIT_ILLNESS_ERROR = 'Accident/Illness Date is Invalid.';
export const REQ_ILLNESS_ERROR = 'Accident/Illness Date Required';
export const PAYMENT_DOB_ERR = 'Other Insured Date of Birth is Invalid.';
export const SUBMITTED_DUPL_ROWS = 'Duplicate Rows cannot be added.';
// member constants
export const PROVIDER_NOT_FOUND =
  'The combination of Provider ID and Provider Type for the PCP Provider entered cannot be found.';
export const CLOSURE_REASON_ERR =
  'Closure Reason is required for an ended span.';
export const PROVIDER_NOT_PCP =
  'The provider type of the Provider ID entered cannot be a Primary Care Provider.';
export const PCP_ALREADY_EXISTS =
  'PCP Span record already exists for given BeginDate,LOB Code and BenefitPLan.';
export const PROV_NOT_ACTIVE_IN_TIME =
  'Selected PCP Provider is not active for time span entered.';
export const PCP_BDT_BEFORE_MDOB =
  "Begin date cannot be earlier than Member's Date of Birth Month and Year";
export const CANCEL_CUR_ACTION =
  'Please click on Save or Cancel before performing another action.';
export const ASSIGN_REASON = 'Assign Reason Code is required.';
export const MEMBER_DATE_REQ = 'Member Date of Birth is Required.';
export const Invalid_From_Date_Error =
  'From Date is invalid. Date should be in mm/dd/yyyy format.';
export const Invalid_From_Date_Error_Min =
  'Please enter the data in correct format.';
export const Invalid_To_Date_Error =
  'To Date is invalid. Date should be in mm/dd/yyyy format.';
export const Invalid_To_Date_Error_Min =
  'Please enter the data in correct format.';
export const Invalid_DOB_Error =
  'Date of Birth is invalid. Date should be in mm/dd/yyyy format.';
export const Invalid_DOB_Min_Error = 'Please enter the data in correct format.';
export const INVALID_DATE_OF_BIRTH_ERROR =
  'Date of Birth is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY.';
export const LINE_DETAIL_DOESNT_EXIST = "Line Detail Doesn't Exist";
export const fromdt_GT_Todt_Err =
  'From Date must be less than or equal to the To Date';
export const Reqd_From_Date_Error = 'From date is required';
export const Reqd_To_Date_Error = 'To date is required';
export const DUPLICATE_MODIFIER = 'Duplicate Modifiers are not allowed.';
export const LASTNAME_INVALID_Error =
  'Warning-Member last name contains invalid characters. System will continue processing the name as entered.';
export const FIRSTNAME_INVALID_Error =
  'Warning-Member first name contains invalid characters. System will continue processing the name as entered.';
export const LASTNAME_INVALID_Err = 'Member last name invalid.';
export const CASE_LAST_NAME = 'Case Head Last Name is required.';
export const CASE_FIRST_NAME =
  'Member first name is blank or not alpha numeric.';
export const CASE_NUMBER = 'Case Number is required.';
export const CASE_FROM_DATE_INVALID =
  'From Date is invalid. Date should be in mm/dd/yyyy format.';
export const CASE_TO_DATE_INVALID =
  'To Date is invalid. Date should be in mm/dd/yyyy format.';
export const CASE_NO_RECORDS =
  'Case Head Name not found in the system. Please re-enter and try again.';
export const CASE_NUMBER_NO_RECORDS =
  'Case number not found in the system. Please re-enter and try again.';
export const PAYMENT_TOTAL_CHRG_ERR =
  'Payment & Provider Total Charge is Invalid.';
export const PAYMENT_NET_AMT_ERR = 'Payment & Provider Net Amount is Invalid.';

export const CASE_NUMBER_ERROR = 'Case Number should be AlphaNumeric.';
export const MEMBER_ID_MID_ERROR =
  "Member's MID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MEMBER_ID_RID_ERROR =
  "Member's RID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const LAST_NAME_ORG_NAME_ERR =
  'Last Name or Organization Name is required.';
export const MEMBER_ID_NO_RECORD =
  'The combination of Member ID and Member ID Type cannot be found.';
export const SUCCESS_SAVE_REDIRECT_ENTRY =
  'System successfully saved the Information. Total documents have been entered for the given Batch Control record.';

// CLAIM EXCEPTION STARTS
export const DOC_TYPE_REQ_ERR = 'Document Type is required.';
export const CLAIM_TYPE_REQ_ERR = 'Claim Type is required.';
export const MEDIA_TYPE_REQ_ERR = 'Media Type is required.';
export const LOCATION_REQ_ERR = 'Location is required.';
export const CLAIM_EXP_RES_MT = 'Please select All or Specific.';
export const CLAIM_EXP_RES_MT_TO = 'Please select a Media Type to copy to.';
export const CLAIM_EXP_RES_MT_FROM = 'Please select a Media Type to copy from.';
export const CLAIM_LOB_FILTER = 'Please select an LOB and then click filter.';
export const CLAIM_REQ_TABS =
  'Main and Disposition tab information is required to save.';
export const EXCEP_NUM = 'Exception Code should be Numeric.';
export const EXCEP_DEP_NUM = 'Exception Dependency Code should be Numeric.';
export const TRACK_NUM_INVALID = 'Tracking Number should be numeric.';
// CLAIM EXCEPTION ENDS

//MEDICAL CRITERIA STARTS
export const LIMIT_PARAMETER_NUMBER_ERR = 'Parameter Number is required.';
export const LIMIT_LOB_ERR = 'Line of Business is required.';
export const LIMIT_RANK_ERR = 'Rank is required.';
export const MEDICAL_CRITERIA_CODE_ERR = 'Code is required.';
export const NO_DATA_FOUND = 'No data found';
export const MEDICAL_CRITERIA_ERR =
  'At least one Limit Parameter or Contraindicated Parameter is required.';
export const MEDICAL_PFORMAT_ERR =
  'Procedure Code was not entered in the correct format. The valid formats are: A9999, 99999, 9999A or 999';
export const MEDICAL_RFORMAT_ERR =
  'Invalid Revenue Code: Revenue must be four characters, all numeric';
export const MEDICALP_IE_COS_ERR = 'Category of Service selected is required.';
export const MEDICALP_IE_PM_ERR = 'Procedure Modifier selected is required.';
export const MEDICALP_IE_POS_ERR = 'Place of Service selected is required.';
export const MEDICALP_IE_TN_ERR = 'Tooth Number selected is required.';
export const MEDICALP_IE_BENEFIT_ERR = 'Benefit Plan selected is required.';
export const MEDICALP_IE_RANGE_ERR =
  'Procedure/Revenue Range or Procedure/Revenue List Num required when Include or Exclude selected.';
export const MEDICALP_IE_DIAG_ERR = 'Invalid diagnosis code.';
export const MEDICALP_IE_DIAG_GRT_ERR =
  'From Value must be less than or equal to Thru Value.';
export const MEDICALP_IE_NA_ERR =
  'Procedure/Revenue Range not allowed when Procedure/Revenue List Numbers are selected';
export const MEDICALP_DIAG_NA_ERR = 'Diagnosis Code Range not allowed when Diagnosis Code List Numbers are selected.'
export const MEDICALP_IE_MOD_ERR =
  'Modifiers can only be entered when a from and thru procedure range is entered.';
export const MEDICALP_IE_MODDUP_ERR = 'Duplicate Modifiers are not allowed.';
export const MEDICAL_CRITERIA_LIMIT_DUP =
  'UR Parameter entered is already in the system for selected Medical Criteria. Duplicate UR Limit Parameters are not allowed.';
export const MEDICAL_CRITERIA_CONTRA_DUP =
  'UR Parameter entered is already in the system for selected Medical Criteria. Duplicate UR Contraindicated Parameters are not allowed.';
export const INVALID_PARAM_NUM = 'Invalid Limit Parameter Number entered';
export const INVALID_CON_PARAM_NUM =
  'Invalid Contraindicated Parameter Number entered';
export const INVALID_RANKING_NUM = 'Data entered is in the incorrect format.';
export const INVALID_SHOW_PARAM_NUM =
  'Data entered is in the incorrect format. ';
export const MEDICALP_IE_PROC_GRT_ERR =
  'From Value must be less than or equal to Thru Value.';
export const MEDICALP_IE_REV_GRT_ERR =
  'From Value must be less than or equal to Thru Value.';
export const PROC_POS_ERR = 'Place of Service is required.';

//MEDICAL CRITERIA END

//UR SYSTEM LIST STARTS
export const INVALID_PROC_CODE = 'Invalid Procedure code.';
export const INVALID_DIAG_CODE = 'Invalid diagnosis code.';

export const INVALID_DIAG_POINTER = 'Invalid Diag Pointer.';
export const INVALID_DIAG_CODE_THRU = 'Invalid diagnosis code.';
export const INVALID_PROC_CODE_THRU = 'Invalid Procedure code.';
export const INVALID_DESCRIPTION = 'Data entered is in the incorrect format.';
export const REQ_FROM = 'From is required.';
export const REQ_THRU = 'Thru is required.';
export const FROM_THRU_ERR =
  'From Value must be less than or equal to Thru Value.';
export const UR_SYS_LIST_ERR = 'UR System List # is required.';
export const DUP_FROM_CODE = 'Entered From Code is already added to the List';
export const DUP_THRU_CODE = 'Entered Thru Code is already added to the List';
export const INVALID_REV_CODE = 'Invalid Revenue Code';
export const Invalid_ProcCode_Error =
  'Invalid Procedure Code: Procedure must be four characters, all numeric.';
export const Invalid_RevCode_Error =
  'Invalid Revenue Code: Revenue must be four characters, all numeric.';
export const ROW_REQ_ERR =
  'At least one detail row is required. Please enter at least one detail row.';
//UR SYSTEM LIST END

export const DUP_RA_ERR =
  'The Document Type, Media type and Claim Type combination exists for this exception code.  Duplicate Document Type, Media Type and Claim Type combinations are not allowed for RA Text Override.';
export const DUP_CLAIM_ROUTING_ERR =
  'The Document Type, Media type and Claim Type combination exists for this exception code.  Duplicate Document Type, Media Type and Claim Type combinations are not allowed for Claim Routing.';
// Procedure code Provider Taxonomy Starts
export const INCORRECT_PROC_ERR = 'Incorrect current procedure code';
// Procedure code Provider Taxonomy Ends

export const DUPLICATE_ADJUSTMENT_ERR =
  'Adjustment reason code already exists for claim exception code.  Duplicate Adjustment Reason Codes are not allowed.';
export const DUPLICATE_REASON_CODE =
  'Remark code already exists for claim exception code.  Duplicate Remark Codes are not allowed.';
export const AMT_REQ_ERR = 'Amount is required.';
export const BANKRUPTCY_AMT_DEC_ERR =
  'Claim Amount can have only 7 characters before decimal and 2 characters after decimal.';
export const BANKRUPTCY_POST_DEBIT_AMT_DEC_ERR =
  'Post Debit Amount can have only 7 characters before decimal and 2 characters after decimal.';
export const DATALIST_MED_ALLW_DEC_ERR =
  'Medicare Allowable can have only 7 characters before decimal and 2 characters after decimal.';
export const DATALIST_PER_COV_DEC_ERR =
  'Percent Covered can have only 5 characters before decimal and 2 characters after decimal.';
export const RATE_AMT_DEC_ERR =
  'Rate Amount can have only 7 characters before decimal and 2 characters after decimal.';
export const AGE_RATE_AMT_ERR =
  'Rate Amount can have only 9 characters before decimal and 2 characters after decimal.';
export const PER_AMT_DEC_ERR =
  'Rate Percentage can have only 5 characters before decimal and 2 characters after decimal.';
export const MUP_PLR_DEC_ERR =
  'Multiplier can have only 5 characters before decimal and 2 characters after decimal.';
export const RATE_ZERO_ERR = 'Rate Amount should not be Zero';
export const PROV_TAXO_REQ_ERR = 'Provider Taxonomy is required.';
export const RATE_REQ_ERR = 'Rate is required.';
export const ONE_RATE_RATE_REQ_ERR = 'Atleast one rate row is required.';
export const PROV_COS_REQ_ERR = 'Category of Service is required.';
export const PCP_PROVIDER_TYPE_DELETE_SUCCESS =
  'System successfully deleted the Information.';
// Procedure code Provider Taxonomy Ends

export const DUB_RATE_SOURCE =
  'A rate for the header criteria already exists. Duplicates rates are not allowed.';
export const DENT_SUSP_DESP =
  'Denied or Suspended dispositions require an Adjustment Reason code and EOB to be entered.';
export const SUSP_DESP_ERR =
  'Suspended dispositions require a Location to be entered.';
export const BEGIN_DT_GRT_END_DT =
  'The begin date must be less than or equal to the end date.';
export const INCORRECT_PROC_CODE = 'Incorrect current procedure code.';
export const MOD_PROC_CODE_REQ =
  'Enter a Procedure Code as Search criteria if Modifier is selected.';

export const OnePOS_Error =
  'At least one detail row is required in Procedure Code / Place of Service.';
export const PROV_COS_ROW_REQ = 'At least one Rate row is required.';

export const PROCEDURE_MIN_FIVE = 'ProcedureCode length is less than five.';
export const CONF_COD_REQ = 'Confidentiality Code is required.';
export const PROV_IDTYPE_CONF_REQ =
  'Provider ID Type and Provider ID are both required for Confidentiality Code selected.';
export const MEMBER_MID_ERR =
  "Member's MID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MEMBER_RID_ERR =
  "Member's RID is incorrect format due to being the wrong length or it contains non-numeric characters.";

export const SSN_NUMERIC_ERR = 'SSN not numeric';
export const ENT_ERR = 'Enterprise ID should start with XXM.';
export const MCR_ERR =
  'Medicare ID should have length minimum of 10 and maximum of 12.';

export const AT_ONE_RATE_DETAIL = 'At least one Rate Detail row must exist.';
export const RATE_PROV_NET_NOT_EXIST = 'Provider not in Network.';
export const RATE_PROV_NOT_FILE = 'Provider ID not on the Provider file.';
export const RATE_PROC_NOT_FILE = 'Procedure Code not on file.';

export const STATE_DRUG_REQ = 'State is required.';
export const SA_EXC_CODE_REQ_ERROR = 'SA Exception Code is required.';
export const SA_EXC_TYPE_REQ_ERROR = 'Exception Type is required.';
export const SA_EXC_LOC_REQ_ERROR = 'Location is required.';
export const SA_EXC_CODE_FORMAT_ERROR = 'SA Exception Code should be numeric.';
export const SA_EXC_SA_TYPE_REQ_ERROR = 'SA Type is required.';
export const SA_EXC_SUSPEND_REQ_ERR =
  'Either a Location or a User ID is required if a SA Routing Location row is entered.';
export const SA_EXC_CODE_SUSPEND_DUP_ERR =
  'SA Routing Location entered is already in the system for selected SA Exception Code. Duplicate SA Routing Locations are not allowed.';
export const SA_EXC_MEDIA_TY_REQ_ERROR = 'SA Media Type is required.';

export const SA_EXC_RSN_CODE_ERR = 'Please select SA Reason codes in order.';
export const SA_EXC_RSN_CODE_DUP_ERR =
  'SA Reason Code entered is already in the system for selected SA Exception Code. Duplicate SA Reason Codes are not allowed.';
export const INPATIENT_DRG_ERR = 'At least one Rate Detail row must exist.';
export const NON_DRG_RATE_ERR = 'At least one Rate Detail row must exist.';
export const PROV_ID_TYPE_CODE_ERR = 'Provider ID Type Code is required.';
export const PROV_ID_ERR = 'Provider ID is required.';
export const AT_LEAST_ONE_ROW = 'At least one Rate row is required.';
export const POINT_RATE_ERR = 'Point Rate is required.';
export const ADJUST_FACTOR_ERR = 'Adjust Factor is required.';
export const CHARGE_MODE_ERR = 'Charge Mode is required.';
export const AMOUNT_ERR = 'Amount or Percent must be entered';
export const STATUS_CHANGE_REASON_ERROR =
  'The Line Item\'s Reason for Status Change is required if the SA LI Status is "D-Denied" or "V-Voided"';
export const PROV_BILL_ID_ERR = 'Provider Billig ID is required.';
export const END_DATE_SRAEDLB_ERROR =
  'The end date must be greater than or equal to the begin date.';
export const END_DATE_LESS_THAN_BEGIN_DATE =
  'The end date must be greater than or equal to the begin date.';
export const UNITS_OF_MEAS_REQUIRED =
  'Units of Measure is Required when Requested Units Entered.';
export const CATEGORY_CD_REQUIRED = 'Category Code is required.';
export const SVC_TYPE_REQUIRED = 'Svc Type is required.';
export const FROM_SVC_REQUIRED = 'From Svc is required.';
export const TO_SVC_REQUIRED = 'To Svc is required.';
export const PROF_BEGIN_DATE_INV =
  "Line Item Summary's First Date of Service is invalid.";
export const PROF_END_DATE_INV =
  "Line Item Summary's Last Date of Service is invalid.";
export const PROF_TOTAL_CHARGE_INV =
  "Line Item summary's Total Charge is invalid.";
export const PROF_SERIAL_INV = 'Serial # is invalid';
export const MEDCAIR_ALLOW_AMT_INV = 'Medicare Allowed Amount is invalid.';
export const MEDI_DEDUCT_AMT_INV = 'Medicare Deduct Amount is invalid.';
export const MEDI_COINS_AMT_INV = 'Medicare Coins Amount is invalid.';
export const MEDI_PAID_AMT_INV = 'Medicare Paid Amount is invalid.';
export const DRUG_UNIT_PRICE_ERR = 'Drug Unit Price is invalid.';
export const NATIONAL_DRUG_UNIT_COUNT_ERR =
  'National Drug Unit Count is invalid.';
export const PRECRIPTION_DATE_ERR = 'Prescription Date is invalid.';
export const SSN_INVALID = 'Invalid SSN.';
export const CASE_PHONE_OVERLAP =
  'Entered Phone span dates for the same Phone Type and Phone Significance overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Phone span.';
export const CASE_REP_OVERLAP =
  'Entered Case Rep span dates for the same Case Number and Rep Type overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Case Rep span.';
export const CASE_OWNER_OVERLAP =
  'Entered Case Owner span dates for the same Case Number overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Case Owner span.';
export const CASE_ASSISTANCE_OVERLAP =
  'Entered Case/AG span dates for the same Case Number and AG Number overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Case/AG span.';
export const NO_CASE_REP =
  'A case must have at least one non-voided open-ended Case Rep span.';
export const NO_CASE_OWNER =
  'A case must have at least one non-voided open-ended Case Owner span.';
export const NO_CASE_ASSISTANCE =
  'A case must have at least one non-voided Case/Assistance Group span.';
export const NO_DATE_ERROR = 'Issue Date is required.';
export const ISSUE_REASON_ERROR = 'Issue Reason is required.';
export const CARD_TYPE_ERROR = 'Card Type is required.';
export const DEACTIVATION_ERROR = 'Deactivation Reason is required.';
export const DEACTIVATION_DATE_ERROR = 'Deactivation Date is required.';

export const CARRIER_ERR = 'Carrier ID is required.';
export const CARRIER_TYPE_ERR = 'Carrier Type is required.';
export const NATION_ERR = 'National Payer ID is required.';
export const GROUP_ERR = 'Group ID is required.';
export const NUM_ERR = 'Number is required.';
export const NUM_TYPE_ERR = 'Number Type is required.';
export const NAME_ERR = 'Name is required.';
export const SEARCHBY_ERR = 'Search By  is required.';
export const LAST_ERR = 'Last name is required.';
export const WORKER_ERR = 'Worker ID is required.';
export const CASE_WORKER_ERR = 'Case Worker ID is required.';
export const CASE_WORKER_NAM_ERR = 'Case Worker Name is required.';
export const CASE_WORKER_FORMAT_ERR =
  'Case Worker ID should be alphanumeric.Case Worker Name is required.';
export const CITY_ERR =
  'Either City or State must be entered while entering the City/State.';
export const LEVEL_OF_CARE_ERROR =
  'A Level of Care must be selected if the Charge Mode is LTC.';
export const LEVEL_OF_CARE_CHARGE_MODE_ERROR =
  'A Level of Care should not be selected if the Charge Mode is something other than LTC.';
export const POINT_OF_RATE_INVALID_ERROR =
  'Point Rate format should be #######.## or #######.';
export const TEMPORARY_SUCCESS_MSG =
  'Your entered data was successfully stored temporarily. It will be saved permanently to the data base when you select the save link at the top of this page.';
export const CONFIDENTIALITY_OVERLAP =
  'Entered Confidentiality Code span dates for the same Member ID and Confidentiality Code overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Confidentiality Code span.';
export const PREG_DD_INVALID =
  'Pregnancy Due Date is invalid. Date should be in mm/dd/yyyy format.';
export const PREG_DD_REQ =
  'Due Date is required when Number of Children is entered.';
export const PREG_AGE_ERR =
  'Attempting to add pregnancy information for a member under the age of 11 or over the age of 60.';
export const RACE_REQUIRED = 'Select at least one from the Race.';
export const CASE_MISSING = 'Case number is missing';
export const NEW_MEMBER_SUCCESS =
  'Member successfully added. Member Enrollment processing has been triggered.';
export const SUSPECT_INFO =
  'Suspect duplicate found. Member added, suspect link recorded.';
export const MEMBER_EXIST =
  'The Member ID and ID type entered already exist for a different member in the Line of Business.  Please check the data entered and correct.';
export const ATLEAST_ONE_CASE_ADDR =
  'A case must have at least one non-voided open-ended Residential Address span.';
export const SUSPECT_DUPLICATE = 'A Potential Duplicate Member has been found.';
export const MEMBER_INITIAL_ERR = 'Member middle initial invalid.';
export const ENROLLMENT_OVERLAP_ERROR =
  'Same Benefit Plan Details are already exist for Retrigger Date';
export const PLAN_ID = 'Plan ID is required.';
export const REQUEST_BEGIN_DATE_ERROR = 'Begin Date is required.';
export const REQUEST_END_DATE_ERROR = 'End Date is required.';
export const TRANSACTION_TYPE = 'Transaction Type is required.';
export const BENEFIT_PLAN_ERR =
  'Requested row does not match existing Benefit Plan.';

export const RESPONSE_DATE_INVALID = 'Response Date is invalid';
export const RDT_GT_SNDT =
  'Response Date must be greater than or equal to Second Notice Date';
export const RDT_GT_FNDT =
  'Response Date must be greater than or equal to First Notice Date';
export const RDT_GT_CDT = 'Response Date cannot be greater than System Date';
export const MSQ_TYPE = 'MSQ Type is required.';
export const DUPLICATE_TCN_ERR = 'MSQ cannot be entered with a duplicate TCN.';
export const DELETE_MSQ_ERR =
  'The selected MSQ cannot be deleted as the MSQ has already been sent to member.';
export const NO_TCN_ERR =
  'A claim cannot be found in Claims History that matches the TCN entered. Please check to see that TCN was correctly entered';
export const TCN_FORMAT_ERROR = 'Data entered is in the incorrect format.';
export const MEDICAL_DATE_INVALID_ERROR =
  'Invalid Date format. Date should be in MM/DD/YYYY format.';
export const TCN_INV_ERROR =
  'A claim cannot be fount in Claims History that matches the TCN entered. Please check to see that TCN was correctly entered';
export const ADJUST_FACTOR_FORMET_ERROR =
  'Adjust Factor format should be ###.###### or ###.';
export const AMOUNT_INVALID_ERROR = 'Currency format should be #########.##';
export const PERCENT_INVALID_ERROR =
  'Percent format should be ####.##### or ####';
export const SEARCH_BY = 'Search By is required.';
export const MEMBER_ID_TYPE_ERROR = 'Member ID Type is required.';
export const EOMB_DATE_INV = 'EOMB Date is invalid.';
export const SIGN_DATE_INV = 'Sign / Date is invalid.';
export const BENEFIT_PLAN_AMOUNT_ERR =
  'Rate Amount cannot be more than 7 characters without decimal.	';
export const CARRIER_ID_REQUIRED = 'Carrier ID is required.';
export const CARRIER_ID_FORMAT = 'Data entered is in the incorrect format.';
export const PROVIDER_NOT_FILE = 'Provider ID not on the Provider file.';
export const PROCEDURE_NOT_FILE = 'Procedure Code not on file.';
export const PROCEDURE_AGE_OVERLAP =
  'There cannot be overlaps between age range across the procedure code/modifier combinations.';
export const PROCEDURE_AGE_OVERLAP_AGE =
  'There cannot be an overlap rate record with the Age range.';
export const MIN_AGE_MAX_ERR =
  'Minimum age must be less than or equal to maximum age.';
export const MONTH_RANGE =
  'The valid range for the month portion of the age is between 0 and 11.';
export const PROCEDURE_CODE_NOT_FILE = 'Procedure Code not on file';
export const LINE_ITEM_MAX_ERR =
  'A maximum of 50 lines may be added to a claim. The number of lines exceeds 50.';
export const LINE_ITEM_TOOTH_MAX_ERR =
  'A maximum of 32 tooth codes may be added to a Line. The number of tooth codes exceeds 32.';
export const DATE_OVERLAP =
  'There cannot be overlaps between date spans across the detail rows.';
export const UNABLE_WORK_BEGIN_ERR = 'Unable to Work Begin Date is Invalid.';
export const UNABLE_WORK_END_ERR = 'Unable to Work End Date is Invalid.';
export const ADMIT_BEGIN_ERR = 'Admit Date is Invalid.';
export const VISIT_HOSP_BEGIN_ERR = 'Hospital Begin Date is Invalid.';
export const VISIT_HOSP_END_ERR = 'Hospital End Date is Invalid.';
export const OUT_LAB_AMT_ERR = 'Visit Out Lab / Amt is Invalid.';
export const LOB_CODE_FORMAT_ERROR = 'Enter a Valid Lob Code.';
export const LOB_NAME_FORMAT_ERROR = 'Enter a Valid Lob Name.';
export const TPL_MASS_CHANGE_TYPE_ERR = 'Mass Change Type is required.';
export const Starts_With_Contains_Error_Network =
  'Search criteria must be at least 2 characters in length. Please enter at least 2 characters and resubmit';
export const TP_ID_REQ_ERR = 'Trading Partner ID is required.';
export const TP_ID_INV_ERR = 'Trading Partner ID is Invalid.';
export const SEARCHFOR_ERR = 'Search For is required.';
export const CLASSTYPE_ERR = 'Classification Type ID is required.';
export const LNAME_ERR = 'Last Name is required.';
export const BNAME_ERR = 'Business Name is required.';
export const SCREENING_TYPE_ERR = 'Screening Type is required.';
export const GENERIC_SYSTEM_ERROR =
  'There was an error processing the request. Please retry the transaction.';
export const DESCRIPTION_IS_REQUIRED = 'Description is required.';
export const WORKUNIT_IS_REQUIRED = 'Work Unit is required.';
export const DOC_TYPE_IS_REQUIRED = 'Document Type is required.';
export const FILE_IS_REQUIRED = 'File is required.';
export const ONLY_ONE_FILE_SELECT = 'Only 1 file can be uploaded at a time.';
export const MAX_ONE_ATTACHMENT_ALLOWED = 'Only one attachment is allowed.';
export const INVALID_FILE_TYPE =
  'The files you are attempting to attach are an invalid file type. Please contact Technical Support for information on valid file types.';
export const ZERO_MB_FILE =
  'The file uploaded is empty/zero bytes. The attachment will not be saved.';
export const MAX_FILE_SIZE_EXCEEDED =
  'The file(s) you are attempting to attach is/are larger than the maximum allowed size of 10 mb. Please try again by reducing the file size or by uploading the files separately.';
export const ERROR_IN_DOWNLOADING_FILE =
  'Error while downloading the file. Please contact Technical Support';
export const FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const TPL_CONF_CODE_ERR = 'Confirmation Code is required.';
export const TPL_FRM_CARR_ID_ERR =
  'From Carrier ID is required - Please enter valid From Carrier ID';
export const TPL_GROUP_FRM_ID_ERR = 'From Group ID is required.';
export const TPL_TERMINATION_DT_ERR =
  'Termination date is required - please enter the termination date.';
export const TPL_TO_CARR_ID_ERR =
  'To Carrier ID is required - Please enter valid To Carrier ID';
export const TPL_TO_BEGIN_DATE_ERR = 'To Begin Date is required.';
export const TPL_INVALID_CARR_ID_ERR =
  'From Carrier id is required - Please enter valid from carrier id.';
export const TPL_INVALID_GRP_ID_ERR =
  'From Group id is required - Please enter valid from Group id.';
export const TPL_INVALID_DATE_ERR = 'Date entered in incorrect format.';
export const LINE_RATE_AMT_INV = 'Rate Amount is invalid.';
export const LINE_NON_COV_CHG_INV = 'Non Covered Charges Amount is invalid.';
export const INPATIENT_BASE_RATE_AMT_INV =
  'Inpatient Pricing Base Rate Amount is invalid.';
export const LOCATION_CODE_REQ = 'Location Code is required.';
export const LOCATION_TEXT_REQ = 'Text is required.';
export const LOCATION_CODE_INVALID =
  ' Location Code Must Be 3 Numeric Characters.';

export const CONTACT_TYPE_REQD = 'Contact Type is required.';
export const SIGNIFICANCE_REQD = 'Significance is required.';
export const FIRST_NAME_REQD = 'First Name is required.';
export const LAST_NAME_REQD = 'Last Name is required.';
export const BEGIN_DATE_REQD = 'Begin Date is required.';
export const END_DATE_REQD = 'End Date is required.';
export const CONTACT_STATUS_REQD = 'Status is required.';
export const INCORRECT_FORMAT = 'Please enter the data in correct format.';
export const DOD_LT_DOB = 'Date of Death cannot be less than the Date of Birth';
export const EDT_LT_BDT =
  'End Date must be greater than or equal to begin date.';
export const BEGIN_DATE_PRIOR_ERR =
  'Begin Date may not be prior to the current system date.';
export const CARRIER_NAME_REQUIRED = 'Carrier Name is required.';
export const AS_DATE_INVALID =
  'Invalid As of Date, please enter in MM/DD/YYYY format';
export const MCO_PROVIDER_ID_REQ = 'MCO ProviderID is required.';
export const PAP_PROVIDER_ID_REQ = 'PAP ProviderID is required.';
export const PROVIDERID_NOT_VALID = 'Provider ID is not valid.';
export const PROVIDERID_SPEL_CHAR_ERR =
  'Provider ID must not contain special characters.';
export const NETWORK_NAME_REQ = 'Network Name is required.';
export const NETWORK_TYPE_REQ = 'Network Type is required.';
export const ADDS_PROV_NETWORK =
  'At least one address is required for a Provider Network.';
export const NETWORK_ID_SPEL_CHAR =
  'Network ID must not contain special characters.';
export const NETWORK_NAME_SPEL_CHAR =
  'Network Name must not contain special characters';
export const PROVIDER_MQ_REQ =
  'If the entered Network Type is Managed Care or PAP, then the Provider ID is required.';
export const MCO_PROVIDER_TYPE_CHECK =
  'If the MCO Provider ID is entered, then the provider must be an MCO provider type.';
export const PAP_PROVIDER_TYPE_CHECK =
  'For network type of Q, the Provider ID entered must be associated to a PAP Provider Type.';
export const NETWORK_DUPLICATE_CHECK =
  'Duplication of the same Network ID is not allowed.';
export const INVALID_REC_DATE =
  'Received Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_REQ_BGN_DATE =
  'Requested Begin Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_REQ_END_DATE =
  'Requested End Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_APP_BGN_DATE =
  'Approved Begin Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const INVALID_APP_END_DATE =
  'Approved End Date is invalid. It should be in the format MM/DD/CCYY or MMDDCCYY';
export const SUPPLEMENTAL_PROVIDER_ID_REQ =
  'Supplemental Provider ID is Required.';
export const PROVIDER_ID_TYPE_REQ = 'Provider ID Type is required.';
export const DOB_ERR = 'Date of Birth cannot be greater than the Current Date';
export const DOB_GT_CT_ERR = 'Date of Birth must not be in the future.';
export const REQUESTING_ERR =
  'Requesting Provider ID and Type are both required if either is entered.';
export const EVENT_ERR =
  'Event Provider ID Type and Provider ID are both required if either are entered.';
export const SERVICE_ERR = 'Servicing Provider ID is required.';
export const SERVICE_TYPE_ERR =
  'Servicing Provider ID Type and Provider ID are both required if either are entered.';
export const VALID_HEADER_STATUS_ERR =
  'Please enter a Header Status of A (Approved), D (Denied), V (Voided), or P (Pended).';
export const Received_Dt_Future_ERR =
  'Received Date cannot be greater than the Current Date';
export const HICN_REQ_ERROR = 'HICN is Required.';
export const MEDICARE_COVERAGE_REQ_ERROR = 'Medicare Coverage is Required.';
export const TOOTH_ERR = 'Tooth Number is Required.';
export const QUALIFIER_TYPE = 'Service Quantity Type is Required.';
export const QUANTITY = 'Service Quantity is Required.';
export const LINE_FACILITY_TYPE =
  'Line Facility Type is required if Line Facility Type Qualifier is present.';
export const LINE_FACILITY_TYPE_QUALIFIER =
  'Line Facility Type Qualifier is required if Line Facility Type is present.';
export const ADJ_CNTRL_INVLD_ERR = 'Please enter valid sequence number.';
export const ADJ_CNTRL_REQ_ERR = 'Service Sequence is required.';
export const ADJ_TYCD_REQ_ERR = 'Claim Type is required.';
export const ADJ_BTYCD_REQ_ERR = 'Batch Document Type is required.';
export const MEDICARE_COVERAGE_INVALID = 'Duplicate Medicare Coverage.';
export const VALIDATE_MEMBER_ID = 'Please validate Member ID';
export const BEFORE_BIRTH_DATE =
  'Medicare span Begin date cannot be less than Member Date of Birth.';
export const MEMBER_ID_NOT_INDB_ERR =
  'Member ID not found for the Member ID Type selected on the database.';
export const Overlaps_Btw_Detail_Rows_Dates_same_coverage =
  'Medicare Coverage Period cannot be overlapping to existing Medicare Coverage Period for same Medicare Type';
//TPL Add/update
export const COVERAGE_REQ_ERR =
  "Coverage must be selected when Annual Exhausted Date is entered and/or Lifetime Exhausted Indicator is set to 'Yes'.";
export const ANNUAL_EXAUST_DATE_ERR =
  'Annual Exhausted Date must be populated or Lifetime benefits exhausted indicator must be set to Yes when coverage is selected.';
export const GTTHAN_SYSTEM_DATE_ERR =
  'Annual exhausted date cannot be greater than System Date.';
export const MULTIPLE_COVERAGE_CODE_ERR =
  'Policy Coverage Code cannot be selected multiple times for the same policy period.';
export const STATUS_CODE_REQ_ERROR = 'Status Code is required.';
export const STATUS_CODE_ACTIVE_ERR =
  "The Covered Individual's status may not be set to Active when the policy is pended.";
export const COVERED_INDV_BEGINDATE_ACTIVE_ERR =
  'Begin Date is required when Covered Individual is active.';
export const COVERED_INDV_ENDDATE_ACTIVE_ERR =
  'End Date is required when Covered Individual is active.';
export const BEGIN_LESS_END_DATE_ERR =
  'Begin Date must be less than or equal to End Date.';
export const BEGIN_DATE_END_DATE =
  'The begin date must be less than or equal to the end date.';
export const STATUS_CODE_VOIDED_ERR =
  'Covered Individual Information cannot be saved because Covered Individual Information is being voided and one or more other fields has been changed.';
export const PH_ADDRESS_REQ = 'Policyholder Address is required.';
export const EMP_NAM_REQ = 'Employer Name is required.';
export const ADDRESS_TYPE_REQ = 'Address Type is required.';
export const ADDRESS_REQ = 'Address 1 is required.';
export const E_CITY_REQUIRED = 'City is required.';
export const E_ZIP_CODE_REQUIRED = 'Zip code is required.';
export const E_STATE_REQ = 'State is required.';
export const E_ADDRESS_TYPE_REQ = 'Email Address Type Code is required.';
export const E_ADDRESS_REQ = 'Email Address is required.';
export const E_MAIL_FORMAT = 'Email text is not in correct format.';
export const PHONE_TYPE_REQ = 'Type is required.';
export const PHONE_NUM_REQ = 'Phone Number is required.';
export const PHONE_NUM_ERRN =
  'A phone extension number has been entered without a corresponding phone number.';
export const PH_CITY_STATE_ZIP_REQ = 'City, State and Zip are required.';
export const SAVE_TPL_REQ_FIELDS =
  'Member ID must be entered when Carrier ID and Policy are not entered';
export const ECPLYER_ADDRESS_VALIDATE =
  'Address Type, Address Line1, City, State and Zip cannot be spaces when Employer Name is entered';
export const isOnlyAddress1 = "Employer Name, Address Type, City, State and Zip are required when Employer Address Line 1 is entered" ;
export const ADD_LINE1_REQ = 'Employer address Line1 is required.';
export const FIRST_NAME_INVALID =
  'Warning-Member first name contains invalid characters. System will continue processing the name as entered.';
export const LAST_NAME_INVALID =
  'Warning-Member last name contains invalid characters. System will continue processing the name as entered.';
export const CARRIER_NOT_DB =
  'Carrier ID not found in database - Please enter correct Carrier ID.';
export const GENERATE_270_CARRIER_ID_ERR =
  'Carrier is not associated with Submitting Trading Partner or associated Submitting Trading Partner is not active.';
export const GENERATE_270_SUCCESS_MSG =
  'Generate 270 request created successfully.';
export const HICN_FORMAT_ERROR =
  'Medicare ID/HIC number is in an invalid format.';
export const HICN_ERROR = 'Medicare ID/HIC number not present.';
export const CMBI_FORMAT_ERROR = 'Please enter Current MBI in correct format.';
export const MBI_FORMAT_ERROR = 'MBI entered is not in valid format';
export const EVENT_PRV_TAX_ERR =
  'An Event Provider Taxonomy Code is Not Allowed with the Entity Code Selected.';
export const SERVICE_PRV_TAX_ERR =
  'Service Provider Taxonomy Code is Not Allowed with the Entity Code Selected.';
export const PRV_ZIP_EXTN =
  'Zip Code is required if Zip+4/Extension is entered.';
export const ASS_PAPER_WORK_ATT =
  'Attachments at the line level must have a type and delivery method.';
export const POLICY_NUM_REQ = 'Policy Number is required.';
export const RESOUCE_TYPE_REQ = 'Resource Type is required.';
export const POLICY_BEGIN_DT_REQ = 'Policy Begin Date is required.';
export const POLICY_END_DT_REQ = 'Policy End Date is required.';
export const TPL_COVERAGES_REQ =
  'Please select at least one coverage for the Policy to activate Policy Period.';
export const TPL_COV_IND_REQ =
  'At least one valid Covered Individual must be entered and active to activate the policy period.';
export const TPL_PERIOD_OVERLAP_ERROR = "Policy Coverage Period cannot be overlapping to existing Policy Coverage Period.";
//Void Adjustments
export const INVALID_TCN_FORMAT = 'Please enter the tcn in correct format.';
export const REPLACEMENT_TCN_IN_USE =
  'The Replacement TCN is already present. Use a different document number.';
export const TCN_DOESNT_EXIST_IN_DB = `The TCN to be Replaced or Voided does not match a previously "Paid" or "Denied", "To be Paid" or "To be Denied" Claim in history.`;
export const PROV_REQ = 'Provider ID Type is required.';
export const BILL_PROV_REQ = 'Billing Provider ID  is required.';
export const MEM_REQ = 'Member ID is required.';
export const TCN_VOID_REQ = 'TCN to be voided/Replaced is required.';
export const VOID_REP_SAVE_MSG =
  'Void / Replaced claim information saved with exceptions.';
export const VOID_REP_SAVE_MSG_WITHOUT_EXCP =
  'Void / Replaced claim information saved.';
export const VOID_REP_DEL_MSG = 'Void / Replaced claim deleted successfully.';
export const VOID_REP_SAVE_CMSG = 'The VoidReplacement Claim is saved.';
export const VOID_REP_DEL_CMSG = 'The VoidReplacement Claim is Deleted.';
export const REASON_REQ = 'Reason is required.';
export const TPL_COPY_VALIDATION =
  'Policy Copy can not be performed because all associated Covered Individuals have been Terminated or Voided before the Policy End Date';
export const SA_RQ_PROVIDER_ERROR =
  'At least one Requesting Provider ID is required.';
export const SA_RQ_PROVIDER_ID_TYPE_ERROR =
  'Requesting Provider IDs must include Medicaid and/or NPI#';
export const MEDORPROVIDREQ =
  'Please enter either Medicaid ID or Other Provider ID and Other Provider ID Type.';
export const SA_SV_PROV_LI_NUM = 'Prov Line# cannot be zero for SV providers';
export const EV_ENTITY_TYPE = 'Event Provider Entity Type is Required';
export const SV_ENTITY_TYPE = 'Service Provider Entity Type is Required';
export const RQ_ENTITY_TYPE = 'Request Provider Entity Type is Required';
export const SV_ENTITY_CODE = 'Service Provider Entity Code is Required';
export const EV_ENTITY_CODE = 'Event Provider Entity Code is Required';
export const RQ_ENTITY_CODE = 'Request Provider Entity Code is Required.';
export const EV_MAX_ID = "Event Provider max supplemental ID's is 7";
export const SV_MAX_ID = "Servicing Provider max Supplemental ID's is 8";
export const EV_PROV_SEQ_OCCU =
  "Event Provider ID's Exceeds 14 or not sequential";
export const TRANS_LEVEL = 'Trans Level is Required.';
export const PROV_ID_TYPE = 'Provider Id Type is Required.';
export const PROV_ID = 'Provider Id is Required.';
export const LINE_ITEM_PROVIDER_ERROR =
  'Cannot add SV provider as corresponding line# doesn’t exist.';
export const ENTITY_CODE = 'Entity Code is Required.';
export const ENTITY_TYPE = 'Entity Type is Required.';
export const UNIT_OF_MEASURE =
  'Units of Measure is Required when Requested Units Entered.';
export const TPL_NAVIGATION_REQ =
  'Please Enter either Invoice or Member ID or TCN.';
export const LINE_ITEM_ORALCAVITY_ERR =
  'Tooth Oral Cavity must be entered contiguously starting with 1.';
export const LINE_ITEM_TOOTH_SURFACE =
  'Tooth Surfaces must be entered contiguously. starting with 1.';
export const BEGIN_TIME_INVALID = 'Begin Time format is invalid.';
export const END_TIME_INVALID = 'End Time format is invalid.';
export const USER_ID_NAME_ERR =
  'Please enter either User ID or Last Name for the search.';
export const EVENT_GT_DT_ERROR =
  'End Date must be greater than or equal to begin date.';
export const EV_PROV_ID_OCCUR =
  'Duplicate Event Provider ID Type for occurrence.';
export const SV_PROV_ID_OCCUR =
  'Duplicate Servicing Provider ID Type for occurrence.';
export const GLOBAL_AUDIT_SEARCH_ERR =
  '(User ID/Job ID or User Name) OR (Table Name and Field Name) OR (Key Type and Key Value) is required.';
export const INVOICE_REQ = 'Invoice Number is required.';
export const INVOICE_REQ_CHECK = 'Alteast One Checkbox should be Selected.';
export const KEY_VALUE_ERROR = 'Key is required when Key Type is entered.';
export const KEY_TYPE_ERROR = 'Key Type is required when Key is entered.';
export const APPROVAL_PERIOD_ERROR = 'Approval period is Required.';
export const APPROVAL_LENGTH_ERROR = 'Approved Length is Required.';
export const APPROVAL_UNITS_ERROR = 'Approval units is Required.';
export const LICENCE_STATE_REQ =
  'Event Supplemental Provider ID Requires a License State Code';

// Letter Assignment Error Messages
export const RANK_ERROR = 'Rank is Required.';
export const RANK_UNIQUE_ERROR = 'Rank should be unique.';
export const LETTER_ASSIGNMENT_TITLE_ERROR =
  'Letter Assignment Title is Required.';
export const SA_ANCILARY_FACILITY_TYPE_ERR =
  'If Facility Type is entered then Facility Type qualifier is required.';
export const SA_ANCILARY_FACILITY_TYPE_QUALIFIER_ERR =
  'If  Facility Type Qualifier is entered then Facility type is required';
export const PRIOR_ERR =
  'Annual exhausted date cannot be greater than System Date.';

// SA Validate API error
export const VALIDATE_LAST_NAME_ERROR =
  'The Last Name entered does not match the Last Name on the Member file for the entered MID.';
export const VALIDATE_FIRST_NAME_ERROR =
  'The First Name entered does not match the First Name on the Member file for the entered MID.';
export const VALIDATE_MI_ERROR =
  'The MI entered does not match the MI on the Member file for the entered MID.';
export const VALIDATE_SUFFIX_ERROR =
  'The Suffix entered does not match the Suffix on the Member file for the entered MID.';
export const VALIDATE_DOB_ERROR =
  'The Date of Birth entered does not match the Date of Birth on the Member file for the entered MID.';
export const VALIDATE_AGE_ERROR =
  'The Age entered does not match the Age on the Member file for the entered MID.';
export const VALIDATE_SSN_ERROR =
  'The SSN entered does not match the SSN on the Member file for the entered MID.';
export const CLOSURE_DATE_REQ =
  'Closure Date must be entered when Closure Reason is selected.';
export const CLOSURE_REASON_REQ =
  'Closure Reason must be selected when Close Date is entered.';
export const MASS_ADJ_EXCEPTION_CODES_CHECK =
  'Exception Code must be combined with Paid Date and/or Begin DOS. No other criteria types allowed.';
export const GLOBAL_DATE_INVALID =
  'Please enter date in the correct format.Example MM/DD/CCYY Example MM-DD-CCYY Example MMDDCCYY.';
export const INVALID_ERROR = 'Please enter date in the correct format.';
export const NEXT_DATE_ERROR = 'Next screening date must be future date.';
export const BILL_PROV_ID_INV =
  'Please enter the Billing Provider ID in correct format.';
export const MEMBER_ID_INVALID =
  'Please enter the Member ID in correct format.';
export const FCN_ID_INVALID = 'Please enter the FCN ID in correct format.';
export const UNIQUE_PROV_ID_ERR =
  'Provider ID entered is not unique. Please enter a unique Provider ID.';
export const DISCHARGE_HOUR_FORMAT_ERR =
  'Please enter valid Discharge Hour time format (HH).';
export const ADMIT_HOUR_FORMAT_ERR =
  'Please enter valid Admit Hour time format (HH).';
export const MCO_PROVIDER_NETWORK_CHECK =
  'If the entered Network Type is Managed Care or PAP, then the Provider ID is required.';
export const ITEM_ASSOCIATION =
  'This item cannot be deleted because it is tied to another data element.';

// Member Inquiry
export const MEMBER_NOT_UTILIZED_BENEFITS =
  'The member has not utilized any benefits currently limited by state policy.';
export const MEMBER_NOT_SERVICES_BENEFITS =
  'The member has not utilized any services currently limited by state policy.';

//notes
export const NOTES_ADD =
  'Your note(s) will be saved when you select the save link at the top of the page.';
export const INVALID_BEGIN_DATE_FORMAT = 'Incorrect begin date.';
export const INVALID_END_DATE_FORMAT = 'Incorrect end date format.';
export const DEL_CURR_DATE_CHK =
  'The begin date of the item being deleted must be greater than or equal  to the current date';

export const Amt_Format_Error = 'Max 2 digits allowed after decimal.';
export const EXC_FORCE_PAY_MSG = 'Exception Cannot be Force Paid/Approved.';
export const EXC_DENY_MSG = 'Exception Cannot be Force Denied.';

export const PROVIDER_ID_NOT_UNIQUE =
  'Provider ID entered is not unique. Please enter a unique Provider ID.';

export const ADJUSTMENT_REASON_CODE = 'Adjustment Reason Code is required.';
export const CAPITATION_EXISTING_CODE =
  'The Capitation claim void request already exist for the claim id entered in process.';
export const CAPITATION_CAPITATION_CODE =
  'The Capitation claim void request can not be submitted for non Capitation Claim.';

export const AT_ONE_BP_RATE_DETAIL =
  'Atleast one BP Rank should be present for a BPHierarchy.';
export const LAST_NAME_REQ_ERR = 'Last Name is required.';
export const INACTIVE_RESET_PASSWORD =
  'You cannot reset a password for an inactive target user account.';
export const PASSWORD_NEW_REQ = 'New Password is required.';
export const PASSWORD_CONFIRM_REQ = 'Confirm New Password is required.';
export const PASSWORD_REQ = 'Current Password is required.';
export const PASSWORD_LEN = 'The password must contain at least 10 characters.';
export const PASSWORD_3_OF_4 =
  'The password must not contain characters other than alphanumeric characters and the following non-alphanumeric characters: !, $, #, or %.';
export const PASSWORD_5_TIMES =
  'The new password must not be one of the 5 previously used passwords. Please try again.';
export const PASSWORD_AND_CONFIRM_SAME =
  'New and Confirm New passwords do not match. Please try again.';
export const USERID_INVALID =
  'The submitted UserID must be at least 6 to 16 alpha-numeric characters and can contain: hyphens,underscores,and/or periods (no spaces).';
export const USER_ID_REQ_ERR = 'User ID is required.';
export const USER_ID_EXIST =
  'The submitted User ID already exists. Please enter another User ID.';
export const INV_EMAIL = 'Email is invalid.';
export const FIRST_NAME_REQ = 'First Name is required.';
export const PHONE_REQ = 'Phone is required.';
export const ORGANIZATION_REQ = 'Organization is required.';
export const EMAIL_REQ = 'Email is required.';
export const USER_ROLES_REQ = 'User Role is required.';
export const WORK_UNITS_REQ = 'Work Unit is required.';
export const INV_PHONE = 'Phone is invalid.';
export const WILDSEARCH_RESTRICT =
  'At least one of the search fields is required.';
export const DST_OFC_ERR = 'District Office is required.';
export const INV_CONTENT = 'Please enter the data in correct format.';
export const INVALID_USER_FIRST_NAME =
  'First name contains invalid characters. Please enter the data in correct format';
export const INVALID_USER_MIDDLE_NAME =
  'Middle name contains invalid characters. Please enter the data in correct format';
export const INVALID_USER_LAST_NAME =
  'Last name contains invalid characters. Please enter the data in correct format';
// Provider Maintenence bankrutcy messages
export const PROVIDER_MAINTENENCE_BANKRUPTCY_TYPE =
  'Bankruptcy Type is required.';
export const BANKRUPTCY_INVALID_BEGIN_DATE =
  'Begin Date is invalid.It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy only';
export const BANKRUPTCY_INVALID_END_DATE =
  'End Date is invalid.It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy only';

export const PROVIDER_MAINTENENCE_CLAIM_AMOUNT = 'Claim Amount  is required.';
export const PROVIDER_MAINTENENCE_POST_PETITION_DEBIT =
  'Post Petition Debt is required.';
export const PROVIDER_MAINTENENCE_POST_PETITION_INDICATOR =
  'Post Petition Indicator is required.';
export const PROVIDER_MAINTENENCE_POST_DEBT_INDICATOR =
  'Post Debt Indicator is required.';
export const PROVIDER_MAINTENENCE_INVALID = 'Claim Amount is invalid.';
export const PROVIDER_MAINTENENCE_POST_INVALID =
  'Post Debit Amount is invalid.It should be numeric only';
export const PROVIDER_MAINTENENCE_POST_INVALIDD =
  'Post Debit Amount is invalid.';
export const PROVIDER_MAINTENENCE_OVERLAP =
  'Overlapping dates not allowed for Bankruptcy';

// Provider Maintenence messages

// Provider Maintenance Add Address Messages
export const PROVIDER_MAINTENANCE_MCO = 'MCO is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_LINE1 =
  'Address Line 1 is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_LINE2 = 'Line 2 is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_CITY = 'City is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_STATE = 'State is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_ADDRESS_TYPE =
  'Address Type is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_SIGINIFICANCE =
  'Significance is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_CHANGEREASON =
  'Change Reason is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_ZIPCODE =
  'Zip & Extension is required.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_INVALID_ZIPCODE =
  'Zip Code format is 99999.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_INVALID_ZIPEXTCODE =
  'Zip Extension format is 9999.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_BEGIN_DATE =
  'Begin Date is required';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_BEGIN_DATE_GREATER =
  'Date must be greater than or equal to the Current date.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_BEGIN_DATE_OVERLAPS =
  'There cannot be any overlaps between date spans across the detail rows for a Begin date.';
export const EFT_OVERLAP_ERROR = 'Overlapping dates not allowed for EFT.';
export const PROVIDER_MAINTENANCE_ADDRESS_FORM_END_DATE =
  'End Date is required';
// Provider Maintenance Add Address Messages

// Provider maintenance ownership messages
export const PROVIDER_MAINTENANCE_OWN_OTHER_PROVIDER_BUSSINESS_NAME =
  "Organization's Legal Business Name is required.";
// Provider Maintenance add update EFT Messages
export const PROVIDER_MAINTENANCE_EFT_FORM_BEGIN_DATE =
  'Begin Date is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_BEGIN_DATE_INVALID =
  'Begin Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy format only.';

export const PROVIDER_MAINTENANCE_EFFECTIVE_DATE =
  'Effective Date is required.';
export const PROVIDER_MAINTENANCE_END_DATE = 'End Date is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP_RELATIVE_INVALID =
  'Relative ZipCode is invalid.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ADDRESS = 'Address is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_CITY = 'City is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP =
  'Zip & Extension is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP4_INVALID =
  'The zip code extension must be 4 digits.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP4_INVALID_CODE =
  'Zip Code Extension is invalid.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_FEIN = 'SSN/FEIN is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_FEIN_MD = 'FEIN # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_DATE_COMP =
  'Effective Date should be lesser than the End Date.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_DATE_COMP_CI =
  'End Date of Controlling Interest must be greater than Effective Date of Controlling Interest.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_DATE_COMP_OW =
  'End Date of Ownership must be greater than Effective Date of Ownership.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_NH_TITLE =
  ' Current Title XIX # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_MC_NH =
  'Medicare or Title XIX # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP_INVALID =
  'Please enter a 5 digit zip code.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_ZIP_INVALID_CODE =
  'Zip Code is invalid.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_FEIN_INVALID =
  'Tax Id type FEIN is invalid for Individual provider.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_MEDICARE =
  'Current Medicare # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_MD = 'Medicare # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_NH_TITLE_MD =
  'Title XIX # is required.';
export const PROVIDER_MAINTENANCE_OWNERSHIP_LIST =
  'Owner significant business transactions from 4b are required.';
// Provider Maintenance add update EFT Messages
export const PROVIDER_MAINTENANCE_EFT_FORM_END_DATE = 'End Date is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_END_DATE_INVALID =
  'End Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy format only.';
export const PROVIDER_MAINTENANCE_EFT_FORM_STATUS = 'Status is required.';
export const PROVIDER_MAINTENANCE_BEGINDATE_GRETER_ENDDATE =
  'The End Date must be greater than Begin date.';
export const PROVIDER_MAINTENANCE_BEGINDATE_GRETER_ENDDATE_SPAN =
  "EFT span dates must be within a provider's active enrollment span.";
export const PROVIDER_MAINTENANCE_EFT_FORM_FIN_INST_NAME =
  'Financial Institution Name is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_FIN_INST_NAME_INVALID =
  'Financial Institution Name is invalid.';
export const PROVIDER_MAINTENANCE_EFT_FORM_ACC_NUM_INVALID =
  'Account Number is invalid. It should be numeric only';
export const PROVIDER_MAINTENANCE_EFT_FORM_ACCOUNT_TYPE_CD =
  'Type of Account at Financial Institution is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_FIN_INST_ROUT_NUM =
  'Financial Institution Routing Number is required.';
export const ROUTING_INVALID_ERROR = 'Bank Routing Number is invalid.';
export const PROVIDER_MAINTENANCE_EFT_FORM_ACC_NUM =
  "Provider's Account Number with Financial Institution is required.";
export const PROVIDER_MAINTENANCE_EFT_FORM_BANK_ADDRESS =
  'Bank Address is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_BANK_ADDRESS_INVALID =
  'Bank Address is invalid.';
export const PROVIDER_MAINTENANCE_EFT_FORM_CITY = 'City is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_CITY_INVALID =
  'City Name is invalid.';
export const PROVIDER_MAINTENANCE_EFT_FORM_STATE =
  'State/Province is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_ZIPCODE =
  'Zip Code/Postal Code is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_ZIPCODE_INVALID =
  'Zip Code/Postal Code is invalid.';
export const PROVIDER_MAINTENANCE_EFT_FORM_INVALID_ZIPCODE =
  'Please enter a 5 digit zip code.';
export const PROVIDER_MAINTENANCE_EFT_FORM_PHONE =
  'Financial Institution Telephone Number is required.';
export const PROVIDER_MAINTENANCE_EFT_FORM_PHONE_INVALID =
  'Financial Institution Telephone Number is invalid. Format is 999-999-9999 or 9999999999.';
export const PROVIDER_MAINTENANCE_EFT_FORM_ACC_NUM_LINK =
  'Account Number Linkage to Provider Identifier is required.';
// Provider Maintenance add update EFT Messages

// Provider Maintenance Add Numbers Error Messages
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_PHONE = 'Phone # is required.';
export const NameAndAddress_Billing_Phone_Req = 'Phone # is required.';
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_PHONE_INVALID =
  'Phone # is invalid.';
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_PHONE_INVALID_FORMAT =
  'Phone # is invalid. Format is 999-999-9999 or 9999999999.';
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_PHONE_EXT_INVALID_FORMAT =
  'Phone & Extension is invalid. It should be in format of xxx-xxx-xxxx or xxxxxxxxxx only.';
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_FAX_INVALID_FORMAT =
  'Fax # is invalid. Format is 999-999-9999 or 9999999999.';
export const PROVIDER_MAINTENANCE_NUMBERS_FORM_CELLPHONE_INVALID_FORMAT =
  'Cell Phone # is invalid. Format is 999-999-9999 or 9999999999.';
// Provider Maintenance Add Numbers Error Messages END

// Provider Maintenance Add Contact Person Error Messages
export const PROVIDER_MAINTENANCE_CONTACT_PERSON_FORM_LASTNAME =
  'Last Name is required.';
export const PROVIDER_MAINTENANCE_CONTACT_PERSON_FORM_FIRSTNAME =
  'First Name is required.';
export const PROVIDER_MAINTENANCE_CONTACT_PERSON_FORM_PHONE_EXT =
  'Phone & Extension is required.';
export const PROVIDER_MAINTENANCE_CONTACT_PERSON_FORM_EXTENSION =
  'Phone Extension is not equal to Integers.';
export const PROVIDER_MAINTENANCE_CONTACT_PERSON_FORM_PHONE_EXT_INVALID =
  'Phone & Extension is invalid. Format is 999-999-9999 or 9999999999.';
// Provider Maintenance Add Contact Person Error Messages END
export const ALTERNATE_ID = 'Alternate ID is required.';
export const INTERNAL_SYSTEM_ID = 'Target Internal System ID is required.';
export const ALTERNATE_INVALID_ID = 'Alternate ID is invalid.';
export const SAVE_UNMERGE_WITHOUT_CLAIMS =
  'Please click on Save before navigating away from this page so that the Alternate ID will be successfully transferred to the Target Member.';
export const SAVE_UNMERGE_WITH_CLAIMS =
  'Please click on Save before navigating away from this page so that TCN Specific Claims Adjustment request(s) will be generated successfully and the Alternate ID will be successfully transferred to the Target Member.';
export const ALTERNATE_IDTYPE_SAME =
  'Alternate ID cannot be transferred or added. Target already has exact same Alternate Id and Type';
export const UNMERGE_CANCEL =
  'You have just cancelled your Unmerge Alternate ID request.';
export const BGDT_INVALID =
  'Begin Date is Invalid. Date should be in MM/DD/YYYY format.';
export const EDT_INVALID =
  'End Date is Invalid. Date should be in MM/DD/YYYY format.';
export const BEGIN_DAT_EARLIER_INVALID =
  'Begin Date is invalid or earlier than 10-01-1967';
export const END_DAT_EARLIER_INVALID =
  'End Date is invalid or earlier than 10-01-1967';
export const BEGIN_DAT_EARLIER_INVALID_CARE_ENROLL =
  'Begin Date is invalid or earlier than 10-01-1967';
export const END_DAT_EARLIER_INVALID_CARE_ENROLL =
  'End Date is invalid or earlier than 10-01-1967';
export const SELECTION_DT_INVALID =
  'Selection Date is Invalid. Date should be in MM/DD/YYYY format.';
export const EFT_EDT_INVALID =
  'Effective Date is Invalid. Date should be in MM/DD/YYYY format.';
export const BGDT_GT_EDT = 'Begin date cannot be greater than the end date.';
export const BGDT_GT_EDT_DATE =
  'Please enter an end date that is greater than or equal to the begin date.';

export const ALTERNATE_DUP_ID =
  'Alternate ID cannot be transferred or added. Target already has exact same Alternate Id and Type.';

export const ALTERNATE_DUP_MEMBER_ID =
  'The Alternate ID that is currently being used as the Member ID for this member cannot be deleted.';
export const ALTERNATE_ENT =
  'An  Alternate ID Type of ENT - Enterprise cannot be deleted.';
export const ALTERNATE_ID_TYPE = 'Alt ID Src Code is required.';
export const ALTERNATE_TYPE = 'Alternate ID Type is required.';
export const ALT_ID_TYPE_CODE = 'Alt ID Type Code is required.';
export const ALTERNATE_RID_ERR =
  "Member's RID is incorrect format due to being wrong length or it contains non-numeric characters.";
export const ALTERNATE_MBI_ERR = 'MBI entered is not in valid format.';
export const SOURCE_ID = 'Source is required.';
export const SSN_ERR = 'SSN must be 9 digits.';
export const SSN_ERR_CHAR = 'SSN must be 9 characters.';
export const SSN_FORMAT_ERR = 'SSN format mismatch.';
export const INVALID_DOB = 'Invalid Date Of birth.';
export const TYPE = 'Type is required.';
export const ALTERNATE_PAYEE =
  'Alternate Payee must have a last name or an organization name; both are missing.';
export const RID_ERR =
  "Member's RID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MEMBER_ID_ERR =
  "Member's MID is incorrect format due to being the wrong length or it contains non-alphanumeric characters.";
export const MBI_MMIS_ERR = 'MBI is on MMIS for a different member';
export const MBI_ID_ERR = ' MBI entered is not in valid format.';
export const COE_ERR_655 =
  'You cannot add pregnancy regular or special eligibility without associated pregnancy demographic information.';
export const FEMALE_CANCER =
  'Breast and Cervical Cancer  COE span exists. The gender must be female';
export const HIC_ERROR =
  'The HIC number entered already exists in the MMIS.  Please correct and re-enter the number.';
export const SPEN_DT_ERR = 'Spenddown Met Date is required.';
export const SPEN_EDT_ERR = 'Spenddown End Date is required.';
export const ENDDT_OVERLAP =
  'When entering disability data online, date spans cannot overlap.';
export const PREG_WARN =
  'Due to pregnancy information added or updated, please add or update pregnancy special eligibility span';
export const SSN_EXIST =
  'The SSN you entered for this Member already exists in the system for another Member. Please enter the correct SSN for this Member or select the existing member.';
// Provider Maintenence RiskLevelAndScanning messages
export const RISK_LEVEL_AND_SCANNING_SITE_VISITED =
  'XX Site Visit Conducted is required.';
export const RISK_LEVEL_AND_SCANNING_DATE_XX_SITE =
  'Date XX Site Visit Conducted is required.';
export const RISK_LEVEL_AND_SCANNING_DATE_XX_SITE_INVALID =
  'Please enter date in the correct format.   Example MM/DD/CCYY Example MMDDCCYY.';
export const RISK_LEVEL_AND_SCANNING_XX_SITE_VISIT_PASS =
  'XX Site Visit Pass is required.';
export const RISK_LEVEL_AND_SCANNING_ASSIGNED =
  'Risk Level Assigned is required.';
export const PROVIDER_TAX_IDENTIFICATION =
  'Preference for Aggregation of Remittance Data is required.';
export const BILLING_MEDIUM = 'Billing Medium is required.';
export const RISK_LEVEL_AND_SCANNING_ASSIGNED_DATE =
  'Date Risk Level Assigned is required.';
export const RISK_LEVEL_AND_SCANNING_ASSIGNED_DATE_INVALID =
  'Please enter date in the correct format.   Example MM/DD/CCYY Example MMDDCCYY.';
export const RISK_LEVEL_AND_SCANNING_FEE_REQUIRED = 'Fee is required.';
export const RISK_LEVEL_AND_SCANNING_FEEWVDCD_REQUIRED =
  'Fee Waived is required.';
export const RISK_LEVEL_AND_SCANNING_REASON_WAIVER_FEE_REQUIRED =
  'Reason for Waiver of Fee is required.';
export const RISK_LEVEL_AND_SCANNING_DATE_XX_SITE_FUTURE =
  'Date XX Site Visit Conducted cannot be future date.';
export const RISK_LEVEL_AND_SCANNING_ASSIGNED_DATE_FUTURE =
  'Date Risk Level Assigned cannot be future date.';
export const REVERFICATION_DATE_REQUIRED = 'Reverification date is required.';
export const REVERFICATION_DATE_INVALID = 'Reverification date is invalid.';
export const REVERFICATION_GREATER =
  'Reverification Date is less than Approval Date plus reverification period';
// Provider Maintenence RiskLevelAndScanning messages
export const PROVIDER_NETWORK_ID_ERR = 'Network ID-Name is required.';
export const PROVIDER_NETWORK_Bgndt_GT_Enddt_Err =
  'Network begin date cannot be prior to enrollment effective date.';
export const FIRSTNAME_MEMBER = 'The member first name cannot be blank';
export const MIDDLENAME_INVALID_Err = 'Member middle initial invalid';
export const PROVIDER_BANKRUPTCY_ENDDATE_ERR =
  'Please enter an end date that is greater than or equal to the begin date.';
export const PROVIDER_BEGIN_DATE =
  'Authorized Representative Begin Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy format only.';
export const PROVIDER_END_DATE =
  'Authorized Representative End Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy format only.';
export const PROVIDER_SERVICE_BILLING_MAILLING_REQ =
  'Provider must have active Service, Billing and Mailing Addresses';

export const PROVIDER_BANKRUPTCY_ENDDATE_ERROR =
  'Bankruptcy Begin Date must be less than or equal to Bankruptcy End Date.';
export const PROVIDER_DESC_ERR = 'Event Description is required.';
export const PROVIDER_DESC_BR_ERR =
  'Event is out of sequence and cannot be added';
export const PROVIDER_DATE_ERR = 'Date is required.';
export const PROVIDER_REASON_ERR = 'Event Reason is required.';
export const PROVIDER_OVERLAP_ERR = 'Duplicate event code.';
export const PROVIDER_SEQUENCE_ERR =
  'Event is out of sequence and cannot be added.';

/** Provider Maintance Medicare */
export const MEDICARE_REQ_ERROR = 'Medicare # is required.';
export const MEDICARE_INVALID_ERROR =
  'Medicare Number is invalid. It should be alphanumeric only.';
export const MEDICARE_PROGRAM_REQ_ERROR =
  'Please enter at least one corresponding Part when adding Medicare information.';
export const MEDICARE_BEGINDATE_REQ_ERROR = 'Begin Date is required.';
export const MEDICARE_ENDDATE_REQ_ERROR = 'End Date is required.';
export const MEDICARE_BEGINDATE_INVALID_ERROR =
  'Medicare Begin Date is invalid.';
export const MEDICARE_ENDDATE_INVALID_ERROR = 'Medicare End Date is invalid.';
export const MEDICARE_ENDDATE_GREATER_ERROR =
  'The Medicare End Date must be greater than Medicare Begin date.';

/**---------- General Info MSG ------------- */

export const FACILITY_TYPE_REQ = 'Facility Type is required.';
export const FACILITY_BEGINDATE_REQ = 'Begin Date is required.';
export const FACILITY_ENDDATE_REQ = 'End Date is required.';
export const REASONDATE_REQ = 'Reason Date is required.';
export const REASONDATE_INVALID = 'Reason Date is invalid.';
export const REASONDATE_DUPLICATE =
  'Duplicate values cannot be added to the Accepting New patients section.';
export const FACILITY_OVERLAPP_DATE_ERR =
  'Overlapping dates not allowed for Facility.';
export const FACILITY_ADMIN_NAME_INVALID = 'Facility Admin Name is invalid.';
export const FACILITY_BEGINDATE_INVALID = 'Begin Date is invalid.';
export const FACILITY_ENDDATE_INVALID = 'End Date is invalid.';
export const DISPRO_BEGINDATE_REQ = 'Begin Date is required. ';
export const DISPRO_ENDDATE_REQ = 'End Date is required.';
export const DISPRO_DATE_EQUAL_ERR =
  'Disproportionate End Date should be greater than Begin Date.';
export const DISPRO_DUPLICATE_ERR =
  'Duplicate values cannot be added to the Disproportionate Share section.';
export const DISPRO_BEGINDATE_INVALID = 'Begin Date that was entered is invalid.';
export const DISPRO_ENDDATE_INVALID = 'The End Date that was entered is invalid.';
export const DISPRO_OVERLAPP_DATE_ERR =
  'Overlapping dates not allowed for Disp Share Hosp.';
export const HOURS_DAY_OF_WORK_REQ = 'Day of Week is required.';
export const HOURS_OPEN_TIME_REQ = 'Open is required.';
export const HOURS_CLOSE_TIME_REQ = 'Close is required.';
export const HOURS_DAYS_OF_WORK_REQ =
  'There is a Limit of 1 entry of time per day.';
export const INTERPRETIVE_SERVICE_REQ = 'Interpretive Services is required.';
export const INTERPRETIVE_DUPLICATE_ERR =
  'Duplicate values cannot be added to the Interpretive Service section.';
export const ACCEPTIVE_REASON_REQ = 'Reason is required.';
export const UPDATE_SUCCESS = 'System successfully updated the information.';

//--------------------------- Provider ownership error msg start-------------------------------------
export const PROVIDER_OWN_LAST_NAME_RQRD = 'Last Name is required.';
export const PROVIDER_OWN_LAST_NAME_INV = 'Last Name is invalid.';
export const PROVIDER_OWN_FIRST_NAME_RQRD = 'First Name is required.';
export const PROVIDER_OWN_FIRST_NAME_INV = 'First Name is invalid.';
export const PROVIDER_OWN_DOB_RQRD = 'Date of Birth is required.';
export const PROVIDER_OWN_DOB_INV = 'Date of Birth is invalid.';
export const PROVIDER_OWN_DOB_LESS =
  'Date of Birth must be lesser than the present date in the Employee Section.';
export const PROVIDER_OWN_DOB_MORE =
  'The Employees age should be between 18 and 100.';
export const PROVIDER_OWN_SSN_RQRD = 'SSN/FEIN is required.';
export const PROVIDER_OWN_SSN_INV =
  'The Social Security Number must be 9 digits.';
export const PROVIDER_OWN_ADDRESS_RQRD = 'Address is required.';
export const PROVIDER_OWN_CITY_RQRD = 'City is required.';
export const PROVIDER_OWN_STATE_RQRD = 'State is required.';
export const PROVIDER_OWN_ZIP_RQRD = 'Zip is required.';
export const PROVIDER_OWN_ZIP_INV = 'Zip is invalid.';
export const PROVIDER_OWN_EXTENSION_INV = 'Extension is invalid.';
export const PROVIDER_OWN_BUSSINESS_NAME_RQRD = 'Business Name is required.';
export const PROVIDER_OWN_BEGIN_END_DATE_OVERLAP =
  'Overlapping dates not allowed for Managing/Directing Employee.';
export const PROVIDER_OWN_EFFECTIVE_DATE_INV =
  'Ownership Provider Effective Date is invalid. It should be in mm/dd/yyyy format only.';
export const PROVIDER_OWN_END_DATE_INV =
  'Ownership Provider End Date is invalid. It should be in mm/dd/yyyy format only.';
export const PROVIDER_OWN_SSN_DIGIT_9 =
  'Employee SSN/FIEN is invalid. It should be in format of xxx-xxx-xxxx or xxxxxxxxxx only';
export const PROVIDER_OWN_ENDDATE_GREATER =
  'Please enter an end date that is greater than or equal to the begin date.';
//--------------------------- Provider ownership error msg end-------------------------------------

export const PROVIDER_DATE_OVERLAP_ERROR =
  'Overlapping dates not allowed for Authorized Reps.';

export const REVIEW_TYPE_ERROR = 'Review Type is required.';

export const LOCKIN_TYPE_ERROR = 'Lock In Type is required.';

export const REVIEW_REASON_ERROR = 'Review Reason is required.';

export const DATE_INDICATOR_ERROR = 'Date Indicator is required.';

export const EXCEPTION_CODE_ERROR = 'Exception Code is required.';

export const EXCEPTION_CODE_SERVICE_ERROR =
  'Exception Code cannot be found on the Claim Exception Control File.';

export const PROVIDER_ID_ERROR =
  'Provider ID and Type entered cannot be found.';

export const REVIEW_PROVIDER_ERROR =
  'You must enter a provider ID and Provider ID Type for a Lock-in or Lock-out.';

export const REVIEW_OVERLAP_ERROR =
  'Member on Review span already exists for this member.';
export const DIS_TYPE_REQ = 'Disability Type is required.';
export const DIS_DTOVER_ERR =
  'Entered Disability span dates for the same Member ID and Disability Code overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Disability span.';
export const REVIEW_LOCATION_ERROR = 'Location Code entered is not found.';

export const MAP_ID_ERROR = 'The Map ID entered cannot be found.';

export const BEGIN_DATE_GREATER_ERROR =
  "Begin date cannot be earlier than Member's Date of Birth Month and Year.";

export const BEGIN_DATE_SPAN_ERROR =
  "Span begin date after Member's Date of Death.";

export const PROVIDER_ID_INACTIVE_ERROR = 'Provider is not active.';

export const END_DATE_SPAN_ERROR =
  "Span end date is after Member's Date of Death.";

export const REVIEW_TYPE_LKI_ERROR =
  'You can not enter a Lock-Out for this Provider ID because a Lock-In span already exists.';
export const ENDDATE_GREATERTHAN_DOD =
  "Span end date is after Member's Date of Death.";
export const REVIEW_TYPE_LKO_ERROR =
  'You can not enter a Lock-In for this Provider ID because a Lock-Out span already exists.';
// Member Maintenance add update LTC and PLD Messages
export const MEMBER_MAINTENANCE_PLD_FORM_BEGIN_DATE = 'Begin Date is required.';
export const MEMBER_MAINTENANCE_PLD_FORM_END_DATE = 'End Date is required.';
export const MEMBER_MAINTENANCE_PLD_FORM_LIABILITYAMOUNT =
  'Liability Amount is required.';
export const MEMBER_MAINTENANCE_PLD_FORM_LIABILITYTYPECODE =
  'Liability Type Code is required.';
export const MEMBER_MAINTENANCE_BEGINDATE_GRETER_ENDDATE =
  'Begin date cannot be greater than the end date.';
export const MEMBER_MAINTENANCE_PLD_FORM_LIABILITY_INVALID = 'Invalid data.';
export const MEMBER_MAINTENANCE_LTC_FORM_PROVIDERID =
  'Provider ID is required.';
export const MEMBER_MAINTENANCE_LTC_FORM_PROVIDERIDTYPE =
  'Provider ID Type is required.';
export const MEMBER_MAINTENANCE_LTC_FORM_LEVELOFCARE =
  'Level of Care Required when Entering a Nursing Facility Care Span.';
export const MEMBER_MAINTENANCE_LTC_FORM_LTCSPANTYPECODE =
  'Long Term Care Span Type is required.';
export const MEMBER_MAINTENANCE_LTC_FORM_DEATH =
  "Span end date is after Member's Date of Death.";
export const MEMBER_MAINTENANCE_LTC_FORM_DEATH_BEGIN =
  "Span begin date after Member's Date of Death.";
export const MEMBER_MAINTENANCE_LTC_FORM_SPAN =
  'Selected LTC Provider is not active for time span entered.';
export const MEMBER_MAINTENANCE_LTC_FORM_ILLEGAL_XXH =
  'Illegal Overlap-XXH spans cannot overlap.';
export const MEMBER_MAINTENANCE_LTC_FORM_ILLEGAL_NHH =
  'Illegal Overlap-NHH spans cannot overlap.';
export const MEMBER_MAINTENANCE_LTC_FORM_ILLEGAL_SNF =
  'Illegal Overlap-SNF spans cannot overlap.';
export const MEMBER_MAINTENANCE_LTC_FORM_BEGIN_EARLIER_MEMBER =
  "Begin date cannot be earlier than Member's Date of Birth Month and Year";
export const MEMBER_MAINTENANCE_LTC_FORM_INVALID_PROV_ID =
  'The combination of Provider ID and Provider Type for the LTC Provider entered cannot be found.';
export const MEMBER_MAINTENANCE_LTC_FORM_PROV_ID_MUST =
  'The Provider Type of the Provider ID entered must be a Nursing Facility';
export const MEMBER_MAINTENANCE_LTC_FORM_PATIENT_SPAN =
  'Patient liability span being added cannot overlap with an existing patient liability span of the same type';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP =
  'LTC transaction overlaps with an existing LTC span.';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_1 =
  'Illegal Overlap-Incoming LTC span must be fully contained within an existing MA span';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_2 =
  'Illegal Overlap-Incoming LTC span cannot overlap existing SLM1 span';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_3 =
  'Illegal Overlap-Incoming LTC span cannot overlap existing HCBC span by more than 30 days.';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_4 =
  'Illegal Overlap-Incoming SNF LTC span cannot overlap existing MGIA/MGIM';

export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_5 =
  'Illegal Overlap-Incoming LTC span cannot overlap existing Healthy Kids Silver span.';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_6 =
  'Illegal Overlap-Incoming LTC span must be fully contained within an existing MA or QMB span';
export const MEMBER_MAINTENANCE_LTC_FORM_LTC_OVERLAP_7 =
  'Illegal Overlap-Incoming LTC span cannot overlap existing manually entered Special Eligibility';

export const MEMBER_MAINTENANCE_PLD_FORM_NO_NURSING_SPAN =
  'No Nursing Facility span found for Nursing Facility Patient Liability span.';
export const MEMBER_MAINTENANCE_PLD_FORM_SNF_MEDICARE =
  'No SNF Medicare span found for SNF Patient Liability span';
export const MEMBER_MAINTENANCE_PLD_FORM_NFC_OVERLAP =
  'Nursing Facility Patient Liability spans cannot overlap';
export const MEMBER_MAINTENANCE_PLD_FORM_SNF_OVERLAP =
  'SNF Medicare Patient Liability spans cannot overlap';
export const MEMBER_MAINTENANCE_PLD_FORM_BIRTH_MMBER =
  "Begin date cannot be earlier than Member's Date of Birth Month and Year";
// -------Special Eligibility / Cost Share Liability Spans Form Start--------
export const SPECIAL_ELIGIBILITY_CODE_IS_REQUIRED =
  'Special Eligibility Code is required.';
export const MEMBER_ID_IS_A_REQUIRED = 'The Member ID is a required field.';
export const HCBC_REVIEW_DATE_IS_INVALID =
  'HCBC Review Date is Invalid. Date should be in MM/DD/YYYY format.';
export const MEMBER_ID_TYPE_IS_REQUIRED = 'Member ID Type is required.';
export const ONLY_NUMERIC_VALUES_ARE_ACCEPTED =
  'Only Numeric values are accepted.';
export const MEMBER_LIABILITY_TYPE_CODE_REQUIRED =
  'Liability Type Code is required.';

export const MEMBER_LIABILITY_AMOUNT_INVALID = 'Liability Amount is invalid.';
export const MEMEBER_LIABILITY_AMOUNT_PERCENT_REQUIRED = 'Either Amount or Percent is required.';
export const MEMBER_SPECIAL_ELIGIBILITY_CODE_IS_REQUIRED =
  'Special Eligibility Code is required.';
export const MEMBER_ELIGIBILITY_CASE_NO_REQ = 'Case Number is required.';
export const MEMBER_LIABILITY_AMT_DEC_ERR =
  'Liability Amount can have only 7 characters before decimal and 2 characters after decimal.';
export const MEMBER_LIABILITY_PERCENT_DEC_ERR =
  'Liability Percent can have only 5 characters before decimal and 2 characters after decimal.';
export const MEMBER_LIABILITY_PERCENT_INVALID = 'Liability Percent is invalid.';

// -------Special Eligibility / Cost Share Liability Spans Form End--------
//--------------------------- Provider incentive sanction review start-------------------------------------
export const PROVIDER_REVIEW_TYPE = 'Review Type is required.';
export const PROVIDER_REVIEW_REASON = 'Review Reason is required.';
export const PROVIDER_REVIEW_DATE_INDICATOR = 'Date Indicator is required.';
export const PROVIDER_REVIEW_EXCEPTION_CODE = 'Exception Code is required.';
export const PROVIDER_REVIEW_DATE_OVERLAP_ERROR =
  'Overlapping dates not allowed for Begin Date.';
export const PROVIDER_REVIEW_EXCEPTION_CODE_SERVICE_ERROR =
  'Exception Code is invalid.';

export const BUSINESS_PLAN_ID = 'Business plan Id is required.';
export const ASSIGNED_REASON = 'Assigned Reason is required.';
//----------------bedData Errors-----------

export const BEDDATA_BR_FACILITY_ERROR =
  'Total Facility Beds is required for this provider type.';
export const BEDDATA_BR_ACUTE_ERROR =
  'Total Acute Beds is required for Hospital providers.';
export const BEDDATA_BR_PSYCH_ERROR =
  'Total Psych Beds is required for Psych DPU.';
export const BEDDATA_BR_REHAB_ERROR =
  'Total Rehab Beds is required for Rehab DPU.';
export const BEDDATA_BR_DUALLY_ERROR = 'Total Dually Beds is required.';
export const BEDDATA_BR_SWING_ERROR = 'Total Swing Beds is required.';
export const BEDDATA_BR_NHXIX_ERROR =
  'Total NH Title XIX Beds is required for Nursing Facilities.';
export const BEDDATA_NUMERICAL_ERROR = 'Bed data must be a positive number.';
export const BEDDATA_EXCEED_ERROR =
  'Total Number of Facility Beds is less than the sum of all other beds entered.';
export const PROVIDER_REVIEW_MAP_ID_SERVICE_ERROR = 'Map ID does not exist.';

// -------------------------------- Provider Speciality -----------------------------------------//
export const PROVIDER_SPECIALITY =
  'Please select at least one Provider type and Specialty combination.';

export const PROVIDER_TYPE_ERROR = 'Provider Type is Required.';
export const SPECIALTY_ERROR = 'Specialty is Required.';
export const PRV_SPL_TAX_WILD_CARD_SEARCH =
  'A minimum of 2 characters must be entered for a starts with search.';
//-----------------------------------------------------------------------------------------------
export const PROVIDER_REVIEW_LOCATION_ID_SERVICE_ERROR =
  'Review location UID is invalid.';
// -----------------------------EVV---------------------------------------------------//
export const PRC_CD_WILD_SEARCH =
  'A minimum of 2 characters of Procedure code must be entered for a starts with search.';
export const RVN_CD_WILD_SEARCH =
  'A minimum of 2 characters of Revenue code must be entered for a starts with search.';

// --------------------------------- ENROLLMENT SPANS ---------------------------------//
export const BP_PLAN_ID = 'Benefit Plan ID is required.';
export const BPLAN_ID = 'Plan ID is required.';
export const BP_ASSIGN_REASON = 'Assign Reason is required.';
export const BP_CLOUSER_REASON =
  'Closure Reason Code is required for ended span.';
export const BP_END_DT_CLOUSER_REASON =
  'End Date for this span is required when a Closure Reason Code is entered.';
export const BP_OVERLAP_ERROR =
  'Incoming CM Enrollment span dates may not overlap existing CM Enrollment span dates.';
export const BP_INDEX_OVERLAP_ERROR =
  'IMDIP or IMDEX benefit plan being added cannot overlap with an existing benefit plan of the same type.';
export const CM_STATUS_BEGIN_DATE = 'Status Begin Date is required.';
export const CM_STATUS = 'Status is required.';
export const CM_STATUS_REASON = 'Status Reason is required.';
export const CM_STATUS_TYPE = 'Status Type is required.';
export const CM_STATUS_BE_BR_PROGRAM =
  'MC Status Begin Date is prior to the MC Program Start Date of 04/01/2013.';
export const CM_STATUS_BE_BR_ELIGIBILITY =
  'MC Status Begin and End dates do not fall within existing eligibility spans` Begin and End dates.';
export const CM_ENROLLMENT_BE_BR_ELIGIBILITY =
  'MC Enrollment Begin and End dates do not fall within existing eligibility spans` Begin and End dates';
export const CM_STATUS_90_DAYS =
  'Begin Date is more than 3 months in the future.';
export const CM_STATUS_OVERLAP =
  'Incoming CM Status span dates may not overlap existing CM Status span dates.';
export const CM_STATUS_OPT_IND =
  'The Opt Out Indicator is required when the CM Status is Voluntary.';
export const ENROLEMENT_BEGIN_DATE =
  'Enrollment Begin and End dates do not fall between the selected Benefit Plan Begin and End Dates.';
export const ENROLEMENT_greater_DATE =
  'Enrollment Begin Date is not greater than or equal to the Eligibility Begin Date and Plan Begin Date.';
export const ENROLEMENT_BIRTHDAY =
  'Member must be under 21 to be assigned FFDWVR BP.';
export const ENROLEMENT_INDEX =
  'IMDEX Enrollment Begin and End dates do not fall within existing NHH spans Begin and End dates.';
export const ENROLEMENT_IMDIP =
  'IMDIP Enrollment Begin and End dates do not fall within existing NHH spans Begin and End dates.';
//--------------------------- Provider AffiliationsAnd service start-------------------------------------
//export const PROVIDERID_NOT_VALID = 'Provider ID is not valid.';
export const PROVIDER_AFFILIATIONS_PROVIDERID = 'Provider ID is required.';
export const PROVIDER_AFFILIATIONS_NAME = 'Provider Name is required.';
export const PROVIDER_AFFILIATIONS_TYPE = 'Affiliation Type is required.';
export const PROVIDER_AFFILIATIONS_PROVIDERID_INVALID =
  'Provider ID is invalid';
export const PROVIDER_AFFILIATIONS_CURRALT_PRID =
  'Please verify the Provider ID entered. You have entered your own Provider ID.';
export const PROVIDER_AFFILIATIONS_PROVIDERID_VALIDATE =
  'Provider Affiliation ID does not exist.';
export const ACCESS_DENIED = 'Either you don\'t have permission to access this page or this page does not exist.';
export const REQUEST_PIN_SUCCESS =
  'Your New PIN has been created and will be mailed to you.';
export const REQUEST_PIN_FAILURE = 'Pin Generation was not successful.';
export const ENROLLMENT_TRIGGER =
  'Member Enrollment processing has been triggered.';
export const VOID_TRIGGER =
  'A Claims Adjustment Request has been created for the span that was just voided.';
export const ADD_TRIGGER =
  'Claims Mass Adjustment Request(s) Generated Successfully.';
export const AFTER_HOURS_CONTACT_ERROR =
  'After Hours Contact # is invalid. Format is 999-999-9999 or 9999999999.';
export const TTD_TTY_CONTACT_ERROR =
  'TDD/TTY # is invalid. Format is 999-999-9999 or 9999999999.';

// --------------------------------------------Care Management Enrollment--------------------------------------------//
export const CM_ENROLL_BEGIN_DATE = 'Enroll Begin Date is required.';
export const KICK_TCN = 'Tcn is required.';
export const CM_MCO_TP_ID = 'MCO TP ID / PAP ID is required.';
export const CM_TERMINATION_REASON =
  'Change / Termination Reason Code is required for an ended span.';
export const CM_BEGIN_DATE_INVALID =
  'Begin Date is more than 3 months in the future';
export const CM_SELECTION_CODE_INVALID =
  'MCO Selection Code is required for an MCO enrollment.';

// --------------------------------------------Premium ASSISTANCE--------------------------------------------//
export const PAP_BENIFIT_PLAN = 'PAP Benefit Plan  is required.';
export const MEMBER_AGE = 'Member Age is required.';
export const TOBOCCO_IND = 'Tobacco Use Ind is required.';
export const CALENDER_YEAR = 'Calendar Year is required.';
export const CALENDER_DIGITS = 'Calendar Year must be 4 digits.';
export const EVAL_DATE = 'Evaluation Date is required.';
export const MEMBER_AGE_INVALID = 'Member Age is not valid.';
export const CALENDER_YEAR_INVALID = 'Calendar Year is not valid.';
export const EVAL_YEAR_INVALID =
  'Evaluation Date is Invalid. Date should be in MM/DD/YYYY format.';
export const PROVIDER_SPECIALTY_DUPLICATE_ERROR =
  'Provider Type Specialty combination already exists.';
export const PROVIDER_SUGG_ADDRESS_MSG =
  'Addresses are checked for proper postal format. Select one of the standardized addresses for efficient delivery.';
export const AGE_RANGE_SERVED = 'Age Range Served is required.';

// ---------------------------- ADDITIONAL MEMBER INFO --------------------------//
export const ADDITIONAL_MEMBER_LNAME = 'The member last name cannot be blank';
export const ADDITIONAL_MEMBER_FNAME = 'The member first name cannot be blank';
export const ADDITIONAL_MEMBER_DOB = 'Date of Birth is required.';
export const MEMBER_DOB_REQUIRED = 'DOB After Special Eligibility Begin Date';
export const ADDITIONAL_MEMBER_DOB_INVALID =
  'Member date of birth is not a valid date.';
export const ADDITIONAL_MEMBER_DOD_INVALID =
  'Date of Death is invalid. Date should be in mm/dd/yyyy format.';
export const ADDITIONAL_MEMBER_GENDER = 'Gender is required.';
export const ADDITIONAL_MEMBER_LIVINGARR = 'Living Arrangement is required.';
export const INVALID_DATE_ERR =
  'Date is invalid. Date should be in MM/DD/YYYY format.';
export const OWN_MEDICAID_ID =
  'Please verify the Medicaid ID entered. You have entered your own Medicaid ID.';
export const AG_NUMBER = 'AG Number is required.';
export const Category_of_Eligibility = 'Category of Eligibility is required.';
export const Case_Number_Error = 'Case number must be 9 digits.';
export const CERTIFICATION_ENDLBGN_DATE_ERR =
  'Please enter a certification effective date that is less than the certification expiration date.';
export const LICENSE_ENDLBGN_DATE_ERR =
  'Please enter a license effective date that is less than the license expiration date.';
export const MCARE_ERR =
  'Illegal Overlap-Incoming MCO BP cannot overlap existing MCO BP span.';
export const PROVIDER_SUGG_SERVICE_ADDRESS_MSG =
  'P.O. box is not allowed for Service location address type';
export const LICENSURE_REQ = 'License # is required.';
export const CERTIFICATION_REQ = 'Certification # is required.';
export const BENIFIT_INVALID_LMT_OVR_EXC_CODE_COPAY =
  'Limit Over Exception Code is invalid. ';
export const Invalid_Deactivate_Date_Error =
  'Deactivation Date is invalid. Date should be in mm/dd/yyyy format.';
export const ADDITIONAL_MEMBER_LANGUAGE = 'Primary Language is required.';
export const ADDITIONAL_MEMBER_CITIZENSHIP = 'Citizenship Status is required.';
export const ADDITIONAL_MEMBER_DOBFUTURE = 'Date of Birth future date.';
export const ADDITIONAL_MEMBER_DODFUTURE =
  'Member date of death is a future date or not a valid date.';
export const ADDITIONAL_MEMBER_SSNERR =
  'SSN Number cannot be set to blanks. Please update via Alternate ID Maintenance link.';
export const ADDITIONAL_MEMBER_DOD_EARLIER =
  'Date of Death, Pregnancy Due Date or Term Date earlier than 10/1/1967 (Medicaid Inception Date)';
export const ADDITIONAL_MEMBER_DODGTDOB =
  'Date of Death earlier than Date of Birth';
export const ADDITIONAL_MEMBER_SSNSOURCE =
  'SSN Source is required when Social Security Number is entered.';
export const ADDITIONAL_MEMBER_HICSOURCE =
  'HIC Source is required when HIC Number is entered.';
export const ADDITIONAL_MEMBER_SSA =
  'SSA Disability Benefit Entitlement Date is invalid. Date should be in mm/dd/yyyy format.';
export const ADDITIONAL_MEMBER_INCARCERATION =
  'Incarceration Release Date is not a valid date.';
export const ADDITIONAL_MEMBER_DOD_SOURCE_REQ =
  'Date of Death Source is required if Date of Death is entered.';
export const ADDITIONAL_MEMBER_FINANCIALDT = 'Date must be in format MMCCYY.';
export const TOBACCO_CODE_USE = 'Tobacco Use Code is required.';
export const MEMBER_ID_SUS_DUP = 'Member ID is required.';
export const MEMBER_ID_SUS_DUP_TYPE = 'Member ID Type is required.';
export const DUP_REASON_CODE_SUS_DUPE = 'Duplicate Reason Code is required.';
export const SOURCE_TARGET_ID_SAME =
  'Entered Source ID and Target ID cannot be the same.';
export const TOBACCO_CODE_USE_OVERLAP =
  'Entered Tobacco Use Codespan dates for the same Member ID and Tobacco Use Code overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the Tobacco Use Code span.';
export const SUSPECT_DUP_ID = 'Suspect Duplicate ID is required.';
export const SUSPECT_DUP_ID_TYPE = 'ID Type is required.';
export const LLC_ERR = 'LLC is required';
export const SUSPECT_DUP_REASON_CODE = 'Suspect Dup Reason Code is required.';
export const SUSPECT_DUP_TRANS_CODE =
  'Suspect Dup Transaction Code is required.';
export const PREGNANCY_MALE_CHILD =
  'Attempted to add pregnancy information for a Male Member.';
export const PREGNANCY_CHILD_NUMBER =
  'Number of Children is required when Due Date is entered.';
export const PREGNANCY_DUE_DATE_INVALID =
  'Pregnancy due date is not a valid date.';
export const PREGNANCY_DUE_DATE_BEFORE =
  'Date of Death, Pregnancy Due Date or Term Date earlier than 10/1/1967 (Medicaid Inception Date)';
export const NEWLY_CODES = 'HPP Newly / Not Newly code is required';
export const NEWLY_DIFF_CODES_OVERLAP =
  'When entering CMS64-Newly/Not Newly online, date spans cannot overlap.';
export const NEWLY_CODES_OVERLAP =
  'Entered CMS64-Newly/Not Newly span dates for the same Member ID and CMS64-Newly/Not Newly codes overlap with an existing span in the system. Please verify entered dates and if correct, either void or end-date the existing overlapping span and reenter the CMS64-Newly/Not Newly span.';
export const CLIA_ERR_EMPTY =
  'A valid CLIA ID is required for this provider type.';
export const MEMBER_PROV_INVALID =
  'The combination of Provider ID and Provider Type for the Provider entered cannot be found.';
export const MEMBER_PROV_ACTIVE_CHECK =
  'Selected Provider is not active for time span entered.';
export const NOT_P =
  'The selected 834 Retrigger Request cannot be deleted because its Status is not Pending';
export const TPL_INVALID_DATE_ERROR =
  'Last TPL Audit Date is invalid. It should be in format mm/dd/yyyy or mm-dd-yyyy or mmddyyyy format only.';
export const REQUEST_NEW_PIN =
  'Confirm that you are requesting a new PIN for this member by clicking on the OK button. To exit this function without assigning a new PIN click on the Cancel below.';
export const RESET_PIN_STATUS =
  'Confirm that you are requesting to reset this member`s PIN status by clicking on the OK button. To exit this function without restting the member`s PIN status, click on the Cancel below.';
export const SPENDDOWN_AMOUNT_MET_INVALID =
  'Spenddown Met Date is Invalid. Date should be in MM/DD/YYYY format.';
export const SPENDDOWN_AMOUNT_END_INVALID =
  'Spenddown End Date is Invalid. Date should be in MM/DD/YYYY format.';
export const SPENDDOWN_NOT_ALLOWED =
  'Spenddown not allowed for COE Code entered.';
export const SPENDDOWN_AMOUNT = 'Spenddown First Day Amount is required.';
export const SPAN_TERMINATED =
  'Span terminated. Closure reason must be entered.';
export const END_SPAN_REQ =
  'End Date for this span is required when Closure Reason Code is entered.';
export const HIC_REQ = 'HIC Number is required when HIC Source is entered.';
export const SSN_REQ =
  'Social Security Number is required when SSN Source is entered.';
export const DOD_REQ =
  'Date of Death is required if Date of Death Source is entered.';
export const SUS_DUP_INVALID_NUM =
  'The combination of Member ID and Member ID Type cannot be found.';
export const SUS_DUP_OVERLAP = 'Suspect Duplicate Already exists.';
export const HIC_INVALID =
  'HIC Number cannot be set to blanks.  Please update via Alternate ID Maintenance link.';
export const TAXONOMY_DUPLICATE_DATE =
  'Overlapping dates not allowed for Taxonomy.';
export const DOD_VITAL_CHECK =
  'Date of Death cannot be updated after it has been verified by Vital Records.';
export const BEGIN_DT_REQ = 'Begin Date is required.';
export const CMS64 = 'CMS64 Sum Lime is required.';
export const END_DT_REQ = 'End Date is required.';
export const FINANCE_RC_REQ = 'Finance Reason Code is required.';
export const FRC_DESC_REQ = 'Finance Reason Code Description is required.';
export const FRC_GROUP_REQ = 'Finance Reason Code Group is required.';
export const FRC_CD_TY_REQ = 'Finance Reason Code Type is required.';
export const PN_REQ = 'Priority Number is required.';
export const INVALID_FORMAT = 'Please enter the data in correct format.';
export const INVALID_DATE = 'Please Enter the date in correct format.';
export const END_DATE_GREATER_THAN_BG_DT =
  'The End Date must be greater than or equal to the Begin Date.';
export const PROVIDER_AFFILIATION_DATE_OVERLAP_ERROR =
  'Overlapping dates not allowed for Affiliation.';
export const MEMBER_DOD_WARNING =
  'Warning- The DOD just entered is greater than the previous existing DOD.  Please verify all eligibility, enrollment, nursing facility, patient liability and on review spans to ensure that they have the correct End Dates.';
export const MEMBER_NAME_WARNING =
  'Warning- The Member whose name was just updated is also the Case Head.  The Case Head information has also been updated by the system.';
export const MEMBER_DOD_GREATER_WARNING =
  'Incoming DOD is greater than existing DOD.  Please review and retrigger member if appropriate.';
export const MEMBER_DOB_UNABLE = 'Unable to update Member DOB';
export const MEMBER_SSN_UNABLE = 'Unable to update Member SSN';
export const COST_TO_CHARGE_ERR = 'Cost to Charge Ratio is required.';
export const OUTLIER_PERCENTAGE_ERR = 'Outlier Percentage is required.';
export const CAPITAL_ERR = 'Capital is required.';
export const GRAD_MEDICAL_ERR = 'Grad Medical Edu. is required.';
export const SEMIPVT_RATE_ERR = 'SemiPvt Rm Rate is required.';
export const PASS_THRU_ERR = 'Pass Thru Amount is required.';
export const PREVIOUS_MEDICAID_TAX =
  'Previous Medicaid Tax Id is invalid.It should be in format XX-XXXXXXX or XXXXXXX only.';
export const NEWBORN_TRIGGER =
  'Date of birth change will make member ineligible for the New-born Program Codes currently assigned.';

export const BASE_RATE_CHANGE_CODE_ERR = 'Base Rate Change Code is required.';
export const BASE_RATE_CHANGE_DESC_ERR =
  'Base Rate Change Description is required.';
export const BASE_RATE_PERCENT_ERR = 'Percent is required.';
export const BASE_RATE_RANK_ERR = 'Rank is required.';
export const BASE_RATE_MAPSETID_ERR = 'Mapset ID is required.';
export const BASE_RATE_PERCENTAGE_FORMAT =
  'Percentage should be in the format #.##';
export const DUPLICATE_BASE_RATE =
  'Unable to save. A duplicate record exists in the system.';
export const SERVICE_CODE_TYPE_ERR = 'Service Code Type is required.';
export const SUSPECT_DUP_DELETE =
  'The Suspect Duplicate relationship(s) have been successfully deleted.';
export const SUSPECT_SELECT_ONE =
  'Please select at least one suspect duplicate record to continue.';
export const SUSPECT_CANCELLED = 'You have just cancelled your Merge request.';
export const BATCH_SUSPECT_MERGE_COMPLETED = 'The Merge process completed successfully. Please click on Save before navigating away from this page.'
export const SUSPECT_MERGE_SAVE =
  'The Merge process will be completed as a Batch job per your request.';
export const PRICING_MODULE_REQUIRED = 'Pricing Module is required.';
export const PROVIDER_BRC_DUPLICATE = "A Provider BRC for the header criteria already exists. Duplicates BRC are not allowed.";
export const LKI_DUPLICY_ERROR = 'Member Lock in span already exists for this member.'

export const EAPG_CODE_REQ = 'EAPG Code is required.';
export const EAPG_DESC_REQ = 'EAPG Description is required.';
export const EAPG_TYP_REQ = 'EAPG Type is required.';
export const EAPG_CAT_REQ = 'EAPG Category is required.';
export const EAPG_SVC_REQ = 'EAPG Service Code is required.';
export const EAPG_WEI_REQ = 'EAPG Weight is required.';
export const LOCKIN_REQ_ERR = 'Lock In Reason is required.';