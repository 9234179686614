import React from 'react';
import '../../App.scss'


function ProfileIcon(props){
  const {userData}=props
  const userName = userData?.firstName && userData?.lastName ? (userData?.firstName + ' ' + userData?.lastName) : ''
  const logo = userData?.lastName?.charAt(0) + userData?.firstName?.charAt(0) 

return (

 userName &&   <div className='profileIcon'>
  
        <span>{logo ? logo : null}</span>

    </div>

)
}

export default ProfileIcon;