import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableComponent from '../Table/Table';
import Bootstrap, { Button, Form } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { generateUUID, validateDateMinimumValue, compareTwoDatesGreaterThanOrEqual, getUTCTimeStamp } from '../DateUtilities/DateUtilities';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getLoginUserDetails } from "../utility/utilityFunction";
import SuccessComponent from "../Errors/TimeOutSuccessMsg";
import ErrorComponent from "../Errors/TimeOutErrorMsg";
import { NOTES_ADD,NOTES_NO_REC } from "../Messages/ErrorMsgConstants";
import NoteAddForm from './NoteAddForm';
import NoteFilterForm from "./NoteFilterForm";
//import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ReactToPrint from "react-to-print";
import { setPrintLayout } from "../Dropdowns/actions";
import { parseDate,parseDateToMMDDYYYY, newDate } from "../DateUtilities/DateUtilities";
import * as ErrMsgConstants from '../Messages/ErrorMsgConstants';
// import { Dialog,DialogActions,DialogContent,DialogContentText } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useImperativeHandle } from 'react';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    height: {
        maxHeight: 'none',
    },
    txtfullwidth: {
        width: '100%',
    }
}));
const headCells = [
    { id: 'strBeginDate', numeric: false, enableHyperLink: true, disablePadding: true, label: 'Date/Time', enableHyperLink: true, width: '75px' },
    { id: 'userId', numeric: false, disablePadding: false, label: 'User ID', width: '75px' },
    { id: 'noteText', numeric: false, disablePadding: false, label: 'Note', isText: true, width: '200px' },
    { id: 'usageTypeDesc', numeric: false, disablePadding: false, label: 'Usage Type Code', width: '100px' }
];

const NOTES_USAGE_TYPE_CD = {
    functionalArea: 'General',
    dataElementName: 'G_NOTE_TY_CD',
    businessName: null,
    valueShortDescription: null,
    crossReferenceColumnName: null,
    crossReferenceTableName: null,
    dataEleNameStartsOrContains: null,
    busNameStartsOrContains: null
}
let successMessages = [];
function Note(props, ref) {
    const scrollToRef = (ref1) =>
    ref1.current?.scrollIntoView({ behavior: "smooth" });
    const classes = useStyles();
    const printNoteRef = useRef();
    const addNoteRef = useRef();
    const scrollToAdd =useRef()
    const dispatch = useDispatch();
    const { viewOnly,usageTypeCodeData,loginUserID,setErrorMessages,notes, setNotes, setNotesErrMsgs=()=>{} } = props;
    const [showMSG, setShowMSG] = React.useState(true);
    const [notesTableData, setNotesTableData] = React.useState(notes.notesList ? notes.notesList: []);
    const [filterTableData,setFilteTableData] =  React.useState([]);
    const [openAddNote, setOpenAddNote] = React.useState(false);
    const [totalCharacters, setTotalCharacters] = React.useState(320);
    const [wordCount, setWordCount] = React.useState(totalCharacters || 0);
    const [{ usageTypeError, textError }, setShowError] = React.useState(false);
    const [{ usageTypeErrorText, textErrorText }, setShowErrorText] = React.useState('');
    const [noteRowEditFlag, setNotRowEditFlag] = React.useState(false);    
    const [minorErrMessages,setMinorErrMessages] = React.useState([]);
    const [showFilterForm, setShowFilterForm] = React.useState(false);
    const [filterFormErrors, setShowFilterFormErrors] = React.useState({});
    const [selectDeleteArray,setSelectDeleteArray] = React.useState([]);
    const [checkFilterFormChanges,setCheckFilterFormChanges] = React.useState(false);
    const [checkAddFormChanges,setCheckAddFormChanges] = React.useState(false);
    const [openDialog,setOpenDialog] = React.useState(false);
    const [cancelType, setCancelType] = React.useState('');
    const [isPrintSelected, setIsPrintSelected] = React.useState(false);
    const userDetails = getLoginUserDetails();
    const [noteSetListInput, setNoteSetListInput] = React.useState({
        auditUserID: loginUserID,
        auditTimeStamp: new Date(),
        addedAuditUserID: loginUserID,
        addedAuditTimeStamp: new Date(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: loginUserID,
        notesCexAuditUserID: loginUserID,
        notesCexAuditTimeStamp: new Date(),
        notesCexAddedAuditUserID: loginUserID,
        notesCexAddedAuditTimeStamp: new Date(),
        noteSetSK: notes.noteSetSK,
        usageTypeDesc: "",
        shortNotes: "",
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: 1,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate:  moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: 'Please Select One',
        tableName: null,
        noteText: "",
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: loginUserID,
        noteCexVersionNum: 0,
        notesCexnoteTextValue: null
      });
    // Input Values
    const [values, setValues] = React.useState({
        usageTypeCode: 'Please Select One',
        text: ''
    });
    const handleMinorSaveNotes= ()=>{
        setShowError({});
        if(openAddNote && (noteSetListInput.usageTypeCode !== 'Please Select One' || noteSetListInput.noteText.trim() !== '')){
            handleSaveNote();
        }
    }
    useImperativeHandle(ref, () => {
        return {
            handleReset,
            handleMinorSaveNotes,
            noteSetListInput
        };
      });
    //to set in current note
    let latestRecord= notesTableData.length ? notesTableData[0] : null;
    if(notesTableData.length){
        notesTableData.map(item =>{
            if(moment(item.strBeginDate) > moment(latestRecord.strBeginDate)){
                latestRecord= item
            }
        })
    }
    // current note
    const [currentNote, setCurrentNote] = React.useState(
        latestRecord && latestRecord.noteText
    );
    const [counter,setCounter] = React.useState(0);

    //filter Form
    const [filterFormValue,setFilterFormValue]=React.useState({
           beginDate:null,
           endDate:newDate("12/31/9999"),
           userId:'',
           usageTypeCode:''
    })

    const checkWordCount = e => {
        const textValue = e.target.value;
        const left_Chars = totalCharacters - textValue.length;
        setWordCount(left_Chars);
        setValues({ ...values, text: textValue });
    };

    // handle change input values
    const handleChangeValue = name => event => {
        setCheckAddFormChanges(true);
        // setValues({ ...values, [name]: event.target.value });
        setNoteSetListInput({ ...noteSetListInput, [name]: event.target.value });
        if (name === 'usageTypeCode') {
            setNoteSetListInput({ ...noteSetListInput, [name]: event.target.value, usageTypeDesc: mapCodeDescription(event.target.value, usageTypeCodeData) });
        }
    };
    const mapCodeDescription = (_code, _mapArray) => {
        if (_mapArray && _mapArray.length > 0) {
            let _description = [];
            _description = _mapArray.filter((value, index) => value.code === _code);
            if (_description.length > 0) {
                return _description[0].description;
            } else {
                return _code;
            };
        };
        return _code;
    };
    const showAddNote = () => {
        //hide filter form
        handleFilterCancel();
        if (props.setSuccessMessages) {
            props.setSuccessMessages();
        }
            setTimeout(function () {
              scrollToRef(addNoteRef);
            }.bind(this), 300);
        setCheckAddFormChanges(false);
        setOpenAddNote(true);
        // setEditNoteData({
        //     ...noteSetListInput,
        //     usageTypeCode: 'Please Select One',
        //     noteText: '',
        //     usageTypeDesc: ''
        //   })
        setNoteSetListInput({
            ...noteSetListInput,
            usageTypeCode: 'Please Select One',
            noteText: '',
            usageTypeDesc: '',
            noteSequenceNumber: getMaxSeqnum() + 1
        });

        setWordCount(totalCharacters);
    };

    const getMaxSeqnum = ()=>{
        let maxSeqNum=0;
        notesTableData.forEach((it)=>{
             if(maxSeqNum < it.noteSequenceNumber){
                maxSeqNum = it.noteSequenceNumber;
            }
        })
        return maxSeqNum;
    }


    const setSuccessMessages = (v = []) => {
        successMessages = v;
        setShowMSG(false);
        setTimeout(() => {
            setShowMSG(true);
        }, 100);
    };
    React.useEffect(() => {
        successMessages = [];
    }, []);
    // to save data to table
    const handleSaveNote = () => {
        // const tableData = [];
        if(setErrorMessages)setErrorMessages([]);
        setNotesErrMsgs([]);
        let errMsgs=[];
        const value = noteSetListInput;
        var usageTypeError;
        var textError = false;
        var usageTypeErrorText;
        var textErrorText = '';

        if (noteSetListInput.usageTypeCode === 'Please Select One') {
            usageTypeError = true;
            usageTypeErrorText = 'Usage Type Code is required.';
            errMsgs.push('Usage Type Code is required.');
        }
        if (noteSetListInput.noteText.trim() === '') {
            textError = true;
            textErrorText = 'Note Description is required.';
            errMsgs.push('Note Description is required.');
        }
        if (!usageTypeError && !textError) {
            successMessages = [NOTES_ADD];
            value.strBeginDate = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
            value.auditTimeStamp = getUTCTimeStamp();
            value.addedAuditTimeStamp = getUTCTimeStamp();
            value.auditUserID = loginUserID;
            value.addedAuditUserID = loginUserID;
            values.userId = loginUserID;
            setCurrentNote(value.noteText);
            // handle in parent component
            // addNotes(value);
            //handle in same component
            setTimeout(function () {
                scrollToRef(scrollToAdd);
              }.bind(this), 300);
            handleAddNotes(value);            
            handleCloseAddNote();
        }
        setShowError({
            usageTypeError: usageTypeError,
            textError: textError
        });
        setShowErrorText({
            usageTypeErrorText: usageTypeErrorText,
            textErrorText: textErrorText
        });
        if(errMsgs.length){
            if(setErrorMessages)setErrorMessages(errMsgs);
            setNotesErrMsgs(errMsgs)
        }

    };

    const handleAddNotes=(data)=>{
        const noteText = data;
        let notesDataArray = notesTableData;
        notesDataArray.push(noteText);
        setNotesTableData(notesDataArray);
        setNotes({ ...notes, notesList: notesDataArray });
        // setNotesInput({ ...notesInput, notesList: notesDataArray });
    }

    const handleCloseAddNote = () => {
        if(setErrorMessages)setErrorMessages([]);
        setNotesErrMsgs([]);
        setOpenAddNote(false);
        setWordCount(totalCharacters);
        // setEditNoteData({
        //     ...noteSetListInput,
        //     usageTypeCode: 'Please Select One',
        //     noteText: '',
        //     usageTypeDesc: ''
        //   })
        setNoteSetListInput({ ...noteSetListInput, usageTypeCode: 'Please Select One', noteText: '', usageTypeDesc: '' });
    };

    // to reset form
    const handleReset = () => {
        if(setErrorMessages)setErrorMessages([]);
        setNotesErrMsgs([]);
        setCheckAddFormChanges(false);
        setNoteSetListInput({
            ...noteSetListInput,
            usageTypeCode: 'Please Select One',
            noteText: '',
            usageTypeDesc: ''
        });
        // setNoteSetListInput({
        //     ...noteSetListInput,
        //     usageTypeCode: editNoteData.usageTypeCode,
        //     noteText: editNoteData.noteText,
        //     usageTypeDesc:editNoteData.usageTypeDesc
        //   });
        // setWordCount(totalCharacters - editNoteData.noteText.length);
        setWordCount(totalCharacters);
        setShowError([]);
        setShowErrorText([]);
    };

    //only set currentnote on click of row
    const editRow = row => event => {
        // setOpenAddNote(true);
        setCurrentNote(row.noteText);
        // if (setEditNoteData) {
        //   setEditNoteData(row);
        // }
        // setNoteSetListInput({
        //   ...noteSetListInput,
        //   index:row.index,
        //   usageTypeCode: row.usageTypeCode,
        //   noteText: row.noteText,
        //   usageTypeDesc:row.usageTypeDesc
        // });
        // setWordCount(totalCharacters - row.noteText.length);
    };

    // const getTableData = tdata => {
    //     if (tdata) {
    //         tdata.map((each, index) => {
    //             each.index = index;
    //         })
    //         return tdata;
    //     } else {
    //         return [];
    //     }
    // }

    const handleFilterChange = name => (event) => {
        setCheckFilterFormChanges(true);
        setFilterFormValue({ ...filterFormValue, [name]: event.target.value});
        if (name === 'usageTypeCode') {
            setFilterFormValue({ ...filterFormValue, [name]: event.target.value,usageTypeDesc: mapCodeDescription(event.target.value, usageTypeCodeData) });
        }else{
            setFilterFormValue({ ...filterFormValue, [name]: event.target.value});
        }
    };
    const handleDateChange = (name,date) => {
        setCheckFilterFormChanges(true);
        setFilterFormValue({ ...filterFormValue, [name]: date});
      };

      const onCancel = (type)=>{
         setCancelType(type);
        if((type === 'filter' && checkFilterFormChanges) || (type === 'add' && checkAddFormChanges)){
            setOpenDialog(true);
        }else{
           if(type === 'filter'){ 
           handleFilterCancel();
          }else{
            handleAddCancel();   
           }
           
        }
      }

      const handleDialogCancel=()=>{
        setOpenDialog(false);
        if(cancelType === 'filter'){ 
            handleFilterCancel();
           }else{
            handleAddCancel();
            }
      }

    const handleAddCancel=()=>{
        setOpenAddNote(false);
        handleReset();
        setTimeout(function () {
            scrollToRef(scrollToAdd);
          }.bind(this), 300);
    }
    const handleFilterCancel = () => {
        setOpenDialog(false);
        setCheckFilterFormChanges(false);
        setFilterFormValue({
            beginDate:null,
            endDate:newDate("12/31/9999"),
            userId:'',
            usageTypeCode:''
        })
        setTimeout(function () {
            scrollToRef(scrollToAdd);
          }.bind(this), 300);
     setShowFilterForm(false);
     setFilteTableData(notesTableData);
     if(setErrorMessages)setErrorMessages([]);
     setNotesErrMsgs([]);
     setShowFilterFormErrors({});
    }

    const checkDtWithinRange=(it)=>{
        if((new Date(parseDateToMMDDYYYY(it.filterbeginDate)).getTime() >= new Date(parseDateToMMDDYYYY(filterFormValue.beginDate)).getTime()) &&
         (new Date(parseDateToMMDDYYYY(it.filterbeginDate)).getTime() <= new Date(parseDateToMMDDYYYY(filterFormValue.endDate)).getTime())){
            return true;
        }else{
            return false;
        }
    }
    const handleFilterApply = () => {
        if (checkFilterValidation()) {
            setCounter(counter + 1);
            let result = notesTableData
            let filterresult=[];
            let filterUserIdFormValue= filterFormValue.userId && filterFormValue.userId.trim();
            if(filterFormValue.usageTypeCode !== '' && filterFormValue.usageTypeCode !== '-1' &&  filterUserIdFormValue!== ''){
             filterresult = result.filter(it => {
                if (
                  (checkDtWithinRange(it)) && 
                  (it.usageTypeCode === filterFormValue.usageTypeCode) &&
                  (it.userId === filterUserIdFormValue) 
                ) {
                  return true;
                }
              });
            }else if(filterFormValue.usageTypeCode !=='' && filterFormValue.usageTypeCode !== "-1"){
                filterresult = result.filter(it => {
                    if (
                      (checkDtWithinRange(it)) && 
                      (it.usageTypeCode === filterFormValue.usageTypeCode)
                    ) {
                      return true;
                    }
                  });
            }else if(filterUserIdFormValue){
                filterresult = result.filter(it => {
                    if (
                      (checkDtWithinRange(it)) && 
                      (it.userId === filterUserIdFormValue)
                    ) {
                      return true;
                    }
                  });
            }else{
                filterresult = result.filter(it => {
                    if (checkDtWithinRange(it)){
                      return true;
                    }
                  });

            }

            setFilteTableData(filterresult)
            // if(filterresult.length===0){
            //     if(setErrorMessages)setErrorMessages([NOTES_NO_REC]);
            // }
        }
    }

    const handleFilterReset = () => {
        setCounter(counter +1);
        setOpenDialog(false);
        setCheckFilterFormChanges(false);
        setFilterFormValue({
            beginDate:null,
            endDate:newDate("12/31/9999"),
            userId:'',
            usageTypeCode:''
        })
        setFilteTableData(notesTableData);
        if(setErrorMessages)setErrorMessages([]);
        setNotesErrMsgs([]);
        setShowFilterFormErrors({});
    }
   
    const checkFilterValidation =()=>{
        let errObj = {}, errorMsg = [];
        if(setErrorMessages)setErrorMessages([]);
        setNotesErrMsgs([])
        setShowFilterFormErrors({});
        if (!filterFormValue.beginDate) {
            errObj["bgDtReqErr"] = true;
            errorMsg.push(ErrMsgConstants.Begin_Date_Error);
        } else if (newDate(filterFormValue.beginDate).toString() == "Invalid Date") {
            errObj["bgDtReqIErr"] = true;
            errorMsg.push(ErrMsgConstants.The_Invalid_Begin_Date_Error);
        }
        if (!filterFormValue.endDate) {
            errObj["enDtReqErr"] = true;
            errorMsg.push(ErrMsgConstants.End_Date_Error);
        } else if (newDate(filterFormValue.endDate).toString() == "Invalid Date") {
            errObj["enDtReqIErr"] = true;
            errorMsg.push(ErrMsgConstants.The_Invalid_End_Date_Error);
        }
        if (filterFormValue.beginDate && filterFormValue.endDate &&
            newDate(filterFormValue.beginDate).toString() != "Invalid Date" &&
            newDate(filterFormValue.endDate).toString() != "Invalid Date" &&
            // newDate(filterFormValue.beginDate) > newDate(filterFormValue.endDate)
            new Date(parseDateToMMDDYYYY(filterFormValue.beginDate)).getTime() > new Date(parseDateToMMDDYYYY(filterFormValue.endDate)).getTime()) {
            errObj["bDtGTEnDtErr"] = true;
            errorMsg.push(ErrMsgConstants.BEGIN_DT_GRT_END_DT);
        }
        if (errorMsg.length == 0) {
            return true;
        } else {
            if(setErrorMessages)setErrorMessages(errorMsg);
            setNotesErrMsgs(errorMsg);
            setShowFilterFormErrors(errObj); 
            return false;
        }
    
    }

    const getTableData=(data)=>{
        if (data && data.length) {
            let tData = JSON.stringify(data); 
            tData = JSON.parse(tData); 
            tData.map((each,index) => {
                each.index=index;
            })
            return tData;
        }else{
            return [];
        }
    }
   const clearBothForm = ()=>{
       handleAddCancel();
       handleFilterCancel();
   }
    return (
        <div>
            <div className="tab-holder CustomExpansion-panel mt-3">
                <ExpansionPanel className="collapsable-panel" expanded={true}>
                    <ExpansionPanelSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="note"
                    >
                        <Typography className={classes?.heading}>Notes</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="clear-block">
                        <div className="tabs-container my-2">
                            <div className="tab-holder" ref={scrollToAdd}>
                                <div className="tab-header mb-2">
                                    {showMSG && successMessages.length > 0 ? (
                                        <SuccessComponent
                                            successMessages={successMessages}
                                            setSuccessMessages={setSuccessMessages}
                                        />
                                    ) : null}
                                 {minorErrMessages.length > 0 ? (
                                        <ErrorComponent
                                            errorMessages={minorErrMessages}
                                            setErrorMessages={setMinorErrMessages}
                                        />
                                    ) : null}
                                   {!viewOnly && <div className="float-right th-btnGroup">
                                        <Button
                                            title="Add Note"
                                            variant="outlined"
                                            color="primary"
                                            className="btn btn-secondary btn-icon-only"
                                            onClick={showAddNote}
                                        >
                                            <span className="hide-elm">Add Note</span>
                                            <i className="fa fa-plus" />
                                        </Button>
                                    </div>}
                                </div>
                                <div className="tab-body-bordered pt-0 pr-3 pb-3 pl-3">
                                    <div className="tabs-container">
                                        <div className="tab-header mb-2">
                                            <h3 className="tab-heading float-left">Current Note</h3>
                                        </div>

                                        <div className="disabled-form pt-1">
                                            <textarea
                                                className={classes?.txtfullwidth}
                                                aria-label="Current Notes Description"
                                                id="claim_notes"
                                                placeholder=""
                                                value={currentNote}
                                                disabled
                                                rowsMin={1}
                                                rowsMax={4}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="tabs-container mt-2">
                                        <div className="tab-header mb-2">
                                            <h3 class="tab-heading float-left">Notes History</h3>
                                            <div className="float-right th-btnGroup">
                                                <Button
                                                    title="Filter"
                                                    variant="outlined"
                                                    disabled={false}
                                                    color="primary"
                                                    className="btn btn-ic btn-filter btn-filter-transparent"
                                                    onClick={()=>{handleFilterReset();setShowFilterForm(true);handleAddCancel();
                                                        // setFilteTableData(notesTableData);setCheckFilterFormChanges(false);
                                                    }}
                                                >
                                                    {" "}
                                                Filter
                                            </Button>
                                                <ReactToPrint
                                                    onBeforeGetContent={() => {
                                                        clearBothForm();
                                                        setIsPrintSelected(true);
                                                        dispatch(setPrintLayout(true));
                                                        return new Promise((resolve) =>
                                                            setTimeout(() => resolve(), 100)
                                                        );
                                                    }}
                                                    onAfterPrint={() => {
                                                        setIsPrintSelected(false);
                                                        dispatch(setPrintLayout(false));
                                                    }}
                                                    trigger={() => (
                                                        <Button
                                                            title="Print Selected"
                                                            variant="outlined"
                                                            color="primary"
                                                            className="btn btn-ic btn-print"
                                                            disabled = {selectDeleteArray.length > 0 ? false:true}
                                                        >
                                                            {" "}
                                                Print Selected
                                                        </Button>
                                                    )}
                                                    content={() => printNoteRef.current}
                                                />
                                            </div>
                                        </div>
                                        {showFilterForm ? 
                                        <NoteFilterForm 
                                        filterFormValue={filterFormValue}
                                        handleFilterChange={handleFilterChange}
                                        handleDateChange={handleDateChange}
                                        usageTypeCodeData={usageTypeCodeData}
                                        handleFilterCancel={()=>{onCancel('filter')}}
                                        handleFilterApply = {handleFilterApply}
                                        handleFilterReset={handleFilterReset}
                                        errors={filterFormErrors}
                                        />:null}
                                        <div className='tab-holder mt-2 para-truncate' className={isPrintSelected ? "desc-truncate hide-show-tbl-row" : "desc-truncate"} ref={printNoteRef}>
                                            <TableComponent counter={counter} multiDelete selected={selectDeleteArray} setSelected={setSelectDeleteArray} headCells={headCells} isSearch={true} tableData={showFilterForm ?getTableData(filterTableData): getTableData(notesTableData)} onTableRowClick={editRow} sortOrder='asc' defaultSortColumn='strBeginDate'/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {openAddNote ? (<div ref={addNoteRef} className="tabs-container my-3">
                            <div className="tab-header">
                                <h2 className="tab-heading float-left">
                                    {/* {noteRowEditFlag ? "Edit" : "Add"}  */}
                                            Add Note
                            </h2>
                                <div className="float-right th-btnGroup">
                                    <Button title="Add" variant="outlined" color="primary" className="btn btn-ic btn-save" onClick={handleSaveNote}>
                                        Add
                                            </Button>
                                    <Button title="Reset" variant="outlined" color="primary" className="btn btn-ic btn-reset" onClick={() => handleReset()}>
                                        Reset
                                </Button>
                                    <Button title="Cancel" variant="outlined" color="primary" className="btn btn-cancel" onClick={() => { onCancel('add'); }}>
                                        Cancel
                                </Button>
                                </div>
                            </div>
                            <NoteAddForm
                                noteSetListInput={noteSetListInput}
                                handleChangeValue={handleChangeValue}
                                usageTypeCodeData={usageTypeCodeData}
                                wordCount={wordCount}
                                checkWordCount={checkWordCount}
                                totalCharacters={totalCharacters}
                                textError={textError}
                                textErrorText={textErrorText}
                                usageTypeError={usageTypeError}
                                usageTypeErrorText={usageTypeErrorText}
                            />
                        </div>) : null}


                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

            <Dialog
              open={openDialog}
              onClose={handleFilterCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box-inject"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                 
                    <>
                      Unsaved changes will be lost. <br />
                    Are you sure you want to Continue?
                  </>
                 
                </DialogContentText>
              </DialogContent>
            
                <DialogActions>
                  <Button
                    title="Stay on this page"
                    onClick={() => {
                      setOpenDialog(false);
                    //   setCancelType(false);
                    //   onCancel();
                    }}
                    color="primary"
                    className="btn btn-transparent"
                  >
                    STAY ON THIS PAGE!
                </Button>
                  <Button
                    title="Continue"
                    onClick={handleDialogCancel}
                    color="primary"
                    className="btn btn-success"
                    autoFocus
                  >
                    CONTINUE <i className="fa fa-arrow-right ml-1"></i>
                  </Button>
                </DialogActions>
             
            </Dialog>

        </div>
    )
}
Note.defaultProps = {
    viewOnly:false,
    usageTypeCodeData:[],
    loginUserID:'TEST',
    notes:{notesList:[]}
  };
export default forwardRef( Note);
