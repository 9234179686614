/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/

import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from 'react-router';
import AddManageAccount from './AddManageAccount';
import EditManageAccount from './EditManageAccount';

function ManageAccountAddEdit(props) {
  const ManageAccountType = useSelector((state) => state.userManagement.type);
  return (
    <>{ManageAccountType == 'Add' ? <AddManageAccount /> : <EditManageAccount />}</>
  )
}
export default withRouter(ManageAccountAddEdit);
