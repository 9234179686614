import React from "react";
import { Button } from "react-bootstrap";

function NewLogin(props) {

  return (
    <>
      <div className="ligin-form loginForm">
        <div className="form-wrapper">
          <div className="w-100">
            <span>{process.env.REACT_APP_LOGINTEXT}</span>
            <Button
              data-test="normalLogin"
              title="Log On"
              id='normalLogin'
              variant="outlined"
              color="primary"
              className="btn  btn-primary w-100 login-btn mb-3 mt-3"
              onClick={() => {
                props.setLoginType("NORMAL_LOGIN");
              }}
            >
              {process.env.REACT_APP_LOGIN}
            </Button>
            <Button
              data-test="ssoLogIn"
              title="SSO Log On"
              id='ssoLogIn'
              variant="outlined"
              color="primary"
              className="btn  btn-primary w-100 login-btn"
              onClick={() => {
                window.open(process.env.REACT_APP_ROUTE_ENV + 'userManagement/getauthorizations', "_self");
              }}
            >
              {process.env.REACT_APP_SSOLOGIN}
            </Button>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default NewLogin;
