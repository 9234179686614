import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// function createElement(id) {
//   var node = document.createElement("div");
//   node.id = id;
//   document.body.appendChild(node);
//   return id;
// }

window.renderCommonModule = (containerId, history, privileges,componentProps, ref) => {
  console.log("Injectable Module renderCommonModule function");
  // if (!document.getElementById(containerId)) {
  //   createElement(containerId);
  //   }
  ReactDOM.render(
    <App history={history} privileges={privileges} componentProps={componentProps} ref= {ref}/>,
    document.getElementById(containerId),
  );
  serviceWorker.unregister();
};

window.unmountCommonModule = containerId => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

if (!document.getElementById('CommonModule-container')) {
  ReactDOM.render(<App />, document.getElementById('mFroot'));
  serviceWorker.unregister();
}

//Notes
// window.renderNotes = (containerId, history, privileges,componentProps) => {
//   ReactDOM.render(
//     <App history={history} privileges={privileges} componentProps={componentProps}/>,
//     document.getElementById(containerId),
//   );
//   serviceWorker.unregister();
// };

// window.unmountNotes = containerId => {
//   ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
// };


//Audit Log 
// window.renderAuditLog = (containerId, history, privileges,componentProps) => {
//   ReactDOM.render(
//     <AuditLogCommon {...componentProps}/>,
//     document.getElementById(containerId),
//   );
//   serviceWorker.unregister();
// };

// window.unmountAuditLog = containerId => {
//   ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
// };

//Row Level - Audit Log

// window.renderMinorAuditLog = (containerId, history, privileges,componentProps) => {
//   ReactDOM.render(
//     <AuditLogRow {...componentProps}/>,
//     document.getElementById(containerId),
//   );
//   serviceWorker.unregister();
// };

// window.unmountMinorAuditLog = containerId => {
//   ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
// };
