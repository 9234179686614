import * as actionTypes from '../Actions/ActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_PATH:
      return { currentPath: action.currentPath };
    case actionTypes.MULTI_DROPDOWN_VALUES_ACTION:
      return { ...state, multiDropDownData: action.multiDropDownData };
    case actionTypes.ADDRESS_VERIFY:
      return { ...state, addressValidatedData: action.addressValidatedData };
    case actionTypes.USER_LOGGIN_ACTION:
      return { ...state, userPrivileges: action.userPrivileges };
    case actionTypes.USER_INQUIRY_PRIVILEGE:
      return { ...state, userInquiryPrivileges: action.userInquiryPrivileges };
    case actionTypes.LOGIN_USER_ID:
      return { ...state, logInUserID: action.logInUserID };
    case actionTypes.SSO_LOGIN:
      return { ...state, ssoLogin: action.ssoLogin };
    case actionTypes.SET_PRINT_LAYOUT:
      return { ...state, printLayout: action.printLayout };
    case actionTypes.SET_ATTACHMENTS_DDD_VALUES:
      return { ...state, attachmentDDValues: action.attachmentDDValues };
    case actionTypes.DOWNLOAD_FILE_ACTION:
      return { ...state, downloadeFile: action.downloadeFile };
    case actionTypes.USER_ROLES_DROPDOWNS:
        return { ...state, userRolesDropdown: action.userRolesDropdown };
    case actionTypes.WORK_UNITS_DROPDOWNS:
        return { ...state, workUnitsDropdown: action.workUnitsDropdown };
    case actionTypes.REFRESH_ATTACHMENTS:
      return { ...state, newAttachmentResult: action.newAttachmentResult };
    case actionTypes.SHOW_SESSION_EXPIRY_DIALOG:
        return { ...state, showSessionExpiryDialog: action.status };
    case actionTypes.SESSION_EXPANDED:
        return { ...state, sessionExtendStatus: action.status };
    case actionTypes.USER_IN_OUT_ACTION:
          return { ...state, userInOutStatus: action.status };   
    default: return state;
  }
};

export default reducer;
