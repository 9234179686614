/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React from "react";
import * as TextConst from "../../ManageAccountConstants";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";


const Action = (props) => { 
    return (
        <div className="tabs-holder">
            <div className="tab-body-bordered collapsable-panel mt-3">
                <div className="custom-panel"><div className="panel-header"><span>{TextConst.ACTION_HEADING}</span></div></div>
                <form autoComplete="off">
                    <div className="mt-3">
                        <div className="form-wrapper p-0">
                            <NativeDropDown
                                    autoFocus={true}
                                id="user-deactivate"
                                    label={TextConst.DEACTIVATE}
                                    value={props.values.deactivate}
                                    onChange={props.handleValuesChanges('deactivate')}
                                    disabled={props.shouldDisable}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                errTxt={props.errors.deactiveReasonReq ? props.errorMsg.deactivateMsg : null}
                                dropdownList={[
                                    <option selected key="yes" value={true}>{TextConst.YES}</option>,
                                    <option selected key="no" value={false}>{TextConst.NO}</option>
                                ]}
                            />
                            <NativeDropDown
                                id="user-lock"
                                    label={TextConst.LOCK}
                                    value={props.values.lock}
                                    onChange={props.handleValuesChanges('lock')}
                                    disabled={props.shouldDisable}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                errTxt={props.errors.lockReasonReq ? props.errorMsg.lockMsg : null}
                                dropdownList={[
                                    <option selected key="yes" value={true}>{TextConst.YES}</option>,
                                    <option selected key="no" value={false}>{TextConst.NO}</option>
                                ]}
                            />
                            <div className="mui-custom-form input-md input-md-2-col">
                <div className="MuiFormControl-root MuiTextField-root">
                  <label
                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink radio-button-label-text Mui-required"
                    htmlFor="orthodontic_treatment_indicator"
                  >
                    <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">* </span>
                    {TextConst.RESION_ACTION}
                  </label>
                </div>
                                <textarea
                                    aria-label="user-reason-action"
                                    id="user-reason-action"
                                    placeholder="Empty"
                                    className="wid-100 .mui-custom-form.with-textarea"
                                    disabled={(props.values.deactivate == props.defaultValues.deactivate && props.values.lock == props.defaultValues.lock) || 
                                        props.shouldDisable}
                                    value={props.values.reasonAction}
                                    onChange={props.handleValuesChanges('reasonAction')}
                                />
                                
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Action;
