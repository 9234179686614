import React from 'react';
import { NativeSelect, InputLabel, FormHelperText } from '@material-ui/core';
/* import TooltipComponent from '../Tooltip/Tooltip'; */

const NativeDropDown = props => {
    const { id, testId, inputProps, value, isRequired, onChange, name, title, label, tooltipDesc = null, dropdownList, errTxt, disabled = false, showOnlyListItem = false, className = "", autoFocus = false } = props;
    return (
        <div className={`mui-custom-form with-select input-md custom-input-select pos-rel ${className}`}>
          {/*   {tooltipDesc !== null && <TooltipComponent title={title} desc={tooltipDesc} />} */}
            <InputLabel className={label ? "" : "mute-lbl"} htmlFor={id}>
                {isRequired ? <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span> : ''}
                {label ? label : "Dummy Label"}
            </InputLabel>
            <NativeSelect
                data-test={testId}
                id={id}
                disabled={disabled}
                inputProps={inputProps}
                value={value}
                name={name}
                autoFocus={autoFocus}
                inputlabelprops={{
                    shrink: true,
                    required: { isRequired },
                    name: 'name',
                    id: { id },
                }}
                onChange={onChange}
            >
                {!showOnlyListItem && <option selected key="Please Select One" value="-1">Please Select One</option>}
                {dropdownList}
            </NativeSelect>
            <FormHelperText className="required-label">{errTxt}</FormHelperText>
        </div>
    )
}

export default NativeDropDown;