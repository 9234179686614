export const GET_CURRENT_PATH = 'GET_CURRENT_PATH';
export const MULTI_DROPDOWN_VALUES_ACTION = 'MULTI_DROPDOWN_VALUES_ACTION';
export const ADDRESS_VERIFY = 'ADDRESS_VERIFY';
export const USER_LOGGIN_ACTION = 'USER_LOGGIN_ACTION';
export const USER_INQUIRY_PRIVILEGE = 'USER_INQUIRY_PRIVILEGE';
export const LOGIN_USER_ID = 'LOGIN_USER_ID';
export const SSO_LOGIN = 'SSO_LOGIN';
export const SET_PRINT_LAYOUT = 'SET_PRINT_LAYOUT';
export const SET_ATTACHMENTS_DDD_VALUES = 'SET_ATTACHMENTS_DDD_VALUES';
export const DOWNLOAD_FILE_ACTION = 'DOWNLOAD_FILE_ACTION';
export const REFRESH_ATTACHMENTS = 'REFRESH_ATTACHMENTS';
export const ATTACHMENT_API = 'ATTACHMENT_API';
export const USER_ROLES_DROPDOWNS = 'USER_ROLES_DROPDOWNS';
export const WORK_UNITS_DROPDOWNS = 'WORK_UNITS_DROPDOWNS';
export const SPINNER_STATUS = 'SPINNER_STATUS';
export const SHOW_SESSION_EXPIRY_DIALOG = 'SHOW_SESSION_EXPIRY_DIALOG';
export const SESSION_EXPANDED = 'SESSION_EXPANDED';
export const USER_IN_OUT_ACTION = 'USER_IN_OUT_ACTION';
export const APP_CONFIG_DROPDOWNS = "APP_CONFIG_DROPDOWNS";
export const RULES_CONFIG_DROPDOWNS = "RULES_CONFIG_DROPDOWNS";
export const MENU_STATE_CHANGED = "MENU_STATE_CHANGED";

