import React from 'react';
import { withRouter } from 'react-router';

import AuditLogRowTableComponent from './AuditLogRowTableComponent';

function AuditLogRow(props) {

    return (
        <div>
            <div className="tab-header">
                <h2 className="tab-heading float-left">
                    Audit Details
                    
                </h2>
            </div>
            <AuditLogRowTableComponent auditLogData={props.auditLogData?props.auditLogData:[]} />
        </div>
    );
};

export default AuditLogRow;