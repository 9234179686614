import React, { useState, useEffect, forwardRef } from "react";
import logo from "./logo.svg";
import './App.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './SharedModules/Store/store';
import { Router } from 'react-router-dom';
import history from './SharedModules/Navigation/history';
import { ConfirmProvider } from './SharedModules/MUIConfirm/index';
import { Link, Route } from 'react-router-dom';
import routes from './SharedModules/Navigation/Routes';
import Note from './SharedModules/Note/Note';
import AuditLog from './SharedModules/AuditLog/AuditLog';
import AuditLogRow from './SharedModules/AuditLog/AuditLogRow';
import HomePage from "./HomePage";
import ProfileCard from "./Modules/MyAccount/ProfileCard";
import ProfileIcon from "./Modules/MyAccount/ProfileIcon";
import ManageAccount from "./Modules/MyAccount/ManageAccount";

function App(props,ref) {
  const {componentProps} = props

  return (
    <Provider store={store}>
    <Router history={props?.history ? props.history : history} >
      <PersistGate persistor={persistor}>
        <ConfirmProvider>
        
      {componentProps ? (
        componentProps.type === 'my_account' ? (
          <ManageAccount {...componentProps}  />
        ) : componentProps.type === 'AuditLog' ? (
          componentProps.auditType === 'AuditLog' ? (
            <AuditLog {...componentProps}  />
          ) : (
            <AuditLogRow {...componentProps}  />
          )
        ) : componentProps.type === 'profile_card' ? (
          <ProfileCard {...componentProps}  />
        ) : componentProps.type === 'profile_Icon' ? (
          <ProfileIcon {...componentProps}  />
        ) : componentProps.type === 'Notes' ? (
          <Note {...componentProps} ref={ref} />
        ) : null
      ) : (
        <div className="mfComponent">
           <HomePage />
        </div>
       
      )}

        </ConfirmProvider>
      </PersistGate>
    </Router>
  </Provider>
  );
}

App.defaultProps={
  componentProps:{type:'Notes'}
}
export default forwardRef(App);
