export const PLEASE_SELECT_ONE = "Please Select One";
export const NUMBER_ONLY_REGEX = /[^0-9]/g;
export const BEGIN_DATE_SHOULD_NOT_BE_LESS_THAN_1964 = 'Begin Date should be greater than or equals to 01/01/1964';
export const DATE_ERROR_1964 = 'Date must be greater than or equal to 01/01/1964.';
export const ERR_PROCESSING_REQ = 'There was an error processing the request. Please retry the transaction.';
//
export const HELP = 'Help';
export const HISTORY = 'History';
export const NOTES = 'Notes';
export const PRINT = 'Print';
export const SAVE = 'Save';
export const SAVE_SUCCESS = 'System successfully saved the Information.';
export const FINANCIAL = 'Financial';
export const CMdSPortals = "CMdS-Portals";
export const CLAIMS_APP_CODE='CLAIMS'
export const X_CMDS_APP_CODE = 'CNDTAPPS_V2';
export const MANAGEACCOUNT_APP_ID = 'MANAGEACCOUNT';
export const MANAGEACCOUNTR_APP_ID = 'MANAGEACCOUNTR';

