import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AuditLog from "../AuditLog/reducer";
// import tplPolicy  from "../../Modules/TPLPolicy/reducer";
import userManagement from "../../Modules/UserSearch/reducer";
import appDropDowns from "../../SharedModules/Dropdowns/reducer";

/*App Config - CNDT changes*/
import sharedReducer from "./Reducers/Reducers";
// import suggestedAddress from "../SuggestedAddress/reducers";
// import tplBilling from "../../Modules/TPLBilling/reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const tplPolicyPersistConfig = {
  key: "tplPolicy",
  storage: storage,
  blacklist: ["payload", 'saveData', 'validateCarrierId'],
};

const userManagementPersistConfig = {
  key: 'userManagement',
  storage,
  whitelist: ['userDetailsResponse', 'type'],
};

// const tplBillingPersistConfig = {
//   key: "tplPolicy",
//   storage: storage,
//   blacklist: ["TplSearchData", "TplBillingUpdate"],
// };

const rootReducer = combineReducers({
  AuditLog,
  //appDropDowns,
  sharedState: sharedReducer,
  userManagement: persistReducer(userManagementPersistConfig, userManagement),
  appDropDowns,
  // suggestedAddress,
  // tplPolicy: persistReducer(tplPolicyPersistConfig, tplPolicy),
  // tplBilling: persistReducer(tplBillingPersistConfig, tplBilling),
});

export default persistReducer(persistConfig, rootReducer);
