import React from "react";
import "../../theme.scss";
import axios from "axios";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import * as ErrorMsgConstants from "../../SharedModules/Messages/ErrorMsgConstants";
import * as ServiceEndpoint from "../../SharedModules/services/service";
import * as TextConst from "../../SharedModules/Messages/userSearchConstant";


function LoginPage(props) {
  const {formValue, setformValue} = props;
  let errorMessagesArray = [];
  const [
    {
      currePassReqErr,
      newPassReqErr,
      confirmNewPassReqErr,
      passwordMinLenErr,
      password3Of4Err,
      newConfirmSameErr,
      currentPwdNewPwdNotSameErr,
      currPassInValidErr,
    },
    setShowErr,
  ] = React.useState(false);

  const [errorMessages, seterrorMessages] = React.useState([]);

  const handleChange = (name) => (event) => {
    setformValue({ ...formValue, [name]: event.target.value });
    accessToken ? props.setChangeState(false) : props.setChangeState(true);
  };

  const formValidation = () => {
    seterrorMessages([]);
    props.setInfoMessages([]);
    props.setWarningMessages([]);
    props.setErrorMessages([]);
    props.setSuccessMessages([]);
    props.setspinnerLoader(false);
    let reqFieldArr = [];
    let reqPassword3of4 = [];
    var currePassReqErr,
      newPassReqErr,
      confirmNewPassReqErr,
      passwordMinLenErr,
      password3Of4Err,
      newConfirmSameErr,
      currentPwdNewPwdNotSameErr;

    if (formValue.oldPassword === "") {
      currePassReqErr = true;
      reqFieldArr.push(ErrorMsgConstants.PASSWORD_REQ);
    }
    if (formValue.newPassword === "") {
      newPassReqErr = true;
      reqFieldArr.push(ErrorMsgConstants.PASSWORD_NEW_REQ);
    }
    if (formValue.newPassword !== "" && formValue.newPassword.length < 10) {
      passwordMinLenErr = true;
      reqFieldArr.push(ErrorMsgConstants.PASSWORD_LEN);
    }
    if (formValue.newPassword !== "" && formValue.newPassword.length >= 10) {
      if (/^(?=.*[a-z])/.test(formValue.newPassword)) {
        reqPassword3of4.push("lower");
      }
      if (/^(?=.*[A-Z])/.test(formValue.newPassword)) {
        reqPassword3of4.push("upper");
      }
      if (/^(?=.*[?=.*\d])/.test(formValue.newPassword)) {
        reqPassword3of4.push("number");
      }
      if (/^(?=.*?[^\w\s])/.test(formValue.newPassword)) {
        reqPassword3of4.push("specialChar");
      }
      if (reqPassword3of4.length < 3) {
        password3Of4Err = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED);
      }
    }
    if (formValue.confirmNewPassword === "") {
      confirmNewPassReqErr = true;
      reqFieldArr.push(ErrorMsgConstants.PASSWORD_CONFIRM_REQ);
    }
    if (
      formValue.newPassword !== "" &&
      formValue.newPassword.length >= 10 &&
      reqPassword3of4.length >= 3 &&
      formValue.confirmNewPassword !== "" &&
      formValue.newPassword !== formValue.confirmNewPassword
    ) {
      newConfirmSameErr = true;
      reqFieldArr.push(ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME);
    }
    if (formValue.newPassword === formValue.oldPassword && formValue.newPassword !== '' && formValue.oldPassword !== '') {
      //BR-990300005
      currentPwdNewPwdNotSameErr = true;
      reqFieldArr.push(ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR);
    }
    if (reqFieldArr.length) {
      setShowErr({
        currePassReqErr: currePassReqErr,
        newPassReqErr: newPassReqErr,
        confirmNewPassReqErr: confirmNewPassReqErr,
        passwordMinLenErr: passwordMinLenErr,
        password3Of4Err: password3Of4Err,
        newConfirmSameErr: newConfirmSameErr,
        currentPwdNewPwdNotSameErr: currentPwdNewPwdNotSameErr,
      });
      props.setErrorMessages(reqFieldArr);
      seterrorMessages(reqFieldArr);
      return false;
    }
    return true;
  };

  let userData = JSON.parse(localStorage.getItem("loginState"));
  let accessToken = localStorage.getItem("accessToken");

  const onchangePass = () => {
   
    // Chnage Password API Implementation
    const data = {
      userID: userData?.loginUserId || props.values.username,
      oldPassword: formValue.oldPassword,
      newPassword: formValue.newPassword,
    };

    setShowErr(false);
    let changeURL = ServiceEndpoint.CHANGE_PWD_ENDPOINT;
    let changeExpURL = ServiceEndpoint.CHANGE_EXP_PWD_ENDPOINT;
    let passwordURL = accessToken ? changeURL : changeExpURL;
    // let changeData = accessToken ? data : changePassData;   

    if (formValidation()) {
      props.setspinnerLoader(true);
      axios
        .post(`${passwordURL}`, data)
        .then((res) => {
          if (res.data.statusCode === "01") {
            accessToken ? '' : props.submitSuccess();
            props.setspinnerLoader(false);
            accessToken ? props.setIsLogin(true) : '';
            props.setAppList(userData?.applist || []);
            props.setInfoMessages([]);
            props.setWarningMessages([]);
            seterrorMessages([]);
            props.setErrorMessages([]);            
            props.setSuccessMessages([
              "Password has been changed successfully for the user " +
                `${userData?.loginUserId || props.values.username}` +
                ". The session remains active and the user may proceed.",
            ]);
          } else if (
            res.data.statusCode === "02" &&
            res.data.statusDescription === ErrorMsgConstants.PREVIOUS_FIVE_PWD
          ) {
            props.setspinnerLoader(false);
            props.setInfoMessages([]);
            props.setWarningMessages([]);
            seterrorMessages([]);
            props.setIsLogin(false);
            props.setSuccessMessages([]);
            props.setErrorMessages([ErrorMsgConstants.PASSWORD_5_TIMES]);
          } else if (
            res.data.statusCode === "02" &&
            res.data.statusDescription !== ErrorMsgConstants.PREVIOUS_FIVE_PWD
          ) {
            props.setspinnerLoader(false);
            props.setInfoMessages([]);
            props.setWarningMessages([]);
            seterrorMessages([]);
            props.setIsLogin(false);
            props.setSuccessMessages([]);
            setShowErr({ currPassInValidErr: true });
            props.setErrorMessages([ErrorMsgConstants.CURRENT_PASS_INVALID]);
          }
          else if (
            res.data.statusCode === "Unauthorized" &&
            (res.data.statusDescription === ErrorMsgConstants.CURRENT_PASSWORD_FAIL 
              || res.data.statusDescription === ErrorMsgConstants.AFTER_ONE_MORE_ATTEMPT 
              || res.data.statusDescription === ErrorMsgConstants.AFTER_TWO_MORE_ATTEMPT)
          ) {
            props.setspinnerLoader(false);
            props.setInfoMessages([]);
            props.setWarningMessages([]);
            seterrorMessages([]);
            props.setIsLogin(false);
            props.setSuccessMessages([]);
            setShowErr({ currPassInValidErr: true });
            props.setErrorMessages([ErrorMsgConstants.CURRENT_PASS_INVALID]);
          }
          else if (
            res.data.statusCode === "Unauthorized" &&
            res.data.statusDescription === ErrorMsgConstants.USER_LOCKED
          ) {
            props.setspinnerLoader(false);
            props.setInfoMessages([]);
            props.setWarningMessages([]);
            seterrorMessages([]);
            props.setIsLogin(false);            
            props.setIsPassword(false);
            props.setIsNormalLogin(false);
            props.setSuccessMessages([]);
            props.setErrorMessages([ErrorMsgConstants.ACCOUNT_LOCKED]);
          }
        })
        .catch((e) => {
          console.log("catche....", e);
          props.setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
        });
    }
  };

  const redirectToLandingPage = () => {
    props.setIsNormalLogin(false);
    props.setLoginState(false);
    props.setIsLogin(false);
    props.setIsPassword(false);
    seterrorMessages([]);
    props.setInfoMessages([]);
    props.setWarningMessages([]);
    props.setErrorMessages([]);
    props.logOut();
    props.setChangeState(false);
    props.setValues({
      username: '',
      password: '',
      voiChk: false
    });
  };

  React.useEffect(() => {
    setformValue({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
  },[])

  return (
    <>
      <div className="set-logOut">
        <div className="welcome-user">
          <h1>Hello {userData?.firstName} {userData?.lastName}!</h1>
          <p className="heading-text">Please change your password</p>
        </div>
        <div className="password-guide my-3 ml-5">
          <ul>
          <li>{TextConst.PAASWORD_MIN}</li>
            <li>{TextConst.PAASWORD_LAST_FIVE}</li>
            <li>
              {TextConst.PAASWORD_CONTAIN}
              <ul>
                <li>{TextConst.PAASWORD_UPPER}</li>
                <li>{TextConst.PAASWORD_lOWER}</li>
                <li>{TextConst.PAASWORD_NUMBER}</li>
                <li>{TextConst.PAASWORD_ALPHA}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="form-wrapper form-wrapper-alignment sso-login-form">
          <div className="mui-custom-form input-xl field-xl ">
            <TextField
              required
              id="current-passwprd"
              type="password"
              label="Current Password"
              className="inline-lable-ttl"
              // className={`${currePassReqErr || currPassInValidErr ? 'border-red' : "inline-lable-ttl"}`}
              value={formValue.oldPassword}
              onChange={handleChange("oldPassword")}
              inputProps={{ maxLength: 30 }}
              placeholder=""
              helperText={
                currePassReqErr
                  ? ErrorMsgConstants.PASSWORD_REQ
                  : currPassInValidErr
                  ? ErrorMsgConstants.CURRENT_PASS_INVALID
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}

              error={currePassReqErr ? ErrorMsgConstants.PASSWORD_REQ : currPassInValidErr
                ? ErrorMsgConstants.CURRENT_PASS_INVALID : null}
            />
          </div>
          <div className="mui-custom-form with-select input-xl field-xl">
            <TextField
              required
              id="new-password"
              type="password"
              label="New Password"
              value={formValue.newPassword}
              inputProps={{ maxLength: 30 }}
              onChange={handleChange("newPassword")}
              placeholder=""
              className="inline-lable-ttl"
              // className={`${newPassReqErr || passwordMinLenErr || password3Of4Err || currentPwdNewPwdNotSameErr ? 'border-red' : "inline-lable-ttl"}`}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                newPassReqErr
                  ? ErrorMsgConstants.PASSWORD_NEW_REQ
                  : passwordMinLenErr
                  ? ErrorMsgConstants.PASSWORD_LEN
                  : password3Of4Err
                  ? ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED
                  : currentPwdNewPwdNotSameErr
                  ? ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR
                  : null
              }
              error={
                newPassReqErr
                  ? ErrorMsgConstants.PASSWORD_NEW_REQ
                  : passwordMinLenErr
                  ? ErrorMsgConstants.PASSWORD_LEN
                  : password3Of4Err
                  ? ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED
                  : currentPwdNewPwdNotSameErr
                  ? ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR
                  : null
              }
            /> 
          </div>
          <div className="mui-custom-form with-select input-xl field-xl">
            <TextField
              required
              id="confirm-new-password"
              type="password"
              label="Confirm New Password"
              value={formValue.confirmNewPassword}
              inputProps={{ maxLength: 30 }}
              onChange={handleChange("confirmNewPassword")}
              className="inline-lable-ttl"
              // className={`${confirmNewPassReqErr || newConfirmSameErr ? 'border-red' : "inline-lable-ttl"}`}
              placeholder=""
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                confirmNewPassReqErr
                  ? ErrorMsgConstants.PASSWORD_CONFIRM_REQ
                  : newConfirmSameErr
                  ? ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME
                  : null
              }
              error={
                confirmNewPassReqErr
                  ? ErrorMsgConstants.PASSWORD_CONFIRM_REQ
                  : newConfirmSameErr
                  ? ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="th-btnGroup">
          <Button
            title="Submit"
            variant="outlined"
            color="primary"
            className="btn btn-ic btn-save mr-3"
            // disabled={props.privileges && !props.privileges.add }
            onClick={onchangePass}
          >
            Submit
          </Button>
          <Button
            title="Cancel"
            variant="outlined"
            color="primary"
            onClick={redirectToLandingPage}
            className="btn btn-cancel"
          >
            Cancel
          </Button>
        </div>
      </div>
      <div></div>
    </>
  );
}

export default LoginPage;
