export const DASHBOARD = "/dashboard";
export const VALID_VALUE_SEARCH = "/ValidvalueSearch";
export const VALID_VALUE_ADD = "/ValidvalueAdd";
export const VALID_VALUE_VIEW = "/ValidvalueView";
export const EVENT_SEARCH = "/EventSearch";
export const EVENT_MSG_CODE_SEARCH = "/EventTextMgmt";
export const EVENT_MSG_CODE_DETAIL = "/EventTextDetails";
export const AUDIT_SEARCH = "/AuditSearch";
export const MEMBER_SUMMARY_DETAILS = "/MemberSummaryDetails";
export const PAYEE_DETAILS = "/PayeeDetails";
export const MANAGE_MEMBER = "/ManageMember";
export const PROVIDER_MAINTENANCE = "/ProviderMaintenance";
export const PROVIDER_INQUIRY = "/ProviderInquiry";
export const PROVIDER_INQUIRY_DETAILS = "/ProviderInquiryDetails";
export const MEMBER_ELIGIBILITY_CONFIRMATION = "/MemberEligibilityConfirmation";
export const CLAIM_EXCEPTION = "/ClaimException";
export const SERVICE_AUTHORIZATION_EXCEPTION = "/ServiceAuthorizationException";
export const SERVICE_AUTHORIZATION_EXCEPTION_DETAILS =
  "/ServiceAuthorizationExceptionDetails";
export const MEDICAL_CRITERIA = "/MedicalCriteria";
export const MEDICAL_PARAMETER = "/MedicalParameter";
export const LOCATION_DETAILS = "/TextManagementLocationDetails";
export const TEXT_MANAGEMENT_SERVICE_AUTH_DETAILS =
  "/TextManagementServiceAuthDetails";
export const MEDICAL_PARAMETER_LIMIT_NUMBER_ADD =
  "/MedicalParameterLimitNumberAdd";
export const MEDICAL_PARAMETER_LIMIT_NUMBER_EDIT =
  "/MedicalParameterLimitNumberEdit";
export const UR_SYSTEM_LIST = "/URSystemList";
export const MASS_ADJ_SEARCH = "/MassAdjustmentsRequest";
export const WIP = "/Wip";
{
  /*ASC Group  Code routes start */
}
export const ASC_GROUP_ASC_REGION = "/ASCGroupASCRegionPlanID";

export const ASC_CODE_ADD = "/ASCGroupAdd";
export const ASC_CODE_EDIT = "/ASCGroupEdit";
export const ASC_CODE_DETAIL = "/ASCGroupDetails";

{
  /*ASC Group  Code routes end */
}
export const INSTITUTIONAL_PROVIDERID_NETWORKID_BENEFITPLANID =
  "/InstitutionalProviderIdNetworkIdBenefitPlanId";
export const CLAIM_CORRECTION_DETAILS = "/Claimcorrectiondetails";

export const MEMBER_DETAILS = "/MemberDetails";
export const MAINTENANCE = "/Maintenance";

{
  /*Suspense Release Code routes start */
}
export const SUSPENSE_RELEASE_SEARCH = "/SuspenseReleaseSearch";
export const SUSPENSE_RELEASE_ADD = "/SuspenseReleaseAdd";
export const SUSPENSE_RELEASE_EDIT = "/SuspenseReleaseEdit";

{
  /*Benefit Plan routes start */
}
export const SEARCH_BENEFIT_PLAN = "/SearchBenefitPlan";
export const BENEFIT_PLAN_ADD = "/BenefitPlanAdd";
export const BENEFIT_PLAN_EDIT = "/BenefitPlanEdit";
export const BENEFIT_PLAN_DETAILS = "/BenefitPlanDetails";
export const BENEFIT_PLAN_HIERARCHY_EDIT = "/BenefitplanHierarchyEdit";
export const BENEFIT_PLAN_HIERARCHY_ADD = "/BenefitPlanHierarchyAdd";
export const BENEFIT_PLAN_HIERARCHY_HELP = "/BenefitPlanHierarchyHelp";
{
  /*Benefit Plan routes end */
}
{
  /*Systen list and system parameter routes start */
}
export const SYSTEM_LIST = "/SystemList";
export const SYSTEM_LIST_ADD = "/SystemListAdd";
export const SYSTEM_LIST_VIEW = "/SystemListView";
export const SYSTEM_PARAMETER = "/SystemParameter";
export const SYSTEM_PARAMETERS_ADD = "/SystemParametersAdd";
export const SYSTEM_PARAMETERS_EDIT = "/SystemParametersEdit";
export const SYSTEM_LIST_ADD_UPDATE = "/SystemListAddUpdate";
{
  /*Systen list and system parameter routes end */
}

export const SEARCH_PROCEDURE_CODE = "/SearchProcedureCode";
export const PROCEDURE_CODE_EDIT = "/ProcedureCodeEdit";
export const PROCEDURE_CODE_ADD = "/ProcedureCodeAdd";
export const PROCEDURE_CODE_DETAILS = "/ProcedureCodeDetails";
{
  /*Procedure Code routes end */
}

{
  /*DRGCodeSearch routes start */
}
export const DRG_CODE_SEARCH = "/DRGCodeSearch";
export const DRG_CODE_ADD = "/DRGCodeAdd";
export const DRG_CODE_EDIT = "/DRGCodeEdit";
{
  /*DRGCodeSearch routes end */
}
export const RECOVERY_DETAILS = "/RecoveryDetails";
{
  /*Revenue Code routes start */
}
export const SEARCH_REVENUE_CODE = "/SearchRevenueCode";
export const REVENUE_CODE_ADD = "/RevenueCodeAdd";
export const REVENUE_CODE_EDIT = "/RevenueCodeEdit";
export const REVENUE_CODE_DETAILS = "/RevenueCodeDetails";
{
  /*Revenue Code routes end */
}

export const COS = "/COS";
export const PLACE_OF_SERVICE = "/Placeofservice";
export const BENEFIT_PLAN_ID = "/benefitPlanId";
export const PROVIDERID_NETWORKID = "/ProviderIdNetworkId";
export const PROVIDER_SPECIALITY = "/ProviderSpecialty";
export const PROVIDER_TAXONOMY = "/ProviderTaxonomy";
export const PROVIDERID_NETWORKID_BENEFITPLANID =
  "/ProviderIdNetworkIdBenefitPlanID";
export const REVENUE_TYPE_PROVIDER_TYPE = "/RevenueTypeProviderType";
export const REVENUE_TYPE_PROVIDERID_NETWORKID_REGION =
  "/RevenueTypeProviderIdNetworkIdRegion";
export const REVENUE_TYPE_BENEFIT_PLANID = "/RevenueCodeBPID";
export const REVENUE_TYPE_PROVIDERID_NETWORKID =
  "/RevenueTypeProviderIdNetworkId";

// Provider type
export const PROVIDER_TYPE = "/ProviderType";
export const PROCEDURE_CODE_PROVIDER_TYPE = "/ProcedureCodeProviderType";
export const PROCEDURE_CODE_PROVIDER_TYPE_DETAILS =
  "/ProcedureCodeProviderTypeDetails";
export const PROCEDURE_CODE_PROVIDER_TYPE_ADD = "/ProcedureCodeProviderTypeAdd";
export const PROCEDURE_CODE_PROVIDER_TYPE_EDIT =
  "/ProcedureCodeProviderTypeEdit";

{
  /* diagnosis code routes*/
}
export const DIAGNOSIS_CODE_DETAILS = "/DiagnosisCodeDetails";
export const DIAGNOSIS_CODE_SEARCH = "/DiagnosisCodeSearch";
export const DIAGNOSIS_CODE_EDIT = "/DiagnosisCodeEdit";
export const DIAGNOSIS_CODE_ADD = "/DiagnosisCodeAdd";
export const DRG_CODE = "/DRGCode";
export const ICD_SURGICAL_CODE_SEARCH = "/ICDSurgicalCodeSearch";
export const ICD_SURGICAL_CODE_EDIT = "/ICDSurgicalCodeEdit";
export const ICD_SURGICAL_CODE_ADD = "/ICDSurgicalCodeAdd";
export const PROCEDURE_CODE_SEARCH = "/ProcedureCodeSearch";
export const MAP_DEFINITION = "/MapDefinition";
export const TEXT_MANAGEMENT = "/TextManagement";
export const CUSTOMER_MANAGEMENT = "/CustomerManagement";
export const CUSTOMER_MANAGEMENT_DETAILS = "/CustomerManagementDetails";

export const LINE_OF_BUSINESS = "/LineofBusiness";
export const LINE_OF_BUSINESS_DETAILS = "/LineofBusinessDetails";

export const NETWORK = "/Network";
export const BATCH_CONTROL_SEARCH = "/BatchControlSearch";
export const ENTRY = "/Entry";
export const DENTAL_CLAIM_ENTRY = "/DentalClaimEntry";
export const PROFESSIONAL_CLAIM_ENTRY = "/ProfessionalClaimEntry";
export const INSTITUTIONAL_CLAIM_ENTRY = "/InstitutionalClaimEntry";
export const ADJUSTMENT_CLAIM = "/AdjustmentClaim";
{
  /* "/BatchControlSearch" component={BatchControlSearch} /> */
}
export const BATCH_CONTROL_DETAILS = "/BatchControlDetails";
export const READ_RESOLUTION = "/ReadResolution";
export const INQUIRY = "/Inquiry";
export const INQUIRY_DETAILS = "/InquiryDetails";
export const CORRECTION = "/Correction";
export const CORRECTION_DETAILS = "/CorrectionDetails";
export const PROFESSIONAL_CORRECTION_DETAILS = "/ProfessionalCorrectionDetails";
export const INSTITUTIONAL_CORRECTION_DETAILS =
  "/InstitutionalCorrectionDetails";
export const MASS_ADJUSTMENTS = "/MassAdjustments";
export const MA_CLAIM_SELECTION = "/MAClaimselection";
export const SUSPENSE_REELASE = "/SuspenseReelase";
export const SA_DETAIL = "/SADetail";
export const SERVICE_AUTHORIZATION = "/ServiceAuthorization";
export const SERVICE_AUTHORIZATION_DETAILS = "/ServiceAuthorizationDetails";
export const MASS_ADJ_DETAILS = "/ClaimMassAdjDetails";
export const CLAIM_TYPE_ASSIGNMENT = "/ClaimTypeAssignment";
export const CLAIM_TYPE_ASSIGNMENT_EDIT = "/ClaimTypeAssignmentEdit";
export const CLAIM_TYPE_ASSIGNMENT_ADD = "/ClaimTypeAssignmentAdd";
export const DUPLICATE_CHECK = "/DuplicateCheck";
export const DUPLICATE_CHECK_DETAILS = "/DuplicateCheckDetails";
export const FISCAL_PEND = "/FiscalPend";
export const FUND_CODE = "/FundCode";
export const TPL_MATRIX = "/TPLMatrix";
export const LOCKING = "/Locking";
export const HISTORY_REQUEST = "/HistoryRequest";
export const CAPITATION_VOID_AND_ADJUSTMENT = "/CapitationVoidandAdjustment";
//export const EPSDT_DETAILS = "/EPSDTDetails";
export const EPSDT_DETAILS = "/EPSDTDetails";
export const EPSDT_MEMBER_SEARCH = "/EpsdtMemberSearch";
export const BILLING = "/Billing";
export const PERIODICITY_SCHEDULE = "/PeriodicitySchedule";
export const PLAN_HIERARCHY = "/PlanHierachy";
export const CARRIER = "/Carrier";
export const CARRIER_DETAILS = "/CarrierDetails";
export const POLICY = "/Policy";
export const RECOVERY_CASE = "/RecoveryCase";
export const HIPP_INFORMATION = "/HIPPInformation";
export const POLICY_DETAILS = "/PolicyDetails";
export const MEDICARE_DETAILS="/MedicareDetails"
export const MANAGE_MEMBER_POLICY = "/ManageMemberPolicy";
export const CHANGE_PASSWORD = "/ChangePassword";
export const MY_ACCOUNT = "/MyAccount";
export const MANAGE_USER = "/ManageUSerAddEdit";
export const MANAGE_ACCOUNT_ADDEDIT = "/ManageUserAddEdit";
export const USER_SEARCH = '/ManageUserSearch';
export const LIMIT_COPAY_MEMBER = "/BenefitServiceLimitsCopayHistory";
export const MEMBER_ELEIGIBILITY_QUICK_VIEW = "/EligibilityQuickViewInquiry";
export const MEMBER_ADD_NEW_MEMBER = "/AddaNewMember";
export const MEMBER_ENROLMENT_SPAN = "/EnrollmentSpans";
export const MEMBER_ABSENT_INFORMATION = "/AbsentParentInformation";
export const MEMBER_ALTERNATE_ID = "/AlternateIDSpans";
export const MEMBER_ELIGIBLITY_SPANDOWN = "/EligibilityCOESpansSpenddown";

{
  /* Rates routes*/
}
export const REVENUE_CODE_PROVIDER_TYPE = "/RevenueCodeProviderType";
export const PROCEDURE_CODE_BPID = "/ProcedureCodeBPID";
export const PROCEDURE_CODE_COS = "/ProcedureCodeCOS";
export const NATIONAL_DRUG_CODE = "/NationalDrugCode";
export const NATIONAL_DRUG_CODE_DETAILS = "/NationalDrugCodeDetails";
export const HELP = "/Help";

export const PROCEDURE_CODE_COS_ADD = "/ProcedureCodeCOSAdd";
export const PROCEDURE_CODE_PLACE_OF_SERVICE = "/ProcedureCodePlaceofService";
export const PROCEDURE_CODE_PLACE_OF_SERVICE_ADD =
  "/ProcedureCodePlaceofServiceAdd";
export const PROCEDURE_CODE_PLACE_OF_SERVICE_EDIT =
  "/ProcedureCodePlaceofServiceEdit";
export const PROCEDURE_CODE_PROVIDERID_NETWORK_ID =
  "/ProcedureCodeProviderIDNetworkID";
export const PROCEDURE_CODE_PROVIDERID_NETWORKID_BP =
  "/ProcedureCodeProviderIDNetworkIDBP";
export const PROCEDURE_CODE_PROVIDERID_NETWORKID_BP_ADD =
  "/AddProcedureCodeProviderIDNetworkIDBP";
export const PROCEDURE_CODE_PROVIDERID_NETWORKID_BP_UPDATE =
  "/UpdateProcedureCodeProviderIDNetworkIDBP";
// Procedure Code Taxonomy Starts
export const PROCEDURE_CODE_TAXONOMY = "/ProcedureCodeTaxonomy";
export const PROCEDURE_CODE_TAXONOMY_DETAILS = "/ProcedureCodeTaxonomyDetails";
export const PROCEDURE_CODE_COS_DETAILS = "/ProcedureCodeCosDetails";
// Procedure Code Taxonomy Ends
export const PROCEDURE_CODE_PROVIDER_SPECIALITY =
  "/ProcedureCodeProviderSpecialty";
export const PROCEDURE_CODE_PROVIDER_SPECIALITY_DETAILS =
  "/ProcedureCodeProviderSpecialtyDetails";
export const PROCEDURE_PROVIDER_SPECIALITY_ADD = "/ProcedureProviderAdd";
export const PROCEDURE_CODE_PROVIDER_SPECIALITY_EDIT = "/ProcedureProviderEdit";

export const REVENUE_CODE_TYPE_PROVIDER_ID_NETWORD_ID_REG_ID =
  "/RevenueCodeProviderIDNetworkIDRegion";
export const REVENUE_CODE_TYPE_PROVIDER_ID_NETWORD_ID_REG_ID_ADD =
  "/AddRevenueCodeProviderIdNetworkIdRegion";
export const REVENUE_CODE_TYPE_PROVIDER_ID_NETWORD_ID_REG_ID_EDIT =
  "/EditRevenueCodeProviderIdNetworkIdRegion";
export const DRUG_ENFORCEMENT_AGENCY = "/DrugEnforcementAgency";
export const DRUG_ENFORCEMENT_AGENCY_DETAILS = "/DrugEnforcementAgencyDetails";

{
  /* User login */
}
export const USER_LOGIN = "/UserLogin";

{
  /* Member Summary Search */
}
export const MEMBER_SUMMARY_INQUIRY = "/MemberSummaryInquiry";
export const CASE_SEARCH_MANAGMENT = "/CaseAssistanceGroupSpans";
export const CASE_MEMBER_DETAILS = "/caseMemberDetails";
{
  /* Documents URL*/
}
export const DOCUMENTS = "/ViewDocuments";

{
  /* Notes URL*/
}
export const NOTES = "/Notes";

{
  /* CATEGORY OF SERVICES URL */
}
export const CATEGORY_OF_SVC_ASSIGNMENT = "/CategoryofSvcAssignment";
export const CATEGORY_OF_SVC_ASSIGNMENT_ADD = "/CategoryofSvcAdd";
export const CATEGORY_OF_SVC_ASSIGNMENT_EDIT = "/CategoryofSvcEdit";
export const TEXT_MANAGEMENT_SEARCH = "/TextManagementSearch";

{
  /* MEDICAL CRITERIA */
}
export const MEDICAL_CRITERIA_ADD = "/MedicalCriteriaAdd";
export const MEDICAL_CRITERIA_EDIT = "/MedicalCriteriaEdit";

{
  /* MEDICAL PARAMETER */
}
export const MEDICAL_CONTRA_PARAMETER_ADD = "/MedicalContraParameterAdd";
export const MEDICAL_CONTRA_PARAMETER_EDIT = "/MedicalContraParameterEdit";

{
  /* UR System List */
}
export const UR_SYSTEMLIST_DG_EDIT = "/URDiagnosisEdit";
export const UR_SYSTEMLIST_DG_ADD = "/URDiagnosisAdd";
export const UR_SYSTEMLIST_REV_EDIT = "/URRevenueEdit";
export const UR_SYSTEMLIST_REV_ADD = "/URRevenueAdd";
export const UR_SYSTEMLIST_PROC_ADD = "/URProcedureAdd";
export const UR_SYSTEMLIST_PROC_EDIT = "/URProcedureEdit";
export const MEMBER_REPRESENTATIVE_DETAILS = "/MemberRepresentativeDetails";

// CLAIM EXCEPTION
export const SEARCH_CLAIM_EXCEPTION = "/ClaimException";
export const CLAIM_EXCEPTION_DETAILS = "/ClaimExceptionDetails";
export const CLAIM_EXCEPTION_ADD = "/ClaimExceptionAdd";
export const CLAIM_EXCEPTION_EDIT = "/ClaimExceptionEdit";

export const PROC_BENEFITPLANID_ADD = "/ProcBenefitPlanIDAdd";
export const PROC_BENEFITPLANID_EDIT = "/ProcBenefitPlanIDEdit";

export const PROCEDURE_CODE_PROVIDERID_NETWORK_ID_DETAILS =
  "/ProcedureCodeProviderIDNetworkIDDetails";

export const INSTITUTIONAL_PROVIDERID_NETWORKID_BPID =
  "/InstitutionalProviderIDNetworkIDBPID";

export const REVENUE_CODE_PROVIDERID_NETWORK_ID_DETAILS =
  "/RevenueCodeProviderIDNetworkIDDetails";
export const REVENUE_TYPE_BENEFIT_PLANID_DETAILS = "/RevenueCodeBPIDDetails";
export const REVENUE_CODE_TYPE_PROVIDER_ID_NETWORD_ID_REG_ID_DETAILS =
  "/RevenueCodeProviderIDNetworkIDRegionDetails";
export const REVENUE_PROVIDERTYPE_DETAILS = "/RevenueProviderTypeDetails";
export const REVENUE_CODE_PROVIDER_TYPE_ADD = "/RevenueCodeProviderTypeAdd";
export const REVENUE_CODE_PROVIDER_TYPE_EDIT = "/RevenueCodeProviderTypeEdit";

export const REVENUE_TYPE_PROVIDERID_NETWORKID_ADD =
  "/RevenueTypeProviderIdNetworkIdAdd";
export const REVENUE_TYPE_PROVIDERID_NETWORKID_EDIT =
  "/RevenueTypeProviderIdNetworkIdEdit";
export const REVENUE_TYPE_PROVIDERID_NETWORKID_DETAILS =
  "/RevenueTypeProviderIdNetworkIdDetails";
export const CLAIM_TPL_MATRIX_ADD = "/ClaimTplMatrixAdd";
export const INSTITUTIONAL_PROVIDER_DETAILS = "/InstitutionalProviderDetails";

export const TPL_MASS_CHANGE_REQUEST = "/MassChangeRequest";
export const TPL_MASS_CHANGE_ADD = "/MassChangeNewRequest";
export const TPL_MASS_CHANGE_UPDATE = "/MassChangeEditRequest";
export const TRADING_PARTNER_INQUIRY = "/TradingPartnerInquiry";
export const TRADING_PARTNER_INQUIRY_DETAILS = "/TradingPartnerInquiryDetails";
export const PERIODICITY_SCHEDULE_DETAILS = "/periodicityScheduleDetails";
export const PERIODICITY_SCHEDULE_ADD = "/periodicityScheduleAdd";
export const PERIODICITY_SCHEDULE_EDIT = "/periodicityScheduleEdit";
export const PERIODICITY_SCHEDULE_VIEW = "/periodicityScheduleView";
export const NETWORK_DETAILS = "/NetworkDetails";
export const MASS_ADJ_REQUEST_DETAILS = "/MassAdjustmentsRequestDetails";
export const MASS_ADJUSTMENT_CLAIM_SELECTION = "/MassAdjustmentClaimSelection";
export const PRIVACY_POLICY = "/PrivacyPolicy";
export const SITE_MAP = "/SiteMap";
export const TERMS_OF_USE = "/TermsOfUse";
export const BROWSER_REQUIREMENTS = "/BrowserRequirements";
export const ACCESSIBILITY_COM = "/AccessibilityCompliance";
export const ADJUDICATION_CONTROL = "/AdjudicationControl";
export const ADJUDICATION_CONTROL_DETAILS = "/AdjudicationControlDetails";
export const ADJUDICATION_CONTROL_ADD = "/AdjudicationControlAdd";
export const ADJUDICATION_CONTROL_EDIT = "/AdjudicationControlEdit";
export const ADJUDICATION_CONTROL_VIEW = "/AdjudicationControlView";
export const EVENT_LOGGING = "/EventLogging";
export const CLEARING_HOUSE = "/SearchEncounterClaims";
export const TPL_UPDATE_BILLING = "/TplUpdateBilling";
