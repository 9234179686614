/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React from "react"
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as ErrorConst from "../../../SharedModules/Messages/ErrorMsgConstants_copy";
import * as TextConst from "../userSearchConstant";
import Checkbox from '@material-ui/core/Checkbox';
function UserIdForm(props) {
    React.useEffect(()=>{
        setTimeout(()=>{props.userIDRef && props.userIDRef.current.focus()},200)
    },[])
    return (
        < >        
        <form autoComplete="off">
            <div className="mt-3">
                <div className="form-wrapper p-0">
                <div className="mui-custom-form input-md ">
                <TextField
                        id="proc-code-mue"
                        label="User ID"
                        autoFocus={true}
                        inputRef={props.userIDRef}
                        className="inline-lable-ttl"
                        value={props.values && props.values.userId}
                        inputProps={{ maxLength: 30 , style:{textTransform:'uppercase'}}}
                        onChange={props.handleChanges('userId')}
                        placeholder=" "
                        helperText={props.userIdReqErr ? ErrorConst.USER_ID_REQ_ERR :  null}
                        InputLabelProps={{
                            shrink: true,
                            required:true
                        }}
                        error={props.userIdReqErr ? ErrorConst.USER_ID_REQ_ERR  : null}
                    />
                   
                </div>                
                </div>
            </div>
            <div className="tabs-container pb-3">
            <div className="tab-header pt-3">
                    <div className="float-right th-btnGroup mb-2">
                        <Button
                            title={TextConst.SEARCH_BUTTON_LABEL}
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-search"
                            id="search-btn"
                            onClick={props.searchCheck}
                        >
                            {' '}
                            {TextConst.SEARCH_BUTTON_LABEL}
                    {' '}

                        </Button>
                        <Button
                            title={TextConst.RESET_BUTTON_LABEL}
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-reset"
                            id="reset-btn"
                            onClick={props.resetTable}
                        >
                            {' '}
                            {TextConst.RESET_BUTTON_LABEL}
                    {' '}

                        </Button>
                    </div>
                    <div className="clearfix" />
                </div>
                

            </div>
            
        </form>
        </>
    )
}


export default UserIdForm;