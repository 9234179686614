import React from 'react';
import "../../theme.scss";
import axios from "axios";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import * as ErrorMsgConstants from "../../SharedModules/Messages/ErrorMsgConstants";
import * as TextConst from "../../SharedModules/Messages/userSearchConstant";
import * as ServiceEndpoint from "../../SharedModules/services/service";

function ManageAccountChangePassword(props){
  const [
    {
      currePassReqErr,
      newPassReqErr,
      confirmNewPassReqErr,
      passwordMinLenErr,
      password3Of4Err,
      newConfirmSameErr,
      currentPwdNewPwdNotSameErr,
      currPassInValidErr,
    },
    setShowErr,
  ] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
    const defaultFormValue = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      };

    const [formValue, setformValue] = React.useState(defaultFormValue);

    const handleChange = (name) => (event) => {
      setformValue({ ...formValue, [name]: event.target.value });
    };

    const resetForm = () =>{
      setShowErr(false);
      seterrorMessages([]);
      props.setErrorMessages([])
      setformValue(defaultFormValue)
    }

    const onchangePass = () => {   
      // Chnage Password API Implementation
      const data = {
        userID: props.userData._id,
        oldPassword: formValue.oldPassword,
        newPassword: formValue.newPassword,
      };  
      let config = {
        headers: {
          'X-CMDS-APP-CODE' : process.env.REACT_APP_CMDS_APP_CODE,
          Authorization: localStorage.getItem("accessToken"),
          Username: localStorage.getItem("username"),
        },
      };
      setShowErr(false);  
      if (formValidation()) {
        props.setspinnerLoader(true);
        axios
          .post(`${ServiceEndpoint.CHANGE_PWD_ENDPOINT}`, data ,config)
          .then((res) => {
            if (res.data.statusCode === "01") {
              props.setspinnerLoader(false);
              seterrorMessages([]);
              props.setErrorMessages([]);            
              props.setSuccessMessages([
                "Password has been changed successfully for the user " +
                  `${props.userData._id}` +
                  ". The session remains active and the user may proceed.",
              ]);
              setformValue(defaultFormValue)
            } else if (
              res.data.statusCode === "02" &&
              res.data.statusDescription === ErrorMsgConstants.PREVIOUS_FIVE_PWD
            ) {
              props.setspinnerLoader(false);
              seterrorMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.PASSWORD_5_TIMES]);
            } else if (
              res.data.statusCode === "02" &&
              res.data.statusDescription !== ErrorMsgConstants.PREVIOUS_FIVE_PWD
            ) {
              props.setspinnerLoader(false);
              seterrorMessages([]);
              props.setSuccessMessages([]);
              setShowErr({ currPassInValidErr: true });
              props.setErrorMessages([ErrorMsgConstants.CURRENT_PASS_INVALID]);
            }
            else if (
              res.data.statusCode === "Unauthorized" &&
              (res.data.statusDescription === ErrorMsgConstants.CURRENT_PASSWORD_FAIL 
                || res.data.statusDescription === ErrorMsgConstants.AFTER_ONE_MORE_ATTEMPT 
                || res.data.statusDescription === ErrorMsgConstants.AFTER_TWO_MORE_ATTEMPT)
            ) {
              props.setspinnerLoader(false);
              seterrorMessages([]);
              props.setSuccessMessages([]);
              setShowErr({ currPassInValidErr: true });
              props.setErrorMessages([ErrorMsgConstants.CURRENT_PASS_INVALID]);
            }
            else if (
              res.data.statusCode === "Unauthorized" &&
              res.data.statusDescription === ErrorMsgConstants.USER_LOCKED
            ) {
              props.setspinnerLoader(false);
              seterrorMessages([]);
              props.setSuccessMessages([]);
              props.setErrorMessages([ErrorMsgConstants.ACCOUNT_LOCKED]);
            }
          })
          .catch((e) => {
            console.log("catche....", e);
            props.setErrorMessages([ErrorMsgConstants.ERROR_OCCURED_DURING_TRANSACTION])
          });
      }
    };
  
    const formValidation = () => {
      seterrorMessages([]);
      props.setErrorMessages([]);
      props.setSuccessMessages([]);
      props.setspinnerLoader(false);
      let reqFieldArr = [];
      let reqPassword3of4 = [];
      var currePassReqErr,
        newPassReqErr,
        confirmNewPassReqErr,
        passwordMinLenErr,
        password3Of4Err,
        newConfirmSameErr,
        currentPwdNewPwdNotSameErr;
  
      if (formValue.oldPassword === "") {
        currePassReqErr = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_REQ);
      }
      if (formValue.newPassword === "") {
        newPassReqErr = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_NEW_REQ);
      }
      if (formValue.newPassword !== "" && formValue.newPassword.length < 10) {
        passwordMinLenErr = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_LEN);
      }
      if (formValue.newPassword !== "" && formValue.newPassword.length >= 10) {
        if (/^(?=.*[a-z])/.test(formValue.newPassword)) {
          reqPassword3of4.push("lower");
        }
        if (/^(?=.*[A-Z])/.test(formValue.newPassword)) {
          reqPassword3of4.push("upper");
        }
        if (/^(?=.*[?=.*\d])/.test(formValue.newPassword)) {
          reqPassword3of4.push("number");
        }
        if (/^(?=.*?[^\w\s])/.test(formValue.newPassword)) {
          reqPassword3of4.push("specialChar");
        }
        if (reqPassword3of4.length < 3) {
          password3Of4Err = true;
          reqFieldArr.push(ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED);
        }
      }
      if (formValue.confirmNewPassword === "") {
        confirmNewPassReqErr = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_CONFIRM_REQ);
      }
      if (
        formValue.newPassword !== "" &&
        formValue.newPassword.length >= 10 &&
        reqPassword3of4.length >= 3 &&
        formValue.confirmNewPassword !== "" &&
        formValue.newPassword !== formValue.confirmNewPassword
      ) {
        newConfirmSameErr = true;
        reqFieldArr.push(ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME);
      }
      if (formValue.newPassword === formValue.oldPassword && formValue.newPassword !== '' && formValue.oldPassword !== '') {
        //BR-990300005
        currentPwdNewPwdNotSameErr = true;
        reqFieldArr.push(ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR);
      }
      if (reqFieldArr.length) {
        setShowErr({
          currePassReqErr: currePassReqErr,
          newPassReqErr: newPassReqErr,
          confirmNewPassReqErr: confirmNewPassReqErr,
          passwordMinLenErr: passwordMinLenErr,
          password3Of4Err: password3Of4Err,
          newConfirmSameErr: newConfirmSameErr,
          currentPwdNewPwdNotSameErr: currentPwdNewPwdNotSameErr,
        });
        props.setErrorMessages(reqFieldArr);
        seterrorMessages(reqFieldArr);
        return false;
      }
      return true;
    };

    return(
        <>
        {/* <div className="myAccount"> */}
        <div className="text-right th-btnGroup mb-3">
          <Button
            title="Save"
            variant="outlined"
            color="primary"
            className="btn btn-ic btn-save1"
            // disabled={props.privileges && !props.privileges.add }
           onClick={onchangePass}
          >
            Save
          </Button>
          <Button
            title="Reset"
            variant="outlined"
            color="primary"
            onClick={resetForm}
            className="btn btn-ic btn-reset"
          >
            Reset
          </Button>
        </div>
        <div className='border p-4 rounded-lg'>
        <div className='row'>
          <div className='col-md-6'>
          <div className="form-wrapper ">
          <div className="mui-custom-form input-md field-md">
            <TextField
              required
              autoFocus
              id="current-passwprd"
              type="password"
              label="Current Password"
              className="inline-lable-ttl"
              value={formValue.oldPassword}
              onChange={handleChange("oldPassword")}
              inputProps={{ maxLength: 30 }}
              placeholder=""
              helperText={
                currePassReqErr
                  ? ErrorMsgConstants.PASSWORD_REQ
                  : currPassInValidErr
                  ? ErrorMsgConstants.CURRENT_PASS_INVALID
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}

              error={currePassReqErr ? ErrorMsgConstants.PASSWORD_REQ : currPassInValidErr
                ? ErrorMsgConstants.CURRENT_PASS_INVALID : null}
            />
          </div>         
          </div>
          <div className="form-wrapper ">
          <div className="mui-custom-form with-select input-md field-md">
            <TextField
              required
              id="new-password"
              type="password"
              label="New Password"
              value={formValue.newPassword}
              inputProps={{ maxLength: 30 }}
              onChange={handleChange("newPassword")}
              placeholder=""
              className="inline-lable-ttl"
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                newPassReqErr
                  ? ErrorMsgConstants.PASSWORD_NEW_REQ
                  : passwordMinLenErr
                  ? ErrorMsgConstants.PASSWORD_LEN
                  : password3Of4Err
                  ? ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED
                  : currentPwdNewPwdNotSameErr
                  ? ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR
                  : null
              }
              error={
                newPassReqErr
                  ? ErrorMsgConstants.PASSWORD_NEW_REQ
                  : passwordMinLenErr
                  ? ErrorMsgConstants.PASSWORD_LEN
                  : password3Of4Err
                  ? ErrorMsgConstants.PASSWORD_POLICY_NOT_MATCHED
                  : currentPwdNewPwdNotSameErr
                  ? ErrorMsgConstants.NEW_PWD_CURRENT_PWD_ERR
                  : null
              }
            /> 
          </div>
          <div className="mui-custom-form with-select input-md field-md">
            <TextField
              required
              id="confirm-new-password"
              type="password"
              label="Confirm New Password"
              value={formValue.confirmNewPassword}
              inputProps={{ maxLength: 30 }}
              onChange={handleChange("confirmNewPassword")}
              className="inline-lable-ttl"
              placeholder=""
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                confirmNewPassReqErr
                  ? ErrorMsgConstants.PASSWORD_CONFIRM_REQ
                  : newConfirmSameErr
                  ? ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME
                  : null
              }
              error={
                confirmNewPassReqErr
                  ? ErrorMsgConstants.PASSWORD_CONFIRM_REQ
                  : newConfirmSameErr
                  ? ErrorMsgConstants.PASSWORD_AND_CONFIRM_SAME
                  : null
              }
            />
          </div>
        </div>
          </div>
        <div className='col-md-5 offset-1'>
        <div className="password-guide my-3 py-5 px-2">
          <ul>
            <li>{TextConst.PAASWORD_MIN}</li>
            <li>{TextConst.PAASWORD_LAST_FIVE}</li>
            <li>
              {TextConst.PAASWORD_CONTAIN}
              <ul>
                <li>{TextConst.PAASWORD_UPPER}</li>
                <li>{TextConst.PAASWORD_lOWER}</li>
                <li>{TextConst.PAASWORD_NUMBER}</li>
                <li>{TextConst.PAASWORD_ALPHA}</li>
              </ul>
            </li>
          </ul>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}
export default ManageAccountChangePassword