import React,{useRef} from "react";
import Spinner from "../../SharedModules/Spinner/Spinner";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { setPrintLayout } from "../../SharedModules/Dropdowns/actions";
import { Button, Dropdown } from "react-bootstrap";
const Iframe = ({ source }) => {
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  
  const src = source;
  const dispatch = useDispatch();
  const printRef = useRef();
  const handleLink = () => {
    window.location = "/";
  }
  if (!source) {
    return <div>Loading...</div>;
  }
  return (
   <>
      {spinnerLoader ? <Spinner /> : null}
      <div>
      <header className="sso-page-header">
    <img
      src="./images/logo2.png"
      alt={process.env.REACT_APP_PRODUCT_TITLE}
      className="portal-logo"
    />
    <span className="logo-text"> {process.env.REACT_APP_PRODUCT_TITLE} </span>
  </header>
  
  <div className="tabs-container">     
  <div className="tab-header mx-3">
    <div className="float-left">
    <button title="Home" className="btn btn-ic btn-primary btn-home" onClick={() => {
                      handleLink();
                    }}>Home</button>
    </div>
    <div className="float-right th-btnGroup">
    <ReactToPrint
              onBeforeGetContent={() => {
                dispatch(setPrintLayout(true));
                setspinnerLoader(true);
                return new Promise((resolve) =>
                  setTimeout(() => resolve(), 100)
                );
              }}
              onAfterPrint={() => {
                setspinnerLoader(false);
                dispatch(setPrintLayout(false));
              }}
              trigger={() => (
                <Button
                  title="Print"
                  variant="outlined"
                  color="primary"
                  className="btn btn-ic btn-primary btn-print btn-print-n"
                >
                  PRINT
                </Button>
              )}
              content={() => printRef.current}
            />
    </div>
  </div>
  
  </div>
  
      <div className="iframe-container mx-3" ref={printRef}>

      <iframe
        src={src}
        style={{ width: "100%", height: "500px", scrollBehavior: "auto", border: "0" }}
      ></iframe>
   </div>
   </div>
   </>
  );
};
export default Iframe;
