/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function DisplaySuccessMessages (props) {
  useEffect(() => {
    const timer = setTimeout(function () {
        props.setSuccessMessages([]);
      }.bind(this), 10000);
      return () => clearTimeout(timer);
});

  return (<div>{props.successMessages.length > 0 ? <div className="alert alert-success custom-alert hide-on-print" role="alert">
    {props.successMessages.map((message, index) => <li key ={index}>{message}</li>)
    }
  </div> : null
  }</div>);
}

DisplaySuccessMessages.propTypes = {
  successMessages: PropTypes.array.isRequired
};
