import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as serviceEndPoint from '../../SharedModules/services/service';

export const dispatchUserSearch = (response,fieldsValue) => ({
    type: actionTypes.SEARCH_USER,
    searchData: response,
    fieldsValue:fieldsValue
});
export const SearchByNameAction = (values,fieldsValue) => async dispatch => {
    return await axios.post(serviceEndPoint.USER_SEARCH, values)
    .then(response => {
        if (response.data.statusCode == "01") {
            dispatch(dispatchUserSearch(response.data,fieldsValue));
        } else {
            dispatch(dispatchUserSearch(response.data));
        }
    })
    .catch(error => {
        dispatch(dispatchUserSearch(error));
    })
}
export const resetByNameAction = () => ({
    type: actionTypes.RESET_USER,
    resetData: []
});

export const resetByIdAction = () => ({
    type: actionTypes.RESET_USER_BY_ID,
    resetData: []
});

export const dispatchSearchById = (response,fieldsValue ) => ({
    type: actionTypes.SEARCH_USER_BY_ID,
    searchData: response,
    fieldsValue:fieldsValue
});

export const searchByIdAction = (values,fieldsValue) => async dispatch => { 
    return await axios.get(serviceEndPoint.USER_SEARCH_BY_ID+'/'+values.userId)
    .then(response => {
        if (response.data.statusCode == "01") {
            dispatch(dispatchSearchById(response.data,fieldsValue));
        } else {
            dispatch(dispatchSearchById(response.data));
        }
    })
    .catch(error => {
        dispatch(dispatchSearchById(error));
    })
}