import React from 'react';
import Bootstrap, { Button, Form } from 'react-bootstrap';
import {
    TextField,
    MenuItem
} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseDate, newDate } from "../DateUtilities/DateUtilities";
import * as ErrMsgConstants from '../Messages/ErrorMsgConstants';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from "@material-ui/core/InputLabel";

export default function NoteFilterForm(props) {
    const {
        filterFormValue,
        handleFilterApply,
        handleFilterReset,
        handleFilterCancel,
        handleFilterChange,
        handleDateChange,
        usageTypeCodeData,
        errors
    } = props;
    return (
        <div>
            <div className="tab-body-bordered container-space mb-3">
                <div className="tab-header">
                    <h3 className="tab-heading float-left">
                        Filter
                    </h3>
                    <div className="float-right th-btnGroup">
                        <Button
                            title="Apply"
                            variant="outlined"
                            color="primary"
                            className=""
                            className="btn btn-ic btn-update"
                            onClick={handleFilterApply}
                        >
                            Apply
                        </Button>
                        <Button
                            title="Reset"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-reset"
                            onClick={handleFilterReset}
                        >
                            Reset
                        </Button>
                        <Button
                            title="Cancel"
                            variant="outlined"
                            color="primary"
                            className="btn btn-cancel"
                            onClick={handleFilterCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="tab-body-bordered mt-2">
                    <form autoComplete="off">
                        <div className="form-wrapper">
                            <div className="flex-block">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div
                                        className="mui-custom-form input-md with-select">
                                        <KeyboardDatePicker
                                            id="begin_date_filter_note"
                                            name="beginDate"
                                            required
                                            label="Begin Date"
                                            format="MM/dd/yyyy"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            minDate={newDate("0001-01-01")}
                                            placeholder="mm/dd/yyyy"
                                            value={
                                                parseDate(filterFormValue.beginDate)
                                            }
                                            onChange={(dt, val) => {
                                                handleDateChange("beginDate", (dt && !isNaN(dt.getTime()) ? dt : parseDate(val)));
                                            }}
                                            // onChange={(dt, val) => {
                                            //     setRowValues({ ...rowValues, "beginDate": (dt && !isNaN(dt.getTime()) ? dt :  parseDate(val)) });
                                            // }}
                                            helperText={errors.bgDtReqErr ? ErrMsgConstants.Begin_Date_Error :
                                                errors.bgDtReqIErr ? ErrMsgConstants.The_Invalid_Begin_Date_Error :
                                                    errors.bDtGTEnDtErr ? ErrMsgConstants.BEGIN_DT_GRT_END_DT : null}
                                            error={errors.bgDtReqErr || errors.bgDtReqIErr || errors.bDtGTEnDtErr}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>


                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div
                                        className="mui-custom-form input-md with-select">
                                        <KeyboardDatePicker
                                            id="end_date_filter_note"
                                            name="endDate"
                                            required
                                            label="End Date"
                                            format="MM/dd/yyyy"
                                            minDate={newDate("0001-01-01")}
                                            maxDate={newDate('9999-12-31T13:00:00.000+0000')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="mm/dd/yyyy"
                                            value={
                                                parseDate(filterFormValue.endDate)
                                            }
                                            onChange={(dt, val) => {
                                                handleDateChange("endDate", (dt && !isNaN(dt.getTime()) ? dt : parseDate(val)));
                                            }}
                                            helperText={errors.enDtReqErr ? ErrMsgConstants.End_Date_Error :
                                                errors.enDtReqIErr ? ErrMsgConstants.The_Invalid_End_Date_Error : null}
                                            error={errors.enDtReqErr || errors.enDtReqIErr}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>

                                <div className="mui-custom-form with-select input-md">
                                    <TextField
                                        id="note_filter_userid"
                                        label="User ID"
                                        name="userId"
                                        value={filterFormValue.userId}
                                        inputProps={{ maxLength: 20 }}
                                        onChange={handleFilterChange('userId')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                {/* 
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    id="usage_code_filter_note"
                                    select
                                    label="Usage Type Code"
                                    value={filterFormValue.usageTypeCode ? filterFormValue.usageTypeCode : "-1"}
                                    onChange={handleFilterChange('usageTypeCode')}
                                    // onChange={(e) => { setRowValues({ ...rowValues, "rateSourceCode": e.target.value, "rateSourceDesc": e.nativeEvent.target.innerText }); }}
                                    placeholder="Please Select One"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem value="-1">Please Select One</MenuItem>
                                    {usageTypeCodeData
                                        ? usageTypeCodeData.map(option => (
                                            <MenuItem key={option.code} value={option.code}>
                                                {option.description}
                                            </MenuItem>
                                        ))
                                        : null}
                                </TextField>
                            </div> */}
                                <div className="mui-custom-form with-select input-md custom-input-select">
                                    <InputLabel htmlFor="usage_code_filter_note" title="Usage Type Code">Usage Type Code</InputLabel>

                                    <NativeSelect
                                        id="usage_code_filter_note"
                                        value={filterFormValue.usageTypeCode ? filterFormValue.usageTypeCode : "-1"}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={handleFilterChange('usageTypeCode')}
                                        placeholder="Please Select One"
                                        InputLabelProps={{
                                            shrink: true,
                                            name: 'name',
                                            id: 'usage_code_filter_note'
                                        }}
                                    >
                                        <option value="-1">Please Select One</option>
                                        {usageTypeCodeData
                                            ? usageTypeCodeData.map(option => (
                                                <option key={option.code} value={option.code}>
                                                    {option.description}
                                                </option>
                                            ))
                                            : null}
                                    </NativeSelect>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}