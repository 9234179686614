const ROUTE_HOST_ENV = process.env.REACT_APP_CLAIMS_URL+"/";
let NODE_APP_HOST_ADMIN_ENV         = process.env.NODE_APP_HOST_ADMIN_ENV
let NODE_APP_HOST_COMMON_ENV        = process.env.NODE_APP_HOST_COMMON_ENV
const ROUTE_HOST_ADMIN_ENV = NODE_APP_HOST_ADMIN_ENV;
const ROUTE_HOST_COMMON_ENV = NODE_APP_HOST_COMMON_ENV;
export const MAP_ID_DROPDOWN =
  ROUTE_HOST_ENV + "MapDefinition/getAllMapHeadersWithlob";
  export const NETWORK_DATA_DROPDOWN =
  ROUTE_HOST_ENV + "provider/inquiry/netWorkData";
  export const SA_REASON_CODE_DROPDOWN =
  ROUTE_HOST_ENV + "serviceAuthorization/getAllSAReasonCodes";
/* System List GetReferenceData End-Points START */
export const SYSTEM_LIST_GETREFERENCEDATA_ENDPOINT =
  ROUTE_HOST_ENV +"pgadminroute" +"SystemList/getReferenceData";
export const SYSTEM_LIST_NONVV_ENDPOINT =
  ROUTE_HOST_ENV +"pgadminroute" + "SystemList/getSystemListForNonVVDropDowns";

/* System List GetReferenceData End-Points END */

/* Drop Downs End-Points START */
export const GET_APP_DROPDOWN_ENDPOINT =
  ROUTE_HOST_ENV + "ValidValue/getdomainAllvalues";
export const GET_NETWORKID_DROPDOWN_ENDPOINT =
  ROUTE_HOST_ENV + "program/benefitPlan/getnetworkids";
/* Drop Downs End-Points END */

/* PROCEDURE CODE End-Points START */
export const PROCEDURE_CODE_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + "procedureCode/searchProcedureCode";

/* NORMAL LOGIN START */
export const USER_LOGIN_ENDPOINT = `${process.env.REACT_APP_ROUTE_ENV}security/auth/login`;

/* Change Password API*/
export const CHANGE_PWD_ENDPOINT = `${process.env.REACT_APP_ROUTE_ENV}usermgmt/changePassword`;

export const CHANGE_EXP_PWD_ENDPOINT = `${process.env.REACT_APP_ROUTE_ENV}usermgmt/changeExpiredPassword`;


export const LOGIN = ROUTE_HOST_COMMON_ENV + 'security/auth/login';
export const REFRESH_TOKEN = ROUTE_HOST_COMMON_ENV + 'security/token/refresh';
export const TOKEN_LOGOUT = ROUTE_HOST_COMMON_ENV + 'security/auth/logout';
export const TOKEN_LOGIN = ROUTE_HOST_COMMON_ENV + 'security/auth/tokenlogin';
  export const USER_SEARCH = ROUTE_HOST_ENV + 'routeusermgmt/searchUserByName';
export const REACT_APP_DROPDOWN_ENV =
  'http://10.142.124.253/systemList/getSystemList/codeList';
export const USER_ROLES_DROPDOWN = ROUTE_HOST_ENV + 'routeusermgmt/roles';
export const WORK_UNITS_DROPDOWN = ROUTE_HOST_ENV + 'routeusermgmt/workUnits';
export const USER_SEARCH_BY_ID = ROUTE_HOST_ENV + 'routeusermgmt/searchUserByID';
export const MANAGE_ACCOUNT_USER_DETAILS =
  ROUTE_HOST_ENV + 'routeusermgmt/userDetails/';
export const MANAGE_ORG_USER_DETAILS =
  ROUTE_HOST_ENV + 'routeusermgmt/userDetailsByOrgID/';
export const MANAGE_ACCOUNT_UPDATE_DETAILS =
  ROUTE_HOST_ENV + 'routeusermgmt/editUser';
export const MANAGE_ACCOUNT_SAVE_DETAILS = ROUTE_HOST_ENV + 'routeusermgmt/addUser';
export const MANAGE_ACCOUNT_RESET_PASSWORD_DETAILS =
  ROUTE_HOST_ENV + 'routeusermgmt/resetPassword/';
export const MANAGE_ACCOUNT_VALIDATE_USER =
  ROUTE_HOST_ENV + 'routeusermgmt/validateUser/';
export const CHANGE_PASSWORD = ROUTE_HOST_ENV + 'routeusermgmt/changePassword';
export const GET_USER_DATA = ROUTE_HOST_ENV + 'security/getUserData';
export const GET_USER_PRIVILEGES =
  ROUTE_HOST_ENV + 'UserAuth/getauthorizationsforUser';

  export const ADDRESS_VERIFY = ROUTE_HOST_ENV + 'USPS/validateAddress';
  export const DOWNLOAD_ACTION_LINK =
  ROUTE_HOST_COMMON_ENV +
  'enterprise/attachment/downloadAttachment';
  export const USER_LOGOUT = ROUTE_HOST_ADMIN_ENV + 'userManagement/UserLogout';
  

  /**  Footer Links**/
export const Policy = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/beforeLogin/policy.html?sv=2021-04-10&st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sr=f&sp=r&sig=nj2JsxoD%2BPPTWZdG0Xyd0avcG6UNMLksMeACeIdIJx4%3D";
export const AccessibilityCompliance = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/accessibilityCompliance.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=bU8pjWp9A3vOvBOZ3YyM2hVQ6Bra18jX8BOeBpY0UlI%3D";
export const AccessibilityComplianceExt = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/accessibilityComplianceExt.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=Y58I4XFUwtf8QEUoEH5uvAwGhEnj%2FPoVgz3qpNIU37Q%3D";
export const BrowserReq  = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/browserReq.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=FdksoiEu2pWTI%2B%2FRtsLhcOWlcJzni%2B5Rq576kC%2F6lSo%3D";
export const BrowserReqExt   = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/browserReqExt.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=Whgeah%2F9mgs%2Fxdv3Qczu3s0dExgB9MyQljReYfBwvZU%3D";
export const PrivacyPolicy   = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/privacyPolicy.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rl&sv=2018-03-28&sr=f&sig=1MfU87PD2SsvJwRhgqSJISEHf50v1v1b54yxJhzjJGM%3D";
export const TermsOfUse    = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/termsOfUse.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=KOkQJJqF3c0CVWhfJUljwf7IScezO%2BfFO3OOs4AZgX0%3D";
export const PrivacyPolicyExt    = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/privacyPolicyExt.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=29Lh6s8k72lNSiIqLFIuXwZR9cfjsSrNe1%2FjX0CYmnc%3D";
export const TermsOfUseExt    = "https://ghscmdsclaimdevstg01.file.core.windows.net/cmdsfileupload/wpcontentmanagement/templates/footer/termsOfUseExt.html?st=2023-01-01T00%3A00%3A00Z&se=4000-01-01T00%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=f&sig=a8vmtMT5ksFcC2jw4QD31eDma3huK2X%2Bw3aI9FK%2BRvQ%3D";

