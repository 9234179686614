import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Dropdown } from 'react-bootstrap'
import { Tooltip } from '@material-ui/core'
import '../theme.scss'
import ProfileCard from './MyAccount/ProfileCard'
import ProfileIcon from './MyAccount/ProfileIcon'

function Header (props) {
  let userData = JSON.parse(localStorage.getItem('loginState'))
  return (
    <div className=''>
      <header className='sso-page-header'>
        <Container fluid>
          <Row>
            <Col className='portal-logo'>
              <img
                src='./images/logo2.png'
                alt={process.env.REACT_APP_PRODUCT_TITLE}
                className='portal-logo'
              />
              <span className='logo-text'>
                {process.env.REACT_APP_PRODUCT_TITLE}
              </span>
            </Col>
          </Row>
        </Container>
        {/* {window.name} for testing  remove before push */}
        {(props.isLogin || props.checked == 'yes') && (
          <Dropdown className='user-action-btn absolute ml-auto nav-user-dropdown'>
            <Dropdown.Toggle
              variant='secondary'
              id='dropdown-basic'
              className={`float-right`}
            >
              <ProfileIcon userData={userData} />
            </Dropdown.Toggle>{' '}
            <Dropdown.Menu className='wid_320'>
              <ProfileCard
                showLink={props.showLink}
                navigateToMyAccount={props.navigateToMyAccount}
                userData={userData}
                logOut={props.logOut}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </header>
    </div>
  )
}

export default Header
