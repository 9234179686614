import * as actionTypes from './actionTypes';

const axiosPayLoad = {
  appdropdowns:[],
  networkIdDropdown:[],
  mapIDDropdown:[],
  nonvvdropdowns:[],
  benefitDropDowns:[]
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.GET_APP_DROPDOWNS:
      return { ...state, appdropdowns: action.dropdowns};  
      case actionTypes.GET_SYSTEMLIST_DROPDOWNS:
        return { ...state, sysdropdowns: action.sysdropdowns};  
    case actionTypes.GET_NETWORKID_DROPDOWN:
      return { ...state, networkIdDropdown: action.networkIdDropdown};    
    case actionTypes.GET_MAP_ID_DROPDOWN:
      return { ...state, mapIDDropdown:action.mapIDDropdown};
    case actionTypes.SET_PRINT_LAYOUT:
      return { ...state, printLayout: action.printLayout };
    case actionTypes.GET_SYSTEM_LIST_NONVV_DROPDOWNS:
      return {...state,nonvvdropdowns:action.nonvvdropdowns}
    case actionTypes.GET_SA_RSN_DATA_DROPDOWN:
      return {...state,saReasonDataDropdown:action.saReasonDataDropdown}
      case actionTypes.BENEFIT_PLAN_LIST:
        return {...state,benefitDropDowns:action.urcBenefitCodeList}
    case actionTypes.GET_NETWORK_DATA_DROPDOWN:
      return {...state,networkDataDropdown:action.networkDataDropdown}
    case actionTypes.SET_NAVIGATION_PRIVILEGES:
      {
      return {...state,privileges:action.privileges}
    }
    default: return action.type&&action.dropdowns?{...state,[action.type]:action.dropdowns}:state;
  }
};

export default reducer;
