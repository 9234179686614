/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import './navigationMenu.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link, Route } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Dropdown } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import routes from '../SharedModules/Navigation/Routes';
import TopLogo from '../../public/images/logo2.png';
import UserSearch from '../Modules/UserSearch/Components/index'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '../App.scss'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { checkUserActivity, userLogout } from '../SharedModules/Store/Actions/SharedAction';
import { CLAIMS_APP_CODE } from '../SharedModules/AppConstants';

const timedOutMsg = null

const drawerWidth = 285;
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      paddingTop: '0px',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerHeader1: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'center',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    containerFluid: {
      padding: 0,
    },
  }));
 function Manage_User(props){
  const dispatch= useDispatch()
  const classes = useStyles();
  const [timedOutMsg, setTimedOutMsg] = React.useState(null);  
  axios.interceptors.request.use(
    async function (config) {
      config.headers.Username = props.username;
      config.headers.Authorization = props.accessToken;
      config.headers['X-CMDS-APP-CODE'] = CLAIMS_APP_CODE;
      if (
        config.url.includes('getAllClaimsWorkflowContentsForUser') ||
        config.url.includes('cxf/api/')
      ) {
        return config;
      }
      config.timeout = 9 * 60 * 1000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let e;
      try {
        e = error.toJSON();
      } catch (err) {
        e = error;
      }
   if (e.code == 'ECONNABORTED' || error.message == 'Network Error') {
        setTimedOutMsg(
          'There was an error processing the request. Please retry the transaction.'
        );
      } else if (error?.message == 'Request failed with status code 401') {
        dispatch(userLogout());
        setTimeout(() => {
          window.location = '/';
        }, 1000);
      } else {
        setTimedOutMsg(null);
      }
      return Promise.reject(error);
    }
  );
const history1 = useHistory()


  const [mainClass,setMainClass]=useState("")
return(
    <>
     <div className=''>
                <div className={`drawerHeader-im flex-start`}>
                  <div
                    className={clsx(classes?.containerFluid, 'container-fluid')}
                  >
                    {timedOutMsg ? (
                      <div
                        className="alert alert-danger custom-alert"
                        role="alert"
                      >
                        <li>{timedOutMsg}</li>
                      </div>
                    ) : null}
                
                    <Route {...props}>{routes}</Route>
                  </div>
                </div>
    </div>
    </>
);

}

export default withRouter(Manage_User)