export const BREADCRUMB_PARENT = "My Account";
export const BREADCRUMB_CHILD1 = "Change Password";
export const PAGE_HEADING = "Change Password";
export const CHANGE = "CHANGE";
export const RESET = "RESET";
export const CANCEL = "CANCEL";
export const CURRENT_PASS = "Current Password";
export const NEW_PASS = "New Password";
export const CONFIRM_NEW_PASS = "Confirm New Password";
export const PAASWORD_MIN="Password must be a minimum of 10 characters.";
export const PAASWORD_LAST_FIVE="Password must be different from your last 5 passwords.";
export const PAASWORD_CONTAIN="Password must be contain at least 3 of the 4 following types:";
export const PAASWORD_UPPER="Uppercase letters";
export const PAASWORD_lOWER="Lowercase letter";
export const PAASWORD_NUMBER="Numbers";
export const PAASWORD_ALPHA="Non-Alphanumeric(!,$,#, or %)";

export const ADD_USER_BUTTON_TITLE = "Add New User";
export const ADD = "ADD";
export const LAST_NAME = "Last Name";
export const FIRST_NAME = "First Name";
export const MI = "Middle Initial";
export const EMAIL = "Email";
export const SEARCH_RESULT = "Search Results";
export const SEARCH_BUTTON_LABEL = "Search";
export const RESET_BUTTON_LABEL = "Reset";
export const USER_STATUS_PLACEHOLDER = "Please Choose User Status";
export const SELECT_WITH = "Starts With";
export const PTP_DEFAULT_VALIDATION_MESSAGE = "*At least one search criteria must be entered";

