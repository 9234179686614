/**
 * © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
 * Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
 * States and/or other countries.
 */
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import Spinner from '../../SharedModules/Spinner/Spinner'
import { Button } from 'react-bootstrap'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import '../../App.scss'
import '../../theme.scss'
import RolesWorkUnit from './RolesWorkUnit'
import UserDetails from './UserDetails'
import ManageAccountPasswordChange from './ManageAccountPasswordChange'
import * as Dropdowns from '../../SharedModules/Dropdowns/dropdowns'
import { GET_SYSTEMLIST_DROPDOWN } from '../../SharedModules/Dropdowns/actions'
import { scrollToElement } from '../../SharedModules/utility/utilityFunction'
import * as TextConst from "../UserSearch/ManageAccountConstants";


function ManageAccount (props) {
  const { userDetailsData } = props
  const dispatch = useDispatch()
  const [spinnerLoader, setspinnerLoader] = React.useState(false)
  const onDropdowns = values => dispatch(GET_SYSTEMLIST_DROPDOWN(values))
  const sysDropdowns = useSelector(state => state.appDropDowns.sysdropdowns)
  const [errorMessages, setErrorMessages] = React.useState([])
  const [successMessages, setSuccessMessages] = React.useState([])
  const [isClickedBtn, setIsClickedBtn] = React.useState(false)
  const changePassRef = useRef(null)
 
  let userData =
    userDetailsData || JSON.parse(localStorage.getItem('userDetails'))
  const showChnPswd = e => {
    setIsClickedBtn(!isClickedBtn)
    !isClickedBtn && changePassRef && changePassRef.current && scrollToElement(changePassRef,100)
  }
  // To remove search Params generated from login portal
  const clearSearchParams = () => {
    if (!userDetailsData) {
      props.history.replace({
        pathname: props.location.pathname,
        search: ''
      })
    }
  }

  React.useEffect(() => {

    clearSearchParams()
    onDropdowns({
      inputList: [Dropdowns.DM_SFX_NAM, Dropdowns.G_NAM_PREFX_CD]
    })
  }, [])

  return (
    <>
      <div className='tab-header'>
        {spinnerLoader ? <Spinner /> : null}
        {successMessages.length > 0 ? (
          <div className='alert alert-success custom-alert' role='alert'>
            {successMessages.map(message => (
              <li>{message}</li>
            ))}
          </div>
        ) : null}
        {errorMessages.length > 0 ? (
          <div className='alert alert-danger custom-alert' role='alert'>
            {errorMessages.map(message => (
              <li>{message}</li>
            ))}
          </div>
        ) : null}
        <h1 className="page-heading float-left">My Account</h1>
        {process.env.REACT_APP_LOGIN_THROUGH === TextConst.PRODUCT ? (
          <div className="float-right th-btnGroup myAccount">
            <Button
              title="CHANGE PASSWORD"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-save"
              onClick={() => showChnPswd()}
            >
              CHANGE PASSWORD
            </Button>
          </div>
        ) : null}
      </div>
      <div className='rquired-header'>* Required Field</div>
      <div className='bg_myaccount tab-holder CustomExpansion-panel'>
        <div className='scrollTodiv' />
        <ExpansionPanel
          defaultExpanded={true}
          className='collapsable-panel my-3'
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>Profile Details </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className='clear-block pt-0 pb-0'>
            <ExpansionPanel
              defaultExpanded={true}
              className='collapsable-panel my-3 collapse-wrapper-inner-form'
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='custom-summary'
              >
                <Typography>User Details</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className='clear-block'>
                <div className='tab-holder'>
                  <div className='tab-header'>
                    <UserDetails userData={userData} dropdowns={sysDropdowns} />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails className='clear-block pt-0'>
            <ExpansionPanel
              defaultExpanded={true}
              className='collapsable-panel my-3 collapse-wrapper-inner-form'
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='custom-summary'
              >
                <Typography>Roles/Work Unit</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className='clear-block'>
                <div className='tab-holder'>
                  <div className='tab-header myAccount px-2'>
                    <div className='multi-select-area hide-multi-check'>
                      <div className='mui-custom-form m-0 width-full'>
                        <RolesWorkUnit userData={userData} />
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ExpansionPanelDetails>

        </ExpansionPanel>
        {isClickedBtn ? (
              <ExpansionPanel
                defaultExpanded={true}
                className='collapsable-panel my-3 collapse-wrapper-inner-form'
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>Change Password</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className='clear-block'>
                  <div className='tab-holder'>
                    <div className='tab-header myAccount' ref={changePassRef}>
                      <ManageAccountPasswordChange
                        userData={userData}
                        setSuccessMessages={setSuccessMessages}
                        setErrorMessages={setErrorMessages}
                        setspinnerLoader={setspinnerLoader}
                      />
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
          ) : (
            ''
          )}
      </div>
    </>
  )
}
export default withRouter(ManageAccount)
