/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React, { useEffect,useState } from "react";
import TableComponent from "../../../SharedModules/Table/Table";
import { withRouter } from 'react-router';
import { getUserDetailsAction } from '../action';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import * as TextConst from "../userSearchConstant"

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: 'User ID',
    enableHyperLink: true,
    isConcat: true,
    fontSize: 12,
    width: "80px"
  }, 
  {
    id: "sn",
    numeric: false,
    disablePadding: true,
    label: 'Last Name',
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "90px"
  }, 
  {
    id: "givenName",
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "100px",
  }, 
  {
    id: "middleName",
    numeric: false,
    disablePadding: true,
    label: 'Middle Initial',
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "60px",
  }, 
  {
    id: "accountStatus",
    numeric: false,
    disablePadding: true,
    label: 'Status',
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "60px",
  }
];

const TPInquirySearchTable = (props) => {
  // const [redirect,setRedirect] = useState(false);
  const dispatch = useDispatch();
  const getDetails = id => dispatch(getUserDetailsAction(id));

  const editRow = (row) => (event) => {
    getDetails(row.userName);
     props.setspinnerLoader(true);
     props.setRedirect(true);
  };

  const getTableData = (data) => {
    if (data && data.length) {
      let tData = JSON.stringify(data);
      tData = JSON.parse(tData);
      return tData;
    } else {
      return [];
    }
  };

  return (
    <div className="wrap-th-header">
      <TableComponent
        headCells={headCells}
        tableData={props.tableData ? props.tableData : []}
        onTableRowClick={editRow}
        tableData={getTableData(props.tableData)}
        defaultSortColumn="firstProcedureCode"
      />
    </div>
  );
}
export default withRouter(TPInquirySearchTable);
