import React from "react";
import "../../theme.scss";
import axios from "axios";
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import * as ErrorMsgConstants from '../../SharedModules/Messages/ErrorMsgConstants';
import { X_CMDS_APP_CODE } from "../../SharedModules/AppConstants";

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
  }));

function LoginPage(props) {
  const classes = useStyles();
  const [
    {
      userNameReqErr,
      userNameMinLenErr,
      userNameInvalidErr,
      invalidPasswordErr,
      passwordReqErr,
      voiChk,
      userLockedErr
    },
    setShowErrors,
  ] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleChanges = value => {
    props.values.void = value;
  };
  const handleChange = prop => event => {
    if (prop == 'username') {
      const { selectionStart, selectionEnd } = event.target;
      props.setValues({ ...props.values, [prop]: event.target.value?.toUpperCase() });
      setTimeout(() => {
        inputRef?.current?.setSelectionRange(selectionStart, selectionEnd);
    }, 0);
    } else {
      props.setValues({ ...props.values, [prop]: event.target.value?.trim() });
    }
  };
  const handleClickShowPassword = () => {
    props.setValues({ ...props.values, showPassword: !props.values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  React.useEffect(() => {
    props.setWarningMessages([]);
    props.setErrorMessages([]);
    props.setInfoMessages([]);
    props.setSuccessMessages([]);
    setShowErrors(false);
    props.setValues({
      username: '',
      password: '',
      showPassword: false,
      void: false,
    })
    let storedSession = JSON.parse(localStorage?.getItem("loginState"));
    if(storedSession !== null && !storedSession.forcePasswordChange){
      props.setAppList(storedSession?.applist || []);
      props.setIsLogin(true);
      props.setspinnerLoader(false);
    }
  },[]);



  axios.interceptors.request.use(
    async function (config) {      
      const loginState = JSON.parse(localStorage.getItem('loginState'));
      let accessToken = localStorage.getItem('accessToken');
      config.headers.Username = loginState?.loginUserName
        ? loginState.loginUserName
        : null;
      config.headers.Authorization = accessToken;
      config.headers["X-CMDS-APP-CODE"] = X_CMDS_APP_CODE;
      config.timeout = 3 * 60 * 1000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  const handleSubmit = () => {
    setShowErrors(false);
    props.values.username = props.values.username?.trim();
    if (props.values.username === '' && props.values.password === '') {
      setShowErrors({ userNameReqErr: true, passwordReqErr: true });
      return false;
    }
    if (props.values.username && props.values.password === '') {
      setShowErrors({ userNameReqErr: false, passwordReqErr: true });
      return false;
    }
    if (props.values.username === '' && props.values.password) {
      setShowErrors({ userNameReqErr: true, passwordReqErr: false });
      return false;
    }
    if (!props.values.void) {
      setShowErrors({ voiChk: true });
      return false;
    }
    props.submitSuccess()
    localStorage.setItem('isNormalUser', 'true');
  };

  return (            
      <div className="ligin-form loginForm">
        <div className="form-wrapper">
          <div className="wid-100 pb-2">
            <TextField
              data-test="user-name"
              required
              autoComplete='off'
              id="UserName"
              name="User ID"
              label="User ID"
                      inputRef={inputRef}
                      value={props.values.username || ''}
              onChange={handleChange('username')}
              fullWidth
              inputProps={{
                minLength: 6,
                maxLength: 30,
                style:{textTransform:'uppercase'}
              }}
              helperText={
                userNameReqErr
                  ? ErrorMsgConstants.USERNAME_REQ_ERR
                  : userNameMinLenErr
                  ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR
                  : userNameInvalidErr
                  ? ErrorMsgConstants.USERNAME_EXIST_ERR
                  : null
              }
              error={
                userNameReqErr
                  ? ErrorMsgConstants.USERNAME_REQ_ERR
                  : userNameMinLenErr
                  ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR
                  : userNameInvalidErr
                  ? ErrorMsgConstants.USERNAME_EXIST_ERR
                  : null
              }
            />
          </div>
          <div className="wid-100 pb-1">
            <FormControl
              className={clsx(classes?.textField) + ' wid-100'}
              required
              error={passwordReqErr}
            >
              <InputLabel
                htmlFor="standard-adornment-password"
                className="Mui-required"
              >
                Password
              </InputLabel>
              <Input
                data-test="user-name-password"
                id="standard-adornment-password"
                autoComplete="off"
                type={props.values.showPassword ? 'text' : 'password'}
                value={props.values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {props.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                required
                inputProps={{ maxLength: 15 }}
              />
              <FormHelperText required error={passwordReqErr}>
                {passwordReqErr
                  ? ErrorMsgConstants.PASSWORD_REQ_ERR
                  : null}
              </FormHelperText>
            </FormControl>
            {
                userNameMinLenErr ? ErrorMsgConstants.USERNAME_MIN_LEN_ERR :
                        userNameInvalidErr ?<p
                        className="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                        > {ErrorMsgConstants.USERNAME_EXIST_ERR}</p>:
                            userLockedErr ? <p
                            className="MuiFormHelperText-root Mui-error Mui-required"
                            role="alert"
                                    >{ErrorMsgConstants.USER_LOCKED_ERR_MESSAGE}</p> :
                            invalidPasswordErr ?  <p
                            className="MuiFormHelperText-root Mui-error Mui-required"
                            role="alert"
                            > {ErrorMsgConstants.USERNAME_EXIST_ERR}</p> 
                            : null
            }                            
          </div>
          <div>
            <a title="Forgot password" href="#" className="forgot-password">
              Forgot Password? Please contact your system administrator.
            </a>
          </div>
          <div className="input-md radio-100 mt-3 pb-1 t-and-c">
            <div className="sub-radio set-rd-pos">
              <Checkbox
                type="checkbox"
                value="void"
                id="voidId"
                color="primary"
                onChange={e => handleChanges(e.target.checked)}
              />
              <label className="text-black-0 m-0 " htmlFor="voidId">
                I accept all the terms &amp; conditions
              </label>
            </div>
            {voiChk ? (
              <p
                style={{ fontStyle: '0.75rem' }}
                className="MuiFormHelperText-root Mui-error Mui-required"
              >
                Please accept Terms and Conditions
              </p>
            ) : null}
          </div>
          <div className="w-100">
            <Button
              data-test="log-on"
              title="Log On"
              variant="outlined"
              color="primary"
              className="btn  btn-primary w-100 login-btn"
              onClick={() => handleSubmit()}
            >
              LOG ON
            </Button>
          </div>
          <div className="w-100 mt-3">
            <Button
              data-test="cancel-click"
              title="Cancel"
              id='onCancel'
              variant="outlined"
              color="primary"
              className="btn  btn-primary w-100 login-btn"
              onClick={() => {
                props.setLoginType('');
                props.setSuccessMessages([]);
                props.setErrorMessages([]);
                props.setWarningMessages([]);
              }}
            >
              Cancel
            </Button>
          </div>
          <div></div>
        </div>
      </div>            
  );
};

export default LoginPage;
