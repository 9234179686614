import * as actionTypes from './actionTypes';

const axiosPayLoad = {
  auditLogData: null,
  auditTime: new Date(),
  auditLog:{},
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.AUDIT_LOG_SK:
      return { ...state, auditLogSKData: action.response, auditTime: new Date(),auditLog:{...state.auditLog, [action.auditType] :action.response} };
      case actionTypes.AUDIT_LOG:
        return { ...state, auditLogData: action.response, auditTime: new Date() };
    case actionTypes.AUDIT_LOG_DETAIL:
      return { ...state, auditLogDetailData: action.response, auditTime: new Date() };
    case actionTypes.AUDIT_LOG_RESET:
      return axiosPayLoad;
    default: return state;
  }
};

export default reducer;
